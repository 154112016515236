import _ from 'lodash'
import { minutesToTime } from '../../utils/minutesToTime'
import React from 'react'
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ActivitiesExcel = ({ children, data }) => {
  const allActivities = data.length
  const allMinutes = data.reduce((sum, curr) => curr.minutes + sum, 0)

  const categories = _(data)
    .groupBy('category.type')
    .map((objs, key) => ({
      type: key,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const projects = _(data)
    .groupBy('project.name')
    .map((objs, key) => ({
      name: key,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const users = _(data)
    .groupBy('author._id')
    .map((objs, key) => ({
      name: key,
      firstName: objs[0].author.firstName,
      lastName: objs[0].author.lastName,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const companies = _(data)
    .groupBy('company.name')
    .map((objs, key) => ({
      name: key,
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const dates = _(data)
    .groupBy('date')
    .map((objs, key) => ({
      name: key,
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const filename = `CITO Raport - ${
    companies.length > 1 ? companies.length + ' firm' : data[0].company.name
  } - ${data[0].date?.slice(0, 10)} - ${data[data.length - 1].date?.slice(
    0,
    10
  )}`

  const dataSummary = {
    company: {
      name:
        companies.length > 1
          ? companies.length + ' firm'
          : data[0].company.name,
    },
    category: {
      name: categories.length + ' kategorii',
    },
    project: {
      name: projects.length + ' projektów',
    },
    date: dates.length + ' dni',
    title: allActivities + ' aktywności',
    minutes: allMinutes,
    author: {
      firstName: users.length + ' pracowników',
      lastName: '  ',
    },
  }

  return (
    <ExcelFile element={children} filename={filename}>
      <ExcelSheet data={[dataSummary, ...data]} name='Aktywności'>
        <ExcelColumn label='Marka' value={({ company }) => company.name} />
        <ExcelColumn
          label='Kategoria'
          value={({ category }) => category.type}
        />
        <ExcelColumn
          label='Projekt'
          value={({ project }) => (project ? project.name : '-')}
        />
        <ExcelColumn label='Opis' value='title' />
        <ExcelColumn label='Data' value={({ date }) => date.slice(0, 10)} />
        <ExcelColumn
          label='Czas'
          value={({ minutes }) => minutesToTime(minutes, 'HH:MM')}
        />
        <ExcelColumn
          label='Autor'
          value={({ author }) =>
            author.firstName + ' ' + author.lastName[0] + '.'
          }
        />
      </ExcelSheet>
      <ExcelSheet data={users} name='Pracownicy'>
        <ExcelColumn
          label='Pracownik'
          value={(author) => author.firstName + ' ' + author.lastName[0] + '.'}
        />
        <ExcelColumn
          label='Czas'
          value={({ minutes }) => minutesToTime(minutes, 'HH:MM')}
        />
        <ExcelColumn
          label='Procent'
          value={({ minutes }) =>
            ((minutes / allMinutes) * 100).toFixed(2) + '%'
          }
        />
      </ExcelSheet>

      <ExcelSheet data={categories} name='Kategorie'>
        <ExcelColumn label='Kategoria' value={(category) => category.type} />
        <ExcelColumn
          label='Czas'
          value={({ minutes }) => minutesToTime(minutes, 'HH:MM')}
        />
        <ExcelColumn
          label='Procent'
          value={({ minutes }) =>
            ((minutes / allMinutes) * 100).toFixed(2) + '%'
          }
        />
      </ExcelSheet>

      <ExcelSheet data={projects} name='Projekty'>
        <ExcelColumn
          label='Projekt'
          value={(project) =>
            project.name === 'undefined' ? 'Bez projektu' : project.name
          }
        />
        <ExcelColumn
          label='Czas'
          value={({ minutes }) => minutesToTime(minutes, 'HH:MM')}
        />
        <ExcelColumn
          label='Procent'
          value={({ minutes }) =>
            ((minutes / allMinutes) * 100).toFixed(2) + '%'
          }
        />
      </ExcelSheet>
    </ExcelFile>
  )
}

export default ActivitiesExcel
