/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import { Grid, Paper, Avatar, Collapse, IconButton } from '@material-ui/core'
import { imageLink } from '../utils/imageLink'
import { roleToColor, roleValueToName } from '../utils'
import ColorLine from './ColorLine'
import { hoursToTime } from '../utils/hoursToTime'
import { getToday } from '../utils/getToday'

import { sPaper, sTitle, sSubtitle, sIconSmall } from './EstimateCard'
import { minutesToTime } from '../utils/minutesToTime'
import { timeDiff } from '../utils/timeDiff'
import { colors } from '../style'
import { useUIContext } from '../context/ui.context'
import EditIcon from '@material-ui/icons/Edit'
import ClickupIcon from '../assets/ClickupIcon'

const ActivityUserCard = ({ data, now, large, forceOpen }) => {
  const {
    firstName,
    lastName,
    title,
    image,
    color,
    h_activities,
    l_activities,
    role,
    companies,
    categories,
    projects,
    current,
    shine,
  } = data

  const [open, setOpen] = useState(forceOpen || false)

  return (
    <Grid item xs={12} lg={large ? 12 : 4}>
      <Paper elevation={0} css={[sPaper(color)]}>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={12}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => setOpen(!open)}>
            <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Avatar
                src={imageLink(image)}
                css={[
                  {
                    width: 32,
                    height: 32,
                    marginRight: '1rem',
                  },
                  !image && { backgroundColor: color },
                ]}
                alt={firstName + ' ' + lastName}>
                {firstName?.[0]}
                {lastName?.[0]}
              </Avatar>
              <h2 css={[sTitle(color)]}>
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                  {firstName}&nbsp;
                  {lastName}
                  <span
                    css={{
                      opacity: 0.3,
                      fontSize: '0.675rem',
                      fontWeight: 400,
                    }}>
                    {title}
                  </span>
                </div>
                <span css={sSubtitle(roleToColor(role))}>
                  {roleValueToName(role)}
                </span>
              </h2>
            </div>
          </Grid>
          <Grid item xs={12} css={{ margin: '1rem 0 0' }}>
            <Grid container spacing={1} onClick={() => setOpen(!open)}>
              <Grid item xs={12}>
                {/* <ColorLine
                  color={color}
                  percentage={100}
                  shine={!open && shine}>
                  <div css={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      css={[sNow(color), !open && shine && { opacity: 1 }]}
                    />
                    {h_activities > 1 ? (
                      <div>
                        {h_activities.toFixed(h_activities > 10 ? 0 : 1)}{' '}
                        <span>h</span>
                      </div>
                    ) : (
                      <div>
                        {(h_activities * 60).toFixed(0)} <span>min</span>
                      </div>
                    )}
                    &nbsp;&nbsp;|&nbsp;&nbsp;{l_activities}&nbsp;
                    <span>activities</span>
                  </div>
                </ColorLine> */}
                <ColorLine
                  color={current ? current.company.color : colors.gray600} // color to use user's color}
                  percentage={100}
                  shine={!open && shine}
                  title={
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      {current?.clickup && (
                        <a
                          href={`clickup://t/${current.clickup.replace(
                            '#',
                            ''
                          )}`}
                          target='_blank'
                          rel='noreferrer noopener'>
                          <IconButton
                            size='small'
                            aria-label='edit'
                            css={{
                              marginRight: '0.5rem',
                              fontSize: '0.875rem',
                              color: current.company.color,
                              svg: { width: '0.875rem', height: '0.875rem' },
                            }}>
                            <ClickupIcon />
                          </IconButton>
                        </a>
                      )}
                      {current ? (
                        current.company.icon ? (
                          <>
                            <img
                              src={imageLink(current.company.icon)}
                              css={sIconSmall}
                              alt={current.company.name}
                            />
                            {current.title}
                          </>
                        ) : (
                          <>
                            <div css={sIconSmall} />
                            {current.title}
                          </>
                        )
                      ) : (
                        <>
                          <div
                            css={[
                              sNow(color),
                              !open && shine && { opacity: 1 },
                            ]}
                          />
                          {l_activities}&nbsp;
                          <span>aktywnoś{l_activities > 1 ? 'ci' : 'ć'}</span>
                          {/* {h_activities > 1 ? (
                            <div>
                              {h_activities.toFixed(h_activities > 10 ? 0 : 1)}{' '}
                              <span>h</span>
                            </div>
                          ) : (
                            <div>
                              {(h_activities * 60).toFixed(0)} <span>min</span>
                            </div>
                          )} */}
                        </>
                      )}
                    </div>
                  }>
                  <div css={{ display: 'flex', alignItems: 'center' }}>
                    {current && (
                      <div
                        css={[
                          sNow(current.company.color),
                          !open && shine && { opacity: 1 },
                        ]}
                      />
                    )}
                    {current &&
                      minutesToTime(timeDiff(current.timeStart, now), 'HH:MM') +
                        ' / '}
                    {minutesToTime(h_activities * 60, 'HH:MM')}
                  </div>
                </ColorLine>
              </Grid>
            </Grid>

            {open && (
              <Collapse in={open} css={{ width: '100%' }}>
                <div css={[{ paddingTop: '1rem' }]} />
                <div css={sSectionName}>Companies</div>
                <Grid container spacing={1}>
                  {companies.map((item) => (
                    <ItemDetails
                      key={item._id}
                      data={item}
                      typename={'Company'}
                      h_activities={h_activities}
                      lightBack
                    />
                  ))}
                </Grid>
                <div css={[{ paddingTop: '1rem' }]} />
                <div css={sSectionName}>Categories</div>
                <Grid container spacing={1}>
                  {categories.map((item) => (
                    <ItemDetails
                      key={item._id}
                      data={item}
                      typename={'Category'}
                      h_activities={h_activities}
                      lightBack
                    />
                  ))}
                </Grid>
                {/* <div css={[{ paddingTop: '1rem' }]} />
                <div css={sSectionName}>Projects</div>
                <Grid container spacing={1}>
                  {projects.map((item) => (
                    <ItemDetails
                      key={item._id}
                      data={item}
                      typename={'Project'}
                      h_activities={h_activities}
                      lightBack
                    />
                  ))}
                </Grid> */}
              </Collapse>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

const ItemDetails = ({
  data,
  typename,
  h_activities,
  xs,
  lightBack,
  lessVisible,
}) => {
  const {
    name,
    color,
    h_activities: item_activities,
    workDone,
    icon,
    tasks,
    companies,
    categories,
    projects,
    shine,
    date,
  } = data

  const { setEditActivityOpen } = useUIContext()

  const [shineState, setShine] = useState(shine)
  const [open, setOpen] = useState(false)
  const today = getToday(new Date())

  return (
    <Grid
      item
      xs={xs || 12}
      css={[
        {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        },
      ]}>
      <div
        css={[
          {
            position: 'absolute',
            top: 4,
            left: 4,
            width: 2,
            height: 'calc(100% - 1.25rem)',
            backgroundColor: color,
            transition: 'opacity 0.2s ease-out',
          },
          !open && { opacity: 0 },
        ]}
      />
      <div onClick={() => setOpen(!open)} css={{ position: 'relative' }}>
        <ColorLine
          title={
            icon ? (
              <>
                <img src={imageLink(icon)} css={sIconSmall} alt={name} />
                {name}
              </>
            ) : (
              <>
                <div css={sIconSmall} />
                {name}
              </>
            )
          }
          color={color || '#FFFFFF'}
          percentage={workDone}
          lightBack={lightBack}
          lessVisible={lessVisible}
          shine={!open && shineState}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              span: { whiteSpace: 'nowrap' },
            }}>
            <div css={[sNow(color), !open && shine && { opacity: 1 }]} />
            {date && date.slice(0, 10) !== today && (
              <span>{date.slice(0, 10)}&nbsp;&nbsp;</span>
            )}
            {hoursToTime(item_activities, 'HH:MM')}
            <span>&nbsp;&nbsp;{workDone?.toFixed(0)}%</span>
          </div>
        </ColorLine>
        {typename === 'Activity' && data.clickup && (
          <a
            href={`clickup://t/${data.clickup.replace('#', '')}`}
            target='_blank'
            rel='noreferrer noopener'>
            <IconButton
              size='small'
              aria-label='edit'
              css={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translate(2rem, -50%)',
                fontSize: '0.875rem',
                svg: { width: '0.875rem', height: '0.875rem' },
              }}>
              <ClickupIcon />
            </IconButton>
          </a>
        )}
        {typename === 'Activity' && (
          <IconButton
            size='small'
            aria-label='edit'
            onClick={() => setEditActivityOpen(data._id)}
            css={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translate(1rem, -50%)',
              fontSize: '0.875rem',
            }}>
            <EditIcon fontSize='inherit' />
          </IconButton>
        )}
      </div>
      {data && open && (
        <Collapse in={open}>
          <Grid
            container
            spacing={1}
            justifyContent='center'
            css={{ padding: '0.5rem 0 1rem' }}>
            {companies &&
              companies.map((company) => (
                <ItemDetails
                  key={company._id}
                  data={company}
                  item_activities={item_activities}
                  typename='Company'
                  lightBack
                  xs={11}
                />
              ))}
            {categories &&
              categories.map((category) => (
                <ItemDetails
                  key={category._id}
                  data={category}
                  item_activities={item_activities}
                  typename='Category'
                  lightBack
                  xs={11}
                />
              ))}
            {projects &&
              projects.map((project) => (
                <ItemDetails
                  key={project._id}
                  data={project}
                  item_activities={item_activities}
                  typename='Project'
                  lightBack
                  xs={10}
                />
              ))}
            {tasks &&
              tasks.map((task) => (
                <ItemDetails
                  key={task._id}
                  data={{
                    ...task,
                    h_activities: task.minutes / 60,
                    workDone: (task.minutes / 60 / item_activities) * 100,
                    color,
                    name: task.title,
                    shine: !task.timeEnd,
                  }}
                  item_activities={item_activities}
                  typename='Activity'
                  lightBack
                  xs={9}
                />
              ))}
          </Grid>
        </Collapse>
      )}
    </Grid>
  )
}

const sSectionName = {
  fontSize: '0.675rem',
  opacity: 0.25,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  marginBottom: '1rem',
}

export const sNow = (color) => ({
  opacity: 0,
  width: 8,
  height: 8,
  borderRadius: 4,
  backgroundColor: color,
  // animation: 'blink 1s infinite',
  margin: '0 1rem',
  flexShrink: 0,
  transition: 'opacity 0.15s ease-out',
})

export default ActivityUserCard
