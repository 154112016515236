import { gql } from '@apollo/client'

export const USER_LOGIN = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      userId
      token
      name
      role
    }
  }
`

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      _id
      email
      firstName
      lastName
      title
      image
      phone
      birthDate
      role
      color
      isActive
    }
  }
`

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      _id
      firstName
      lastName
      title
      image
      phone
      email
      role
      color
      birthDate
      isActive
    }
  }
`

export const USER_CREATE = gql`
  mutation userCreate($input: UserCreateInput) {
    userCreate(input: $input) {
      _id
      firstName
      lastName
      title
      image
      phone
      email
      role
      color
      birthDate
      isActive
    }
  }
`

export const USER_UPDATE = gql`
  mutation userUpdate($id: ID!, $input: UserUpdateInput) {
    userUpdate(id: $id, input: $input) {
      _id
      email
      firstName
      lastName
      title
      image
      phone
      birthDate
      role
      color
      isActive
    }
  }
`

export const USER_PASSWORD_RESET = gql`
  mutation userPasswordReset($email: String!) {
    userPasswordReset(email: $email) {
      success
    }
  }
`

export const USER_PASSWORD_NEW = gql`
  mutation userPasswordNew($password: String!, $id: ID!, $token: String!) {
    userPasswordNew(password: $password, id: $id, token: $token) {
      success
    }
  }
`

export const DELETE_USER = gql`
mutation userDelete($id: ID!) {
  userDelete(id: $id) {
    _id
    message
  }
}`
