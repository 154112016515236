/** @jsxImportSource @emotion/react */

import { useRef, useState, useEffect } from 'react'
import QRCode from 'react-qr-code'
import { alpha, colors, s } from '../../style'
import { sTableCategory } from '../Tracking/Tracking'
import PDF from 'react-to-pdf'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ClickupIcon from '../../assets/ClickupIcon'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const QRBoard = ({ selectedCodes, setSelectedCodes, setViewCode }) => {
  const boardRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const getDimensions = () => ({
    width: boardRef.current.offsetWidth,
    height: boardRef.current.offsetHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }
    if (boardRef.current) {
      setDimensions(getDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [boardRef])

  // console.log(dimensions)

  return (
    <>
      <div css={sBoard} ref={boardRef}>
        {selectedCodes.map((code, id) => (
          <div key={id} css={sCode}>
            <div css={{ [s.md_down]: { display: 'none' } }}>
              <QRCode
                value={code.url}
                size={boardRef?.current ? (dimensions.width - 512) / 4 : 256}
              />
            </div>
            <div
              css={{
                [s.xs]: { display: 'none' },
                [s.lg]: { display: 'none' },
              }}>
              <QRCode
                value={code.url}
                size={boardRef?.current ? (dimensions.width - 256) / 3 : 256}
              />
            </div>
            <div
              css={{
                [s.sm]: { display: 'none' },
              }}>
              <QRCode
                value={code.url}
                size={boardRef?.current ? (dimensions.width - 256) / 2 : 256}
              />
            </div>
            <h1 css={sCompany}>{code.company.name}</h1>

            {code.project && (
              <div
                css={[
                  sTableCategory(code.project.color),
                  sProject,
                  {
                    backgroundColor: code.project.color.concat(alpha[8]),
                    color: code.project.color.concat(alpha[100]),
                  },
                ]}>
                <div
                  css={[sProjectSquare, { background: code.project.color }]}
                />
                {code.project.name}
              </div>
            )}
            <div
              css={[
                sTableCategory(code.category.color),
                sCategory,
                { backgroundColor: code.category.color.concat(alpha[8]) },
                // { color: code.category.color.concat(alpha[100]) },
              ]}>
              {code.category.name}
            </div>
            <p css={sTitle}>
              {code.title}
              {code.auto && (
                <span
                  css={{
                    fontSize: '0.5rem',
                    fontWeight: 900,
                    lineHeight: '1em',
                    letterSpacing: '-0.1em',
                    margin: '3px 0 0 4px',
                    opacity: 0.5,
                  }}>
                  AUTO
                </span>
              )}
              {code.clickup && (
                <ClickupIcon
                  css={{
                    width: 8,
                    height: 8,
                    opacity: 0.5,
                    marginTop: 2,
                    marginLeft: 4,
                  }}
                />
              )}
            </p>
            <div className='delete-qr'>
              <IconButton css={sOpenButton} onClick={() => setViewCode(code)}>
                <OpenInNewIcon fontSize='small' />
              </IconButton>
              <IconButton
                css={sDeteleButton}
                onClick={() =>
                  setSelectedCodes((prev) => {
                    const updated = prev.filter((e, i) => i !== id)
                    localStorage.setItem('qrcodes', JSON.stringify(updated))
                    return updated
                  })
                }>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <div
        css={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '2rem 0',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <p css={{ marginBottom: '1rem', fontSize: '0.75rem', opacity: 0.5 }}>
          Plik PDF zostanie wygeneroway w formacie A4. Jego wymiary są wyrażone
          w pikselach, a wielkość zależy od rozmiaru okna przeglądarki. Wielkość
          kodów QR jest responsywna, ale odświeżenie następuje podczas zmiany
          rozmiaru okna przeglądarki. Dostosuj ich wilekość przed wydrukiem.
          Domyślnie siatka kodów wynosi 4x5 dla ekranów desktopowych. Jeżeli
          potrzebujesz większych kodów, zmiejsz ekran do szerokości tabletowej,
          wówczas siatak będzie miała rozmiat 3x4. Wersja mobilna pozwala
          wydrukować jeszcze większe kody - siatka 2x3, ale raz ze zmniejszeniem
          okna, jakość wydruku spada. Średnio jeden kod zwiększa rozmiar pliku o
          2MB, więc plik z pełną siatką 4x5 może ważyć nawet 50MB, dlatego
          proces generowania pliku może chwilę potrwać.
        </p>
        <PDF
          targetRef={boardRef}
          filename={'citoqr-board.pdf'}
          scale={1.8}
          options={{
            orientation: 'portrait',
            unit: 'px',
            format: [dimensions.width || 1252, dimensions.height || 1770],
          }}>
          {({ toPdf }) => (
            <Button variant='contained' color='primary' onClick={toPdf}>
              DOWNLOAD PDF
            </Button>
          )}
        </PDF>
      </div>
    </>
  )
}

const sBoard = {
  width: '100%',
  aspectRatio: '1 / 1.4142',
  backgroundColor: 'white',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(5, 1fr)',
  // minWidth: 1252,
  // minHeight: 1770,
  padding: '1.5rem 0 1.5rem',
  [s.md_down]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    padding: 0,
  },
  [s.xs]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
  },
}

const sCode = {
  // border: '1px solid red',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  position: 'relative',
  top: 0,
  left: 0,

  '.delete-qr': {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.white.concat(alpha[75]),
    opacity: 0,
    transition: 'opacity 0.15s ease-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '7rem',
  },

  '&:hover': {
    '.delete-qr': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
}
const sCompany = {
  fontSize: '1rem',
  letterSpacing: '-0.025em',
  fontWeight: 700,
  margin: '1rem 0 0.5rem',
  textAlign: 'center',
}

const sCategory = {
  marginBottom: '0.5rem',
  // fontSize: 13,
}

const sProject = {
  marginBottom: '0.5rem',
  // fontSize: 13,
}

const sProjectSquare = {
  width: 8,
  height: 8,
  marginRight: 8,
  transform: 'rotate(45deg)',
}

const sTitle = {
  fontSize: '0.75rem',
  opacity: 0.5,
  display: 'flex',
  alignItems: 'center',
}

const sDeteleButton = {
  margin: '0 1rem',
  transition: 'all 0.2s ease-out',
  backgroundColor: colors.text,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.red,
    color: colors.white,
  },
}

const sOpenButton = {
  margin: '0 1rem',
  transition: 'all 0.2s ease-out',
  backgroundColor: colors.text,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.green,
    color: colors.white,
  },
}

export default QRBoard
