/** @jsxImportSource @emotion/react */

import { Button, Checkbox, Grid, TextField, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { s } from '../../style'
import { imageLink } from '../../utils/imageLink'
import ClickupTrackingGetTask from '../Clickup/ClickupTrackingGetTask'
import { sTableCategory } from '../Tracking/Tracking'

export const getURL = ({
  auto,
  clickup,
  company,
  category,
  project,
  title,
}) => {
  let result = ''
  const domain = process.env[`REACT_APP_SERVER_URI_${process.env.NODE_ENV}`]

  result += domain
  result += auto ? '/track' : '/tracking'
  result += `?company=${company._id}`
  result += `&category=${category._id}`

  if (project) result += `&project=${project._id}`

  if (!title || title.trim().length === 0) {
    if (project)
      result += `&title=${encodeURIComponent('[AUTO] ' + project.name)}`
    else result += `&title=${encodeURIComponent('[AUTO] ' + category.name)}`
  } else result += `&title=${encodeURIComponent(title)}`

  if (clickup) result += `&clickup=${clickup}`

  return result
}

const QRInputs = ({ namesData, setSelectedCodes, setViewCode }) => {
  const initialNewTaskValues = {
    title: '',
    company: null,
    project: null,
    category: null,
    clickup: '',
    auto: true,
  }

  const [token, setToken] = useState(null)
  const [newTaskValues, setNewTaskValues] = useState(initialNewTaskValues)

  const companies = namesData.companies.filter((thing) => !thing.isHidden)
  const projects = namesData.projects.filter((thing) => !thing.isHidden)
  const categories = namesData.categories.filter((thing) => !thing.isHidden)

  const projectsInCompany = newTaskValues.company
    ? companies
        ?.find((company) => company._id === newTaskValues.company._id)
        ?.projects.filter((project) => !project.isHidden).length
    : -1

  const selectedProject = newTaskValues.project

  const clickupOverridesFields = (task) => {
    const nodata =
      !task ||
      !task.name ||
      !task.company ||
      !task.project ||
      !projects ||
      !companies

    if (nodata) return null

    const foundProjects = projects.filter(
      ({ name }) =>
        task.project &&
        (name.toLowerCase().includes(task.project.toLowerCase()) ||
          task.project.toLowerCase().includes(name.toLowerCase()))
    )

    // console.log('FOUND PROJECTS', foundProjects)

    const foundProject = foundProjects?.find((project) => {
      const foundCompany = companies.find(
        ({ _id }) => _id === project.company._id
      )
      // console.log('FOUND COMPANY', foundCompany)

      return (
        foundCompany &&
        (foundCompany.name.toLowerCase().includes(task.company.toLowerCase()) ||
          task.company.toLowerCase().includes(foundCompany.name.toLowerCase()))
      )
    })

    // console.log('FOUND PROJECT', foundProject)

    if (foundProjects?.length === 0)
      return toast.error('Projekt ClickUp nieodnaleziony w CITO')

    if (!foundProject) {
      toast.warn(
        'Projekt ClickUp odnaleziony w CITO, ale nie odnaleziono sprzężonej firmy (sprawdź nazwę listy w ClickUp - powinna zawierać nazwę firmy taką samą jak nazwa firmy w CITO (do znacznika " - "))'
      )
      setNewTaskValues({
        ...newTaskValues,
        project: foundProjects[0],
        title: task.name,
      })
    }

    setNewTaskValues({
      ...newTaskValues,
      project: foundProject,
      title: task.name,
    })
  }

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem('clickup'))
    if (getToken) setToken(getToken)
  }, [])

  useEffect(() => {
    if (!selectedProject) return null

    const foundProjectCompanyId = selectedProject?.company?._id
    const foundCompany = companies?.find(
      ({ _id }) => _id === foundProjectCompanyId
    )
    if (!foundCompany) return toast.error('Firma nieodnaleziona')

    setNewTaskValues({
      ...newTaskValues,
      company: foundCompany,
    })
  }, [selectedProject])

  return (
    <>
      <Grid
        container
        css={{
          width: '100%',
          // padding: '1rem',
          // justifyContent: 'center',
          fontSize: '0.75rem',
          overflow: 'hidden',
        }}
        spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          lg={2}
          css={{
            letterSpacing: '-0.025em',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            position: 'relative',
            top: 0,
            left: 0,
          }}>
          <TextField
            fullWidth
            variant='outlined'
            label='ClickUp'
            type='text'
            size='small'
            inputProps={newTaskValues.clickup}
            value={newTaskValues.clickup}
            onChange={(e) =>
              setNewTaskValues({ ...newTaskValues, clickup: e.target.value })
            }
            css={sInput}
          />
          {newTaskValues.clickup && (
            <ClickupTrackingGetTask
              input={newTaskValues.clickup}
              token={token}
              updateInputs={clickupOverridesFields}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Autocomplete
            value={newTaskValues.company}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                company: newValue,
              })
            }}
            id='company'
            options={companies}
            filterOptions={(options, state) => {
              if (newTaskValues.category?.type === 'PRZERWA')
                return options.filter((option) => option.name === 'PRZERWA')
              const isProject = newTaskValues.project
              const newOptions = options.filter(
                (option) =>
                  (isProject
                    ? option?.projects?.filter(
                        (project) => project._id === isProject._id
                      ).length > 0
                    : true) &&
                  option.name
                    .toLowerCase()
                    .includes(state.inputValue?.toLowerCase())
              )
              return newOptions
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <div
                css={{
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {option.icon ? (
                  <img
                    src={imageLink(option.icon)}
                    css={{ width: 16, height: 16, marginRight: 16 }}
                    loading='lazy'
                    alt={option.name}
                  />
                ) : (
                  <div
                    css={{
                      width: 16,
                      height: 16,
                      marginRight: 16,
                      border: '1px solid black',
                    }}
                  />
                )}
                {option.name}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                label='Company'
                placeholder='Company'
                required
                css={sInput}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Autocomplete
            value={newTaskValues.project}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                project: newValue,
              })
            }}
            id='project'
            options={projects}
            filterOptions={(options, state) => {
                const companyID = newTaskValues.company?._id; // Użycie opcjonalnego łańcuchowania
                const newOptions = options.filter((option) => {
                    // Dodajemy warunek, który pomija próbę porównania, jeśli option.company jest null lub undefined
                    const matchesCompany = companyID ? option.company?._id === companyID : true;
                    const matchesName = option.name.toLowerCase().includes(state.inputValue?.toLowerCase());

                    return matchesCompany && matchesName;
                });

                return newOptions;
            }}

            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <div
                css={{
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div
                  css={{
                    width: 8,
                    height: 8,
                    marginRight: 16,
                    backgroundColor: option.color || 'transparent',
                    border: option.color ? 'none' : '1px solid black',
                    transform: 'rotate(45deg)',
                  }}
                />
                {option.name}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={
                  newTaskValues.company?.require_projects &&
                  !newTaskValues.project
                }
                variant='outlined'
                size='small'
                label={
                  projectsInCompany >= 0
                    ? `Project (${projectsInCompany})`
                    : 'Project'
                }
                placeholder='Project '
                css={sInput}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Autocomplete
            value={newTaskValues.category}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                category: newValue,
              })
            }}
            id='category'
            options={categories}
            getOptionLabel={(option) => option?.name || ''}
            filterOptions={(options, state) => {
              if (newTaskValues.company?.name === 'PRZERWA')
                return options.filter((option) => option.type === 'PRZERWA')
              return options.filter((option) =>
                option.name
                  .toLowerCase()
                  .includes(state.inputValue?.toLowerCase())
              )
            }}
            renderOption={(option, { selected }) => (
              <div
                css={[
                  sTableCategory(option?.color),
                  {
                    width: '100%',
                  },
                ]}>
                {option.name}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                label='Category'
                placeholder='Category'
                required
                css={sInput}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          css={{
            letterSpacing: '-0.025em',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            // '.MuiOutlinedInput-inputMarginDense': {
            //   padding: '4.5px 4px',
            // },
          }}>
          {/* <Autocomplete
            freeSolo
            fullWidth
            inputValue={newTaskTitleInput}
            onInputChange={(event, newInputValue) => {
              setNewTaskTitleInput(newInputValue)
            }}
            options={activitiesTitles}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                title: newValue,
              })
            }}
            renderOption={(option, { selected }) => (
              <div css={{ fontSize: '0.75rem' }}>{option}</div>
            )}
            renderInput={(params) => ( */}
          <TextField
            // {...params}
            fullWidth
            variant='outlined'
            label='Title'
            type='text'
            size='small'
            inputProps={
              newTaskValues.title && {
                spellCheck: 'true',
              }
            }
            value={newTaskValues.title}
            onChange={(e) =>
              setNewTaskValues({ ...newTaskValues, title: e.target.value })
            }
            css={sInput}
          />
          {/* )} */}
          {/* /> */}
        </Grid>
        <Grid item xs={4} sm={4} lg={1} css={{ [s.lg]: { display: 'none' } }}>
          <Tooltip
            title={
              newTaskValues.auto
                ? '[AUTO] Aktywność uruchamiana automatycznie'
                : '[CLICK] Aktywność uruchamiana ręcznie'
            }
            aria-label='auto'>
            <Checkbox
              checked={newTaskValues.auto}
              onChange={(e) => {
                setNewTaskValues({
                  ...newTaskValues,
                  auto: !newTaskValues.auto,
                })
              }}
              color='primary'
            />
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={2}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Tooltip
            title={
              newTaskValues.auto
                ? 'Aktywność uruchamiana automatycznie (Auto)'
                : 'Aktywność uruchamiana ręcznie (Click)'
            }
            aria-label='auto'
            css={{
              display: 'none',
              marginRight: '1rem',
              [s.lg]: { display: 'inherit' },
            }}>
            <Checkbox
              checked={newTaskValues.auto}
              onChange={(e) => {
                setNewTaskValues({
                  ...newTaskValues,
                  auto: !newTaskValues.auto,
                })
              }}
              color='primary'
            />
          </Tooltip>
          <Button
            onClick={() =>
              setViewCode({
                ...newTaskValues,
                url: getURL({
                  auto: newTaskValues.auto,
                  clickup: newTaskValues.clickup,
                  company: newTaskValues.company,
                  project: newTaskValues.project,
                  category: newTaskValues.category,
                  title: newTaskValues.title,
                }),
              })
            }
            size='large'
            variant='contained'
            disabled={
              !(
                newTaskValues.company &&
                newTaskValues.category &&
                !(
                  newTaskValues.company?.require_projects &&
                  !newTaskValues.project
                )
              )
            }
            css={{
              width: '100%',

              minWidth: 32,
              backgroundColor: 'green',
              color: 'white',
              fontWeight: 900,
              fontSize: '0.675rem',
              '&:hover': {
                backgroundColor: 'green',
              },
            }}>
            GENERATE
          </Button>
        </Grid>
        {/* <Grid
          item
          xs={4}
          sm={4}
          lg={1}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={isLoading}
            onClick={() => {
              setLoading(true)
              onNewBreakActivity({
                title: newTaskValues.title,
                callback: onActionCallback,
              })
            }}
            size='large'
            variant='contained'
            css={{
              width: '100%',
              minWidth: 32,
              fontSize: '0.675rem',
            }}
          >
            BREAK
          </Button>
        </Grid> */}
      </Grid>
    </>
  )
}

const sInput = {
  input: { fontSize: '0.875rem' },
  label: { fontSize: '0.75rem' },
}

export default QRInputs
