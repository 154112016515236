/** @jsxImportSource @emotion/react */

import React, { useState } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { colors, s } from '../../style'
import { imageLink } from '../../utils/imageLink'
import ColorLine from '../../components/ColorLine'
import TaskContainer from './TaskContainer'
import { hoursToTime } from '../../utils/hoursToTime'
import { sNow } from '../../components/ActivityUserCard'

const UserContainer = ({ data }) => {
  const {
    activs,
    estims,
    firstName,
    lastName,
    color,
    image,
    title,
    tasks,
    isNow,
  } = data

  // console.log(tasks)

  const workDone = estims ? (activs / estims) * 100 : 0

  const [open, setOpen] = useState(false)
  const [isOvertime, setIsOvertime] = useState(workDone >= 101)
  const [isOverWarning, setIsOverWarning] = useState(workDone >= 90)
  const [isOverSoon, setIsOverSoon] = useState(workDone >= 80)

  return (
    <Grid container spacing={3} css={sUserOuter}>
      <Grid item xs={12} css={sUserInner}>
        <div css={sUserText}>
          {image ? (
            <img src={imageLink(image)} css={sUserImage} alt='' />
          ) : (
            <div
              css={[
                sUserImage,
                { backgroundColor: color, borderRadius: '2rem' },
              ]}
            />
          )}
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <strong css={{ fontSize: '2rem' }}>
              {firstName} {lastName}
            </strong>
            <p>{title}</p>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={4} css={sUserColorline}>
        <ColorLine
          isOvertime={workDone >= 101}
          isOverWarning={workDone >= 90}
          isOverSoon={workDone >= 80}
          shine={isNow}
          lightBack
          color={color ? color : colors.gray300}
          percentage={workDone}
          title={
            <>
              {hoursToTime(activs / 60, 'HH:MM')} /{' '}
              {hoursToTime(estims / 60, 'HH:MM')}
            </>
          }
        >
          <div>{workDone.toFixed(0)}%</div>
        </ColorLine>
      </Grid>
      <Grid item container xs={12} spacing={3} css={{ marginTop: '1rem' }}>
        {tasks.map((task) => (
          <TaskContainer key={task._id} data={task} />
        ))}
      </Grid>
    </Grid>
  )
}

const sUserColorline = {
  width: '100%',
  height: 'max-content',
}

const sUserOuter = {
  padding: '2rem 0 3rem',
  margin: '1rem 0',
  borderBottom: '1px solid rgb(0 0 0 / 8%)',
}

const sUserInner = {
  display: 'flex',
  flexDirection: 'column',
  [s.sm_down]: {
    alignItems: 'flex-start',
  },

  strong: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },

  p: {
    opacity: 0.5,
    fontSize: '0.75rem',
  },
}

const sUserText = {
  display: 'flex',
  alignItems: 'center',
}

const sUserImage = {
  width: 48,
  height: 48,
  marginRight: '1rem',
  borderRadius: '2rem',
}

export default UserContainer
