/** @jsxImportSource @emotion/react */

import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Tooltip,
} from '@material-ui/core'
import React, { useRef } from 'react'
import { sFront } from '../Estimates/EstimateEditModal'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { imageLink } from '../../utils/imageLink'
import { sTableCategory } from '../Tracking/Tracking'
import { alpha, colors, s } from '../../style'
import QRIcon from '@material-ui/icons/CropFree'
import AddIcon from '@material-ui/icons/AddCircle'
import DownloadIcon from '@material-ui/icons/GetApp'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import PDF from 'react-to-pdf'
import { toast } from 'react-toastify'
import ClickupIcon from '../../assets/ClickupIcon'

const copyToClipboard = (text) => {
  navigator?.clipboard?.writeText(text).then(
    function () {
      toast.success(<strong>Link skopiowano do schowka!</strong>, {
        autoClose: 2000,
      })
    },
    function (err) {
      toast.error(<strong>Wystąpił błąd podczas kopiowania</strong>)
    }
  )
}

const QRView = ({ viewCode, setViewCode, options, setSelectedCodes }) => {
  // console.log(viewCode)
  const { url, company, category, project, title, auto, clickup } = viewCode
  const clientWidth = typeof window !== 'undefined' ? window.innerWidth : 256
  const refQR = useRef(null)

  if (!company) return null
  return (
    <div>
      <Modal onClose={() => setViewCode({})} open={url}>
        <Paper
          css={[
            sFront,
            {
              width: 'max-content',
              alignItems: 'center',
              [s.sm]: { padding: '0 0 4rem' },
            },
          ]}>
          <IconButton
            size='small'
            css={{ position: 'absolute', top: '1rem', right: '1rem' }}
            onClick={() => setViewCode({})}>
            <CloseIcon />
          </IconButton>
          <div ref={refQR} css={sPDF}>
            <div
              css={{
                [s.sm]: { display: 'none' },
              }}>
              <QRCode value={url} size={clientWidth - 64} />
            </div>
            <div css={{ [s.xs]: { display: 'none' } }}>
              <QRCode value={url} size={320} />
            </div>
            <div css={sContainer}>
              <div css={{ display: 'flex', marginBottom: '1em' }}>
                <h1>{company.name}</h1>
                <img src={imageLink(company.icon)} alt='' loading='lazy' />
              </div>

              <div css={{ display: 'flex', marginBottom: '0.5rem' }}>
                {project && (
                  <span
                    css={[
                      sTableCategory(project.color),
                      {
                        backgroundColor: project.color.concat(alpha[8]),
                        marginRight: '0.5rem',
                        [s.sm]: {
                          fontSize: 13,
                          color: project.color.concat(alpha[100]),
                        },
                      },
                    ]}>
                    <div
                      css={[sProjectSquare, { background: project.color }]}
                    />
                    {project.name}
                  </span>
                )}
                <span
                  css={[
                    sTableCategory(category.color),
                    {
                      [s.sm]: {
                        backgroundColor: category.color.concat(alpha[8]),
                        fontSize: 13,
                        color: category.color.concat(alpha[100]),
                      },
                    },
                  ]}>
                  {category.name}
                </span>
              </div>
              <p css={sTitle}>
                {title}
                {auto && (
                  <span
                    css={{
                      fontSize: '0.5rem',
                      fontWeight: 900,
                      // lineHeight: '1em',
                      letterSpacing: '-0.1em',
                      margin: '0 0 0 8px',

                      opacity: 0.5,
                      position: 'relative',
                      left: 0,
                      top: 5,
                    }}>
                    AUTO
                  </span>
                )}
                {clickup && (
                  <ClickupIcon
                    css={{
                      width: 8,
                      height: 8,
                      opacity: 0.5,
                      marginTop: 2,
                      marginLeft: 8,
                    }}
                  />
                )}
              </p>

              {/* <Button>
              <PDFDownloadLink
                document={<QRPDF data={viewCode} fileName='cito-qr.pdf' />}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CircularProgress size={24} css={{ color: 'black' }} />
                  ) : (
                    'PDF'
                  )
                }
              </PDFDownloadLink>
            </Button> */}
            </div>
          </div>
          {options && (
            <div css={sButtons}>
              <Tooltip title={'Dodaj do planszy'}>
                <IconButton
                  css={sIconButton}
                  onClick={() => {
                    setSelectedCodes((prev) => {
                      const updated = [...prev, viewCode]
                      localStorage.setItem('qrcodes', JSON.stringify(updated))
                      return updated
                    })
                    setViewCode({})
                  }}>
                  <AddIcon fontSize='large' />
                </IconButton>
              </Tooltip>
              <ExportPDF name='Project' targetRef={refQR} />
              <Tooltip title={'Skopiuj Link'}>
                <IconButton
                  css={sIconButton}
                  onClick={() => copyToClipboard(url)}>
                  <CopyIcon fontSize='large' />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Paper>
      </Modal>
    </div>
  )
}

const ExportPDF = ({ targetRef }) => (
  <PDF
    targetRef={targetRef}
    filename={'citoqr.pdf'}
    scale={3}
    options={{
      orientation: 'portrait',
      unit: 'px',
      format: [2480, 3508],
    }}>
    {({ toPdf }) => (
      <Tooltip title={'Pobierz PDF'}>
        <IconButton css={sIconButton} onClick={toPdf}>
          <DownloadIcon fontSize='large' />
        </IconButton>
      </Tooltip>
    )}
  </PDF>
)

const sPDF = {
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  alignItems: 'center',
  [s.sm]: {
    padding: '4rem 4rem 2rem',
  },
}

const sContainer = {
  width: '100%',
  margin: '1rem 0 0',
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'center',

  img: {
    width: 24,
    height: 24,
    marginLeft: '0.75rem',
    [s.xs]: {
      order: 1,
      marginRight: '0.75rem',
      marginLeft: 0,
    },
  },

  h1: {
    fontSize: '1.5rem',
    lineHeight: '1em',
    letterSpacing: '-0.025em',
    fontWeight: 700,
    [s.xs]: {
      order: 2,
    },
  },

  span: { marginBottom: '0.5rem' },
}

const sProjectSquare = {
  width: 8,
  height: 8,
  marginRight: 8,
  transform: 'rotate(45deg)',
}

const sIconButton = {
  transition: 'all 0.2s ease-out',
  backgroundColor: colors.gray200,
  color: colors.gray500,
  '&:hover': {
    backgroundColor: colors.blue,
    color: colors.white,
  },
  '&:disabled': {
    color: colors.gray300 + ' !important',
  },
  [s.xs]: {
    margin: '0 0.75rem',
  },
  [s.ss]: {
    margin: '0 0.5rem',
  },
}

const sButtons = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  [s.sm]: {
    padding: '1rem 4rem 0',
  },
  [s.xs]: {
    display: 'none',
  },
}

const sTitle = {
  fontSize: '0.75rem',
  opacity: 0.5,
  display: 'flex',
  alignItems: 'center',
}

export default QRView
