/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_CLICKUP_TASK } from '../../graphql/clickup.graphql'
import { colors, alpha } from '../../style'
import { toast } from 'react-toastify'
import Spinner from '@material-ui/core/CircularProgress'
import ReloadIcon from '@material-ui/icons/Cached'
import { IconButton } from '@material-ui/core'
import ClickupIcon from '../../assets/ClickupIcon'
import { clickuplink } from './ClickupAuthButton'

const ClickupTrackingGetTask = ({ input, token, updateInputs }) => {
  const { loading, data, error, refetch } = useQuery(GET_CLICKUP_TASK, {
    variables: { id: input.replace('#', ''), token },
    skip: !input || !token,
    notifyOnNetworkStatusChange: true,
  })
  const [reloadCount, setReloadCount] = useState(0)

  useEffect(() => {
    refetch()
  }, [input, reloadCount])

  useEffect(() => {
    const task = data?.getClickupTask
    if (task && task.project) {
      updateInputs(task)
    }
  }, [data, reloadCount])

  const task = data?.getClickupTask
  const taskName = task?.name

  return (
    <div
      css={[
        {
          transition: 'color 0.2s ease-out, background-color 0.2s ease-out',
          width: 'max-content',
          maxWidth: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 38,
          position: 'absolute',
          right: 8,
          top: '50%',
          transform: 'translateY(-50%)',
          padding: '0.5rem',
          backgroundColor: colors.gray300,
          borderRadius: '0.25rem',
          lineHeight: '1em',
          fontSize: 10,
          color: colors.black.concat(alpha[60]),
        },
        (error || !token) && {
          backgroundColor: colors.red,
          color: colors.white,
        },
        taskName && !loading && { backgroundColor: colors.green, color: colors.white },
      ]}
    >
      {!loading && taskName}
      {loading && <Spinner size={20} />}
      {error && !loading && 'Error'}
      {!loading && !token && (
        <a href={clickuplink} target='_blank' rel='noreferrer noopener'>
          <IconButton
            // onClick={() => window.open(clickuplink)}
            size='small'
            css={{
              color: 'inherit',
              svg: { width: 14, height: 14, marginLeft: '0.5rem' },
              fontSize: 10,
            }}
          >
            Połącz z ClickUp <ClickupIcon />
          </IconButton>
        </a>
      )}
      {!loading && token && (
        <IconButton onClick={() => setReloadCount((prev) => prev + 1)} size='small' css={{ color: 'inherit' }}>
          <ReloadIcon fontSize='inherit' css={{ width: 14, height: 14, color: 'inherit' }} />
        </IconButton>
      )}
    </div>
  )
}

export default ClickupTrackingGetTask
