/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { Grid, Paper, Avatar, Collapse, IconButton } from '@material-ui/core'
import { alpha, colors } from '../style'
import { imageLink } from '../utils/imageLink'
import { categoryTypeToColor, companyTypeToColor, roleToColor } from '../utils'
import ColorLine from './ColorLine'
import { hoursToTime } from '../utils/hoursToTime'
import { minutesToTime } from '../utils/minutesToTime'
import { useUIContext } from '../context/ui.context'
import EditIcon from '@material-ui/icons/Edit'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { sNow } from './ActivityUserCard'
import ClickupIcon from '../assets/ClickupIcon'

const EstimateCard = ({ data, showCPHDetails }) => {
  const {
    color,
    name,
    icon,
    h_activities,
    h_estimates,
    workDone,
    workLeft,
    items,
    type,
    typename,
    shine,
    costA,
    costE,
    cph,
    id,
    companyId,
  } = data
  const { setEditEstimateOpen } = useUIContext()
  const [isOvertime, setOvertime] = useState(false)
  const [isOverWarning, setOverWarning] = useState(false)
  const [isOverSoon, setOverSoon] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (
      items?.filter((item) => item.h_estimates && item.workDone >= 101).length >
      0
    )
      setOvertime(true)
    else if (workDone >= 101) setOvertime(true)
    else setOvertime(false)

    if (
      items?.filter((item) => item.h_estimates && item.workDone >= 90).length >
      0
    )
      setOverWarning(true)
    else if (workDone >= 90) setOverWarning(true)
    else setOverWarning(false)

    if (
      items?.filter((item) => item.h_estimates && item.workDone >= 80).length >
      0
    )
      setOverSoon(true)
    else if (workDone >= 80) setOverSoon(true)
    else setOverSoon(false)
  }, [items, workDone])

  // useEffect(() => {
  //   if (h_estimates) {
  //     if (workDone >= 101) setOvertime(true)
  //     if (workDone >= 90) setOverWarning(true)
  //     if (workDone >= 80) setOverSoon(true)
  //   }
  // }, [setOvertime, setOverWarning, setOverSoon, workDone, h_estimates])

  // console.log('showCPHDetails', showCPHDetails)

  const costPercentage = (costA / costE) * 100
  const costDiff = costE - costA
  const costIsDebt = costDiff < 0
  const costLeft = costIsDebt
    ? `- ${(costDiff * -1).toFixed(0)} PLN debt`
    : `+ ${costDiff.toFixed(0)} PLN left`

  return (
    <Paper
      elevation={0}
      css={[
        sPaper(color || '#ffffff'),
        isOverSoon && {
          animation:
            'alert-company-oversoon 0.6s linear alternate both infinite',
        },
        isOverWarning && {
          animation:
            'alert-company-overwarning 0.4s linear alternate both infinite',
        },
        isOvertime && {
          animation:
            'alert-company-overtime 0.2s linear alternate both infinite',
        },
      ]}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          lg={12}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          onClick={() => setOpen(!open)}
        >
          <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {typename === 'Company' &&
              (icon ? (
                <img src={imageLink(icon)} css={sIcon} alt={name} />
              ) : (
                <Avatar
                  css={{
                    width: 32,
                    height: 32,
                    marginRight: '1rem',
                    backgroundColor: color || '#ffffff',
                  }}
                >
                  {name.split(' ')?.[0]?.[0]}
                  {name.split(' ')?.[1]?.[0]}
                </Avatar>
              ))}
            <h2 css={[sTitle(color || '#ffffff')]}>
              <div
                css={
                  (typename === 'Category' || typename === 'Project') &&
                  sTitleCategory(color || '#ffffff')
                }
              >
                {name}
                {/* ({companyId}) */}
              </div>
              <span
                css={sSubtitle(
                  typename === 'Company'
                    ? companyTypeToColor(type)
                    : typename === 'Category' || typename === 'Project'
                    ? categoryTypeToColor(type)
                    : roleToColor(type)
                )}
              >
                {type}
              </span>
            </h2>
          </div>
        </Grid>
        <Grid item xs={12} css={{ margin: '1rem 0 0' }}>
          <div css={{ position: 'relative' }}>
            <Grid container spacing={1} onClick={() => setOpen(!open)}>
              <Grid item xs={12}>
                <ColorLine
                  widthIcon
                  lightBack
                  color={color || '#ffffff'}
                  percentage={workDone}
                  isOvertime={h_estimates && workDone >= 101}
                  isOverWarning={h_estimates && workDone >= 90}
                  isOverSoon={h_estimates && workDone >= 80}
                  shine={!open && shine}
                >
                  <div css={sFlex}>
                    {h_activities.toFixed(h_activities > 10 ? 0 : 1)}{' '}
                    {h_estimates ? `/ ${h_estimates}` : ''} <span>&nbsp;h</span>
                    {h_estimates ? (
                      <>
                        <span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;{workDone.toFixed(0)}%
                        </span>
                        <span>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          {hoursToTime(
                            workLeft < 0 ? workLeft * -1 : workLeft,
                            'HH:MM'
                          )}{' '}
                          {workLeft < 0 ? 'overtime' : 'left'}
                        </span>
                      </>
                    ) : (
                      <span>&nbsp;&nbsp;|&nbsp;&nbsp;not estimated</span>
                    )}
                    <div
                      css={[sNow(color), !open && shine && { opacity: 1 }]}
                    />
                  </div>
                </ColorLine>
              </Grid>
            </Grid>
            <IconButton
              size='small'
              aria-label='edit'
              onClick={() =>
                setEditEstimateOpen(
                  typename === 'Category' ? { categoryId: id } : { companyId }
                )
              }
              css={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translate(-8px, -50%)',
                fontSize: '0.875rem',
                opacity: 0.5,
                color,
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <AccessTimeIcon fontSize='inherit' />
            </IconButton>
          </div>
          {open && (
            <Collapse in={open} css={{ width: '100%' }}>
              <div css={[{ paddingTop: '1rem' }]} />
              <Grid container spacing={1}>
                {items.map((item, id) => (
                  <ItemDetails
                    key={id}
                    data={item}
                    typename={typename}
                    h_activities={h_activities}
                    showCPHDetails={showCPHDetails}
                    // setOvertime={setOvertime}
                    // setOverWarning={setOverWarning}
                    // setOverSoon={setOverSoon}
                  />
                ))}
              </Grid>
              {showCPHDetails !== undefined && (
                <ColorLine
                  withIcon
                  extraCss={[
                    { marginTop: '1rem' },
                    !cph && {
                      backgroundColor: 'red',
                      color: 'white',
                      // animation: 'blink 1s infinite ease-in-out'f,
                    },
                  ]}
                  lightBack
                  color={
                    costPercentage >= 80
                      ? costPercentage >= 90
                        ? costPercentage >= 101
                          ? colors.red
                          : colors.orange
                        : colors.yellow
                      : colors.green
                  }
                  percentage={costPercentage}
                  isOvertime={!cph || costPercentage >= 101}
                  isOverWarning={costPercentage >= 90}
                  isOverSoon={costPercentage >= 80}
                  // shine={cph && shine}
                  title={
                    cph ? (
                      <span>
                        Koszt <strong>{costA.toFixed(0)}</strong> /{' '}
                        <strong>{costE.toFixed(0)}</strong> |{' '}
                        <span css={{ opacity: 0.5 }}>CPH: {cph}</span>
                      </span>
                    ) : (
                      <span>BRAK CPH_DEFAULT DLA FIRMY!</span>
                    )
                  }
                >
                  {!!cph && <div css={sFlex}>{costLeft}</div>}
                </ColorLine>
              )}
            </Collapse>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export const ItemDetails = ({
  data,
  typename,
  h_activities,
  h_estimates,
  isNested,
  showCPHDetails,
  // setOvertime,
  // setOverWarning,
  // setOverSoon,
}) => {
  const {
    id,
    name,
    color,
    h_activities: item_activities,
    h_estimates: item_estimates,
    workDone,
    icon,
    authors,
    categories,
    shine,
    costA,
    costE,
    cph,
    estimateIds,
  } = data

  const [open, setOpen] = useState(false)
  const { setEditEstimateOpen } = useUIContext()

  // const isOvertime = workDone >= 101
  // const isOverWarning = workDone >= 90
  // const isOverSoon = workDone >= 80

  const [isOvertime, setIsOvertime] = useState(false)
  const [isOverWarning, setIsOverWarning] = useState(false)
  const [isOverSoon, setIsOverSoon] = useState(false)

  useEffect(() => {
    if (
      categories?.filter(
        (category) => category.h_estimates && category.workDone >= 80
      ).length > 0
    )
      setIsOverSoon(true)
    else if (item_estimates && workDone >= 80) setIsOverSoon(true)
    else setIsOverSoon(false)

    if (
      categories?.filter(
        (category) => category.h_estimates && category.workDone >= 90
      ).length > 0
    )
      setIsOverWarning(true)
    else if (item_estimates && workDone >= 90) setIsOverWarning(true)
    else setIsOverWarning(false)
    if (
      categories?.filter(
        (category) => category.h_estimates && category.workDone >= 101
      ).length > 0
    )
      setIsOvertime(true)
    else if (item_estimates && workDone >= 101) setIsOvertime(true)
    else setIsOvertime(false)
  }, [
    setIsOverSoon,
    setIsOverWarning,
    setIsOvertime,
    categories,
    item_estimates,
    workDone,
  ])

  // useEffect(() => {
  //   if (isOvertime) setOvertime(true)
  //   if (isOverSoon) setOverSoon(true)
  //   if (isOverWarning) setOverWarning(true)
  // }, [
  //   isOvertime,
  //   isOverSoon,
  //   isOverWarning,
  //   setOvertime,
  //   setOverWarning,
  //   setOverSoon,
  // ])

  return (
    <Grid
      item
      xs={isNested ? 11 : 12}
      css={[
        {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        },
      ]}
    >
      <div
        css={[
          {
            position: 'absolute',
            top: 4,
            left: 4,
            width: 2,
            height: 'calc(100% - 1.25rem)',
            backgroundColor: color || '#ffffff',
            transition: 'opacity 0.2s ease-out',
          },
          !open && { opacity: 0 },
        ]}
      />
      <div>
        <ColorLine
          onClick={() => setOpen(!open)}
          withIcon
          title={
            typename === 'Category' || typename === 'Project' ? (
              icon ? (
                <>
                  <img src={imageLink(icon)} css={sIconSmall} alt={name} />
                  {name}
                  {/* ({id}) */}
                </>
              ) : (
                <>
                  <div css={sIconSmall} />
                  {name}
                  {/* ({id}) */}
                </>
              )
            ) : (
              <>
                {name}
                {/* ({id}) */}
              </>
            )
          }
          color={color || '#ffffff'}
          percentage={
            item_estimates && item_activities
              ? (item_activities / item_estimates) * 100
              : (item_activities / h_activities) * 100
          }
          isOvertime={isOvertime}
          isOverWarning={isOverWarning}
          isOverSoon={isOverSoon}
          // isOvertime={item_estimates && workDone >= 101}
          // isOverWarning={item_estimates && workDone >= 90}
          // isOverSoon={item_estimates && workDone >= 80}
          shine={!open && shine}
        >
          <div css={sFlex}>
            <div css={[sNow(color), !open && shine && { opacity: 1 }]} />
            {isNested ? (
              <div>
                {hoursToTime(item_activities, 'HH:MM')}
                {item_estimates ? ` / ${item_estimates} h` : ''}
                <span>
                  &nbsp;&nbsp;
                  {item_estimates
                    ? ((item_activities / item_estimates) * 100).toFixed(0)
                    : ((item_activities / h_activities) * 100).toFixed(0)}
                  %
                </span>
              </div>
            ) : (
              <div>
                {item_activities.toFixed(item_activities > 10 ? 0 : 1)} /{' '}
                {item_estimates} <span>h</span>
                {item_estimates ? (
                  <span>&nbsp;&nbsp;{workDone.toFixed(0)}%</span>
                ) : (
                  <span>&nbsp;&nbsp;?</span>
                )}
              </div>
            )}
          </div>
          {estimateIds.length > 0 && (
            <svg
              onClick={() => setEditEstimateOpen({ estimateIds })}
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              css={{
                width: 16,
                height: 16,
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translate(-8px, -50%)',
                fontSize: '0.875rem',
                opacity: 1,
                color: 'inherit',
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
          )}
        </ColorLine>
      </div>
      {open && (
        <Collapse in={open}>
          <Grid
            container
            spacing={1}
            justifyContent='center'
            css={{ padding: '0.5rem 0 1rem' }}
          >
            {authors &&
              authors?.map((author, id) => (
                <ItemAuthors
                  key={id}
                  data={author}
                  item_activities={item_activities}
                  item_estimates={item_estimates}
                />
              ))}
            {categories &&
              categories?.map((category, id) => (
                <ItemDetails
                  key={id}
                  data={category}
                  typename='Category'
                  h_activities={item_activities}
                  // h_estimates={h_estimates}
                  isNested
                  // setOvertime={setOvertime}
                  // setOverWarning={setOverWarning}
                  // setOverSoon={setOverSoon}
                  showCPHDetails={showCPHDetails}
                />
              ))}
          </Grid>
        </Collapse>
      )}
      {showCPHDetails && (
        <span
          css={{
            fontSize: '0.675rem',
            opacity: 0.5,
            padding: '0.25rem 0.5rem 0.25rem',
          }}
        >
          {costA.toFixed(0)} / {costE.toFixed(0)} | cph: {cph}
        </span>
      )}
    </Grid>
  )
}

export const ItemAuthors = ({
  data,
  item_activities,
  item_estimates,
  useWorkDonePercentage,
}) => {
  const {
    name,
    color,
    h_activities: person_activities,
    image,
    workDone,
    tasks,
    shine,
  } = data

  // console.log(name, 'item_activities', item_activities)
  // console.log(name, 'person_activities', person_activities)
  // console.log(name, 'workDone', workDone)

  const { setEditActivityOpen } = useUIContext()

  const [open, setOpen] = useState(false)

  return (
    <Grid
      item
      xs={11}
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div
        css={[
          {
            position: 'absolute',
            top: 4,
            left: 4,
            width: 2,
            height: 'calc(100% - 1.25rem)',
            backgroundColor: color,
            transition: 'opacity 0.2s ease-out',
          },
          !open && { opacity: 0 },
        ]}
      />
      <div onClick={() => setOpen(!open)}>
        <ColorLine
          lightBack
          title={
            <>
              {image ? (
                <img src={imageLink(image)} css={sIconSmall} alt={name} />
              ) : (
                <Avatar css={[sIconSmall, { backgroundColor: color }]}>
                  {name.split(' ')?.[0]?.[0]}
                  {name.split(' ')?.[1]?.[0]}
                </Avatar>
              )}
              {name}
            </>
          }
          color={color || '#FFFFFF'}
          percentage={
            useWorkDonePercentage
              ? workDone
              : // item_estimates && item_activities
                //   ? (person_activities / item_estimates) * 100
                //   : (person_activities / item_activities) * 100
                (person_activities / item_activities) * 100
          }
          onClick={() => setOpen(!open)}
          shine={!open && shine}
        >
          <div css={sFlex}>
            <div css={[sNow(color), !open && shine && { opacity: 1 }]} />
            {hoursToTime(person_activities, 'HH:MM')}
            <span>&nbsp;&nbsp;{workDone.toFixed(0)}%</span>
          </div>
        </ColorLine>
      </div>
      {open && (
        <Collapse in={open}>
          <Grid
            container
            spacing={1}
            justifyContent='center'
            css={{ padding: '0.5rem 0 1rem', position: 'relative' }}
          >
            {tasks?.map((task, id) => (
              <Grid item xs={11} key={id} css={{ position: 'relative' }}>
                <ColorLine
                  lightBack
                  lessVisible
                  title={task.title}
                  color={color}
                  percentage={(task.minutes / 60 / person_activities) * 100}
                  shine={!task.timeEnd}
                  css={{ cursor: 'pointer' }}
                >
                  <div
                    css={[
                      sFlex,
                      {
                        span: { whiteSpace: 'nowrap' },
                      },
                    ]}
                  >
                    <div css={[sNow(color), !task.timeEnd && { opacity: 1 }]} />
                    <span>{task.date.slice(0, 10)}</span>
                    &nbsp;&nbsp;
                    {minutesToTime(task.minutes, 'HH:MM')}
                    <span>
                      &nbsp;&nbsp;
                      {((task.minutes / 60 / person_activities) * 100).toFixed(
                        0
                      )}
                      %
                    </span>
                  </div>
                </ColorLine>
                <IconButton
                  size='small'
                  aria-label='edit'
                  onClick={() => setEditActivityOpen(task._id)}
                  css={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translate(1rem, -50%)',
                    fontSize: '0.875rem',
                  }}
                >
                  <EditIcon fontSize='inherit' />
                </IconButton>
                {task?.clickup && (
                  <a
                    href={`clickup://t/${task.clickup.replace('#', '')}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <IconButton
                      size='small'
                      aria-label='edit'
                      css={{
                        position: 'absolute',
                        top: '50%',
                        right: 0,
                        transform: 'translate(2rem, -50%)',
                        fontSize: '0.875rem',
                        svg: { width: '0.875rem', height: '0.875rem' },
                      }}
                    >
                      <ClickupIcon />
                    </IconButton>
                  </a>
                )}
              </Grid>
            ))}
          </Grid>
        </Collapse>
      )}
    </Grid>
  )
}

export const sPaper = (color) => ({
  // background: `linear-gradient(to left, ${color.concat(
  //   alpha[12]
  // )}, ${color.concat(alpha[25])})`,
  background: 'white',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  boxShadow: '2px 2px 8px rgb(0 0 0 / 8%)',
  cursor: 'pointer',
})
export const sTitle = (color) => ({
  // color: color.concat(alpha[50]),
  color: 'black',
  fontSize: '0.75rem',
  textShadow: '0px 0px rgb(0 0 0 /50%)',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})
export const sTitleCategory = (color) => ({
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color.concat(alpha[25]) || 'rgba(0,0,0,0.25)',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  overflow: 'hidden',
  padding: '0.25rem 0.5rem',
  color: color.concat(alpha[50]) || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})
export const sSubtitle = (color) => ({
  textShadow: '0px 0px rgb(0 0 0 /50%)',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  // color: 'white',
  width: 'max-content',
  fontSize: 8,
  marginLeft: '1rem',
  backgroundColor: color.concat(alpha[12]),
  color,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const sIcon = {
  width: 32,
  height: 32,
  marginRight: '1rem',
}
export const sIconSmall = {
  width: 12,
  height: 12,
  marginRight: '0.5rem',
  fontSize: 8,
}

const sFlex = {
  display: 'flex',
  alignItems: 'center',
}

export default EstimateCard
