/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import { GET_ALL_TASKS } from '../../graphql/task.graphql'
import Skeleton from '@material-ui/lab/Skeleton'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import UserContainer from './UserContainer'
import ActivitiesEditWrapper from '../Activities/ActivitiesEditWrapper'
import EstimateEditWrapper from '../Estimates/EstimateEditWrapper'

const AllTasks = () => {
  const { data, loading, error } = useQuery(GET_ALL_TASKS)

  if (error) {
    toast.error(error.toString())
    return <div>{error.toString()}</div>
  }

  if (loading) {
    return (
      <>
        <PageTitle>
          <h1>Team Assigned Tasks</h1>
          <p>
            <span>
              Estymacje przypisane innym użytkownikom, zawierające zadanie
              ClickUp z bieżącego miesiąca.
            </span>
          </p>
        </PageTitle>
        <PageWrapper>
          <PageWrapper>
            <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
          </PageWrapper>
        </PageWrapper>
      </>
    )
  }

  let users = _.uniqBy(data?.getAllTasks?.map((t) => t.users).flat(), '_id')

  if (!data || !users?.length > 0)
    return (
      <PageTitle>
        <h1>Team Assigned Tasks</h1>
        <p>
          <span>Nie znaleziono zadań</span>
        </p>
      </PageTitle>
    )

  let tasks = {}

  for (let user of users) {
    const foundTasks = data.getAllTasks.filter(
      (dataTask) =>
        dataTask.users.filter((taskUser) => taskUser._id === user._id).length >
        0
    )
    const activs = foundTasks.reduce(
      (acc, curr) =>
        acc + curr.activities.reduce((acc1, curr1) => acc1 + curr1.minutes, 0),
      0
    )

    const estims =
      foundTasks.reduce((acc, curr) => acc + curr.estimate.hours, 0) * 60

    tasks = {
      ...tasks,
      [user._id]: { ...user, tasks: foundTasks, activs, estims },
    }
  }

  return (
    <>
      <PageTitle>
        <h1>Team Assigned Tasks</h1>
        <p>
          <span>
            Estymacje przypisane innym użytkownikom, zawierające zadanie ClickUp
            z bieżącego miesiąca.
          </span>
        </p>
      </PageTitle>
      <PageWrapper>
        <PageWrapper>
          <Grid container spacing={3}>
            {Object.values(tasks).map((user) => (
              <UserContainer data={user} key={user._id} />
            ))}
          </Grid>
        </PageWrapper>
      </PageWrapper>
      <ActivitiesEditWrapper />
      <EstimateEditWrapper />
    </>
  )
}

export default AllTasks
