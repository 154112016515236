import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { BrowserRouter } from 'react-router-dom'

// Material Ui
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

// Apollo Client
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { USER_STORAGE } from './constants'

// Apollo Client
const httpLink = createHttpLink({
  uri: `${process.env[`REACT_APP_SERVER_URI_${process.env.NODE_ENV}`]}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const storageData = JSON.parse(localStorage.getItem(USER_STORAGE))
  const token = storageData?.token
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       toast.error(message)
//     )

//   if (networkError) toast.error(networkError)
// })

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // link: from([errorLink, httpLink, authLink]),
  cache: new InMemoryCache(),
})

// prettier-ignore
function log () {
  console.log(process.env.NODE_ENV === 'production' ? `🚀 PRODUCTION` : `⚙️ DEVELOPMENT`)
  console.log(`⭐ SERVER: `,process.env[`REACT_APP_SERVER_URI_${process.env.NODE_ENV}`])
  console.log(`☄  SOCKET:`,process.env[`REACT_APP_WEBSOCKET_URI_${process.env.NODE_ENV}`])
}
log()

// Material UI
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#007aff',
      },
      secondary: {
        main: '#5393ff',
      },
    },
    // typography: {
    //   fontFamily: ['Gilroy'].join(','),
    // },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 844,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  plPL
)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <ApolloProvider client={client}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ApolloProvider>
        </StylesProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
