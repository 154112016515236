/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PageWrapper from '../../components/PageWrapper'
import { GET_ACTIVITIES } from '../../graphql/activity.graphql'
import { useAuthContext } from '../../context/auth.context'
import { toast } from 'react-toastify'
import Skeleton from '@material-ui/lab/Skeleton'
import { timeDiff } from '../../utils/timeDiff'
import EstimatesSimpleCharts from './EstimatesSimpleCharts'
import EstimatesCompanies from './EstimatesCompanies'
import EstimatesDetails from './EstimatesDetails'
import EstimatesCategories from './EstimatesCategories'
import PageTitle from '../../components/PageTitle'
import { FormControlLabel, Select, Switch } from '@material-ui/core'
import { sSwitchLabel } from '../Activities/Activities'

const EstimatesData = ({
  estimates,
  inputValues,
  companyTypeState,
  viewCompanies,
  viewCategories,
}) => {
  const { userId } = useAuthContext()
  const [sortBy, setSortBy] = useState('name')
  const [now, setNow] = useState('')
  const [showCPHDetails, setShowCPHDetails] = useState(false)

  const skip = !inputValues?.dateFrom || !inputValues?.dateTo || !userId

  const {
    data: activitiesData,
    loading: activitiesLoading,
    error: activitiesError,
  } = useQuery(GET_ACTIVITIES, {
    variables: inputValues,
    skip,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().toString().slice(16, 21))
    }, 1000)

    return () => clearInterval(interval)
  }, [setNow])

  if (activitiesError) {
    toast.error(activitiesError.toString())
    return <div>Error</div>
  }

  if (activitiesLoading)
    return (
      <PageWrapper>
        <div css={sOuter}>
          <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
        </div>
      </PageWrapper>
    )

  if (skip)
    return (
      <div css={sOuter}>
        <PageWrapper>
          <h5>Select time range to include activities</h5>
        </PageWrapper>
      </div>
    )

  let activities

  // filter companyType
  activities = activitiesData?.getActivities?.filter((activity) =>
    companyTypeState ? activity.company.type === companyTypeState : true
  )

  // filter zero minutes
  activities = activities.filter((activity) =>
    activity.timeEnd && activity.minutes < 1 ? false : true
  )

  // add current tasks
  activities = activities.map((activity) => ({
    ...activity,
    minutes:
      !activity.minutes && now
        ? timeDiff(activity.timeStart, now)
        : activity.minutes,
  }))

  if (!activities || activities.length < 1)
    return (
      <div css={sOuter}>
        <PageWrapper>
          <h5>No activities found</h5>
        </PageWrapper>
      </div>
    )

  return (
    <>
      <div css={sOuter}>
        <PageWrapper>
          <EstimatesDetails activities={activities} estimates={estimates} />
        </PageWrapper>

        {viewCompanies && (
          <>
            <PageTitle>
              <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Companies</h2>
                <div css={{ display: 'flex' }}>
                  <Select
                    native
                    variant='outlined'
                    size='small'
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    css={{ marginRight: '1rem', fontSize: '1rem' }}
                  >
                    <option value='name' defaultValue='name'>
                      Nazwa
                    </option>

                    <option value='h_activities'>Godziny</option>
                    <option value='workDone'>Procent</option>
                    {/* <option value='workDone'>Done</option> */}
                    {/* <opiton value='h_activities'>Procent</opiton>
                    <option value='workDone'>Done</option>
                    <option value='workDone'>Done</option> */}
                  </Select>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowCPHDetails(!showCPHDetails)}
                  >
                    <FormControlLabel
                      control={<Switch checked={showCPHDetails} />}
                      css={{ marginRight: 0 }}
                    />
                    <span css={sSwitchLabel}>CPH DETAILS</span>
                  </div>
                </div>
              </div>
            </PageTitle>
            <PageWrapper>
              <EstimatesCompanies
                activities={activities}
                estimates={estimates}
                showCPHDetails={showCPHDetails}
                sortBy={sortBy}
              />
            </PageWrapper>
          </>
        )}
        {viewCategories && (
          <>
            {' '}
            <PageTitle>
              <h2>Categories</h2>
            </PageTitle>
            <PageWrapper>
              <EstimatesCategories
                activities={activities}
                estimates={estimates}
              />
            </PageWrapper>
          </>
        )}
        <PageTitle>
          <h2>Charts</h2>
        </PageTitle>
        <PageWrapper>
          <EstimatesSimpleCharts
            activities={activities}
            estimates={estimates}
          />
        </PageWrapper>
      </div>
    </>
  )
}

const sOuter = {
  width: '100%',
  paddingTop: '2rem',
  '.MuiDataGrid-cell': { fontSize: '0.75rem' },
}

export default EstimatesData
