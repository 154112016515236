import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import Seo from '../../components/Seo'

const Companies = () => {
  return (
    <>
      <Seo title='💼 Companies' />
      <PageTitle>
        <h1>Companies</h1>
      </PageTitle>
      <PageWrapper></PageWrapper>
    </>
  )
}

export default Companies
