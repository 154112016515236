/** @jsxImportSource @emotion/react */

import {useState, useRef, useEffect} from 'react'
import _ from 'lodash'

import {useQuery, useMutation} from '@apollo/client'
import {
    PROJECT_UPDATE,
    PROJECT_CREATE,
    GET_PROJECTS_SETTINGS,
    DELETE_PROJECT
} from '../../graphql/project.graphql'
import {GET_COMPANIES_NAMES} from '../../graphql/company.graphql'
import {toast} from 'react-toastify'
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Button,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Skeleton from '@material-ui/lab/Skeleton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {imageLink} from '../../utils/imageLink'

const SettingsProjects = () => {
    const {data, loading, error} = useQuery(GET_PROJECTS_SETTINGS)
    const {
        data: companies,
        loading: loadingCompanies,
        error: errorCompanies,
    } = useQuery(GET_COMPANIES_NAMES)

    const [projectCreate] = useMutation(PROJECT_CREATE)
    const [projectUpdate] = useMutation(PROJECT_UPDATE)
    const [projectDelete] = useMutation(DELETE_PROJECT)
    const [open, setOpen] = useState(false)
    const tableRef = useRef(null)

    const scrollToBottom = () => {
        tableRef.current?.scrollTo({
            top: tableRef.current.scrollHeight,
            behavior: 'smooth',
        })
    }

    if (loading || loadingCompanies)
        return <Skeleton variant='rect' height={300} css={{width: '100%'}}/>

    if (error || errorCompanies) {
        toast.error(error.toString())
        return <div>{error.toString()}</div>
    }

    const onUpdateSubmit = ({id, input, callback}) => {
        const newInput = {
            ..._.omit(input, ['__typename', '_id']),
            company: input.company?._id,
        }
        projectUpdate({
            variables: {id, input: newInput},
            refetchQueries: [{query: GET_PROJECTS_SETTINGS}],
        })
            .then((res) => {
                if (res?.data?.projectUpdate)
                    toast.info(
                        <div>
                            <strong>
                                🔼&nbsp;&nbsp;<span>UPDATED</span>
                            </strong>
                            <p>
                                <strong>{input.name}</strong>
                                {input.company?.name && (
                                    <span>&nbsp;({input.company.name})</span>
                                )}
                            </p>
                        </div>,
                        {autoClose: 10000}
                    )
                callback()
            })
            .catch((error) => {
                toast.error(error.toString())
            })
    }

    const onCreateSubmit = ({input, callback}) => {
        const newInput = {..._.pickBy(input), company: input.company?._id}
        projectCreate({
            variables: {input: newInput},
            update: (cache, {data}) => {
                const projectsCache = cache.readQuery({
                    query: GET_PROJECTS_SETTINGS,
                })
                cache.writeQuery({
                    query: GET_PROJECTS_SETTINGS,
                    data: {
                        getProjects: [...projectsCache?.getProjects, data?.projectCreate],
                    },
                })
            },
            // refetchQueries: [{ query: GET_PROJECTS_SETTINGS }],
        })
            .then((res) => {
                if (res?.data?.projectCreate)
                    toast.success(
                        <div>
                            <strong>
                                🔼&nbsp;&nbsp;<span>CREATED</span>
                            </strong>
                            <p>
                                <strong>{input.name}</strong>
                                {input.company?.name && (
                                    <span>&nbsp;({input.company.name})</span>
                                )}
                            </p>
                        </div>,
                        {autoClose: 10000}
                    )
                callback()
                scrollToBottom()
            })
            .catch((error) => {
                toast.error(error.toString())
            })
    }

    const onDeleteSubmit = (projectId) => {

        if (window.confirm("Are you sure you want to delete this project?")) {
            projectDelete({
                variables: {id: projectId},
                refetchQueries: [{query: GET_PROJECTS_SETTINGS}],
            })
                .then((res) => {
                    if (res.data.projectDelete) {
                        toast.success(
                            <div>
                                <strong>
                                    🔽&nbsp;&nbsp;<span>DELETED</span>
                                </strong>
                                <p>
                                    The project was successfully removed
                                </p>
                            </div>,
                            {autoClose: 10000}
                        );

                    }
                })
                .catch((error) => {
                    toast.error(error.toString());
                });
        }
    };


    const projects = _.sortBy(data.getProjects, 'company.name')

    return (
        <>
            <Paper elevation={1}>
                <TableContainer css={{maxHeight: '50vh'}} ref={tableRef}>
                    <Table stickyHeader aria-label='collapsible table' size='small'>
                        <TableHead
                            css={{
                                th: {fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)'},
                            }}>
                            <TableRow>
                                <TableCell css={{paddingLeft: '3rem'}}>Project</TableCell>
                                <TableCell css={{paddingLeft: '3rem'}} align='left'>
                                    Company
                                </TableCell>
                                <TableCell align='right'>Desc</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projects &&
                                projects.map((row, id) => (
                                    <Row
                                        key={row._id}
                                        data={row}
                                        onSubmit={onUpdateSubmit}
                                        onDelete={onDeleteSubmit}
                                        companies={companies.getCompanies}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Paper elevation={1}>
                <Collapse in={open}>
                    <Form
                        open={open}
                        setOpen={setOpen}
                        onSubmit={onCreateSubmit}
                        button={{title: 'CREATE'}}
                        companies={companies.getCompanies}
                    />
                </Collapse>
            </Paper>
            <Button
                css={{marginTop: '1rem'}}
                size='small'
                onClick={() => setOpen(!open)}
                color={open ? 'default' : 'primary'}
                variant='contained'>
                {!open ? 'NEW PROJECT' : 'CANCEL CREATION'}
            </Button>
        </>
    )
}

const Row = ({data, onSubmit, onDelete, companies}) => {
    const [open, setOpen] = useState(false)
    const {name, description, color, isHidden, company} = data

    return (
        <>
            <TableRow
                css={[
                    isHidden && {opacity: 0.25},
                    {
                        td: {fontSize: '0.75rem'},
                        '&:hover': {
                            backgroundColor: '#fcfcfc',
                        },
                    },
                ]}>
                <TableCell component='th' scope='row'>
                    <div
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 'inherit',
                        }}>
                        <div
                            css={{
                                width: 8,
                                height: 8,
                                marginLeft: 8,
                                backgroundColor: color || 'transparent',
                                border: color ? 'none' : '1px solid black',
                                transform: 'rotate(45deg)',
                            }}
                        />

                        <span
                            css={{
                                paddingLeft: '1rem',
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                            }}>
              {name}
            </span>
                    </div>
                </TableCell>
                <TableCell>
                    <div
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 'inherit',
                        }}>
                        {company?.icon ? (
                            <img
                                src={imageLink(company.icon)}
                                css={{width: 16, height: 16}}
                                alt={name}
                            />
                        ) : (
                            <div css={{width: 16, height: 16, backgroundColor: 'white'}}/>
                        )}
                        <span
                            css={{
                                paddingLeft: '1rem',
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                            }}>
                            {company?.name ? company?.name : ''}
                        </span>
                    </div>

                </TableCell>
                <TableCell align='right' css={{opacity: 0.5, fontStyle: 'italic'}}>
                    {description}
                </TableCell>
                <TableCell>
                    <IconButton aria-label="delete" size="small" onClick={() => onDelete(data._id)}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => setOpen(!open)}>
                        {open ? <CloseIcon/> : <EditIcon/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow css={{backgroundColor: '#f8f8f8', width: '100%'}}>
                <TableCell css={{width: '100%', padding: 0, margin: 0}} colSpan={10}>
                    <Collapse
                        in={open}
                        timeout='auto'
                        unmountOnExit
                        css={{width: '100%'}}>
                        <Form
                            open={open}
                            setOpen={setOpen}
                            data={data}
                            onSubmit={onSubmit}
                            button={{title: 'UPDATE'}}
                            companies={companies}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export const Form = ({onSubmit, open, setOpen, data, button, companies}) => {
    const initialValues = {
        name: '',
        company: '',
        color: '#' + Math.floor(Math.random() * 16777215).toString(16),
        description: '',
    }

    const [inputValues, setInputValues] = useState(
        open ? data || initialValues : initialValues
    )

    const handleOnChange = (e) =>
        setInputValues({...inputValues, [e.target.name]: e.target.value})

    useEffect(() => {
        return () => setInputValues(initialValues)
    }, [open])

    // console.log(inputValues)

    return (
        <Grid
            container
            css={{
                // maxWidth: 'calc(100vw - 2rem)',
                width: '100%',
                padding: '1rem',
                justifyContent: 'space-between',
            }}
            spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    name='name'
                    label='Name'
                    type='text'
                    required
                    value={inputValues.name}
                    onChange={handleOnChange}
                    css={sInput}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                <Autocomplete
                    value={inputValues.company}
                    onChange={(event, newValue) => {
                        setInputValues({
                            ...inputValues,
                            company: newValue,
                        })
                    }}
                    id='company'
                    autoComplete={false}
                    options={companies}
                    getOptionLabel={(option) => (option.name ? option.name : '')}
                    renderOption={(option, {selected}) => (
                        <div
                            css={{
                                fontSize: '0.75rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            {option.icon ? (
                                <img
                                    src={option.icon}
                                    css={{width: 16, height: 16, marginRight: 16}}
                                    loading='lazy'
                                    alt={option.name}
                                />
                            ) : (
                                <div
                                    css={{
                                        width: 16,
                                        height: 16,
                                        marginRight: 16,
                                        border: '1px solid black',
                                    }}
                                />
                            )}
                            {option.name}
                        </div>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // inputProps={{
                            //   autocomplete: 'company',
                            //   form: {
                            //     autocomplete: 'off',
                            //   },
                            // }}
                            autoComplete='off'
                            variant='outlined'
                            label='Company'
                            placeholder='Company'
                            size='small'
                            css={sInput}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    name='description'
                    label='Description'
                    type='text'
                    value={inputValues.description}
                    onChange={handleOnChange}
                    css={sInput}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    name='color'
                    label='Color'
                    type='color'
                    value={inputValues.color}
                    onChange={handleOnChange}
                    css={sInput}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={1}>
                <FormControlLabel
                    css={{span: {fontSize: '0.75rem'}}}
                    control={
                        <>
                            <Switch
                                name='isHidden'
                                checked={inputValues.isHidden}
                                onChange={() =>
                                    setInputValues({
                                        ...inputValues,
                                        isHidden: !inputValues.isHidden,
                                    })
                                }
                                color='primary'
                            />
                        </>
                    }
                    label=''
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={1}>
                <Button
                    onClick={() =>
                        onSubmit({id: data?._id, input: inputValues, callback: setOpen})
                    }
                    size='small'
                    variant='contained'
                    disabled={!inputValues.name}
                    css={{
                        width: '100%',
                        backgroundColor: 'green',
                        color: 'white',
                        fontSize: '0.75rem',
                        '&:hover': {
                            backgroundColor: 'green',
                        },
                    }}>
                    {button.title}
                </Button>
            </Grid>
        </Grid>
    )
}

const sInput = {
    input: {fontSize: '0.75rem'},
    label: {fontSize: '0.75rem'},
}

export default SettingsProjects
