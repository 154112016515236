import { gql } from '@apollo/client'

export const CATEGORY_UPDATE = gql`
  mutation categoryUpdate($id: ID!, $input: CategoryUpdateInput) {
    categoryUpdate(id: $id, input: $input) {
      _id
      name
      type
      color
      isHidden
    }
  }
`

export const CATEGORY_CREATE = gql`
  mutation categoryCreate($input: CategoryCreateInput!) {
    categoryCreate(input: $input) {
      _id
      name
      type
      color
      isHidden
    }
  }
`

export const GET_CATEGORIES = gql`
  query getCategories {
    getCategories {
      _id
      name
      type
      color
      isHidden
    }
  }
`
