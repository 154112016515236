/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { sLink, sLinkIcon, sText } from '../../components/Menu'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import Seo from '../../components/Seo'
import { GET_NAMES } from '../../graphql/data.graphql'
import { s } from '../../style'
import QRIcon from '@material-ui/icons/CropFree'
import QRBoard from './QRBoard'
import QRInputs from './QRInputs'
import QRView from './QRView'
import QRScanner from './QRScanner'

const QRCodes = () => {
  const [selectedCodes, setSelectedCodes] = useState([])
  const [viewCode, setViewCode] = useState({})
  const [scannerView, setScannerView] = useState(false)

  const {
    data: namesData,
    loading: namesLoading,
    error: namesError,
  } = useQuery(GET_NAMES)

  useEffect(() => {
    const stored = localStorage.getItem('qrcodes')
    const saved = stored ? JSON.parse(stored) : []
    if (saved.length > 0) setSelectedCodes(saved)
  }, [])

  return (
    <>
      <Seo title='🤳🏻 QR Codes' />
      <PageTitle>
        <h1
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [s.sm_down]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          }}>
          <span>QR Codes</span>
          <div
            onClick={() => setScannerView(!scannerView)}
            css={[sLink, { width: 'max-content' }]}>
            <div css={sLinkIcon}>
              <QRIcon fontSize='inherit' />
            </div>
            <div css={[sText]}>QR Scanner</div>
          </div>
        </h1>
        <p>
          <span>
            Wygeneruj kod QR, który uruchomi tracking wybranej aktywności.
            Stwórz plik PDF z listą najczęściej trackowanych aktywności, którą
            będziesz mógł wydrukować. Skorzystaj z funkcjonalności AUTO, dzięki
            której tracking zostanie uruchomiony tuż po zeskanowaniu kodu,
            zatrzymując poprzednie aktywności oraz aktywując nowy widok mobilny.
            Pamiętaj, że{' '}
            <strong>
              musisz być zalogowanym do swojego konta CITO na urządzeniu
            </strong>
            , na którym skanujesz kod. Po zeskanowaniu aplikacja CITO otworzy
            się w przeglądarce i uruchomi tracking.
          </span>
        </p>
      </PageTitle>
      <PageWrapper>
        <PageWrapper>
          {namesLoading && (
            <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
          )}
          {namesData && (
            <QRInputs
              namesData={namesData}
              setSelectedCodes={setSelectedCodes}
              setViewCode={setViewCode}
            />
          )}
        </PageWrapper>
        <PageWrapper></PageWrapper>
        {selectedCodes?.length > 0 && (
          <PageWrapper>
            <QRBoard
              selectedCodes={selectedCodes}
              setSelectedCodes={setSelectedCodes}
              setViewCode={setViewCode}
            />
          </PageWrapper>
        )}
      </PageWrapper>

      <QRView
        viewCode={viewCode}
        setViewCode={setViewCode}
        setSelectedCodes={setSelectedCodes}
        options
      />
      <QRScanner scannerView={scannerView} setScannerView={setScannerView} />
    </>
  )
}

export default QRCodes
