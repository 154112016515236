import { Helmet } from 'react-helmet'
import packageJson from '../../package.json'
import { updates } from '../views/Version/Version'

const Seo = ({ title, desc }) => {
  return (
    <Helmet>
      <title>
        {title ? `${title} | ` : ''}
        {packageJson.title} {updates[0].version}
      </title>
      {title && <meta name='og:title' content={title} />}
      <meta name='og:description' content={desc || packageJson.description} />
      <meta name='description' content={desc || packageJson.description} />
    </Helmet>
  )
}

export default Seo
