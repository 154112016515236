/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import { useQuery } from '@apollo/client'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import Seo from '../../components/Seo'
import { GET_ESTIMATES } from '../../graphql/estimate.graphql'
import { toast } from 'react-toastify'
import EstimatesSettings from './EstimatesSettings'
import EstimatesInputs from './EstimatesInputs'
import EstimatesData from './EstimatesData'
import ActivitiesEditWrapper from '../Activities/ActivitiesEditWrapper'
import EstimateEditWrapper from '../Estimates/EstimateEditWrapper'

const Estimates = () => {
  const nowYear = new Date().getFullYear()
  const nowMonth = new Date().getMonth()

  const initialValues = {
    companies: [],
    categories: [],
    projects: [],
    dateFrom: new Date(nowYear, nowMonth, 1, 2, 0).toISOString().slice(0, 10),
    dateTo: new Date(nowYear, nowMonth + 1, 0, 24, 59)
      .toISOString()
      .slice(0, 10),
  }

  const [inputValues, setInputValues] = useState(initialValues)
  const [companyTypeState, setCompanyTypeState] = useState('')

  const {
    data: estimatesData,
    loading: estimatesLoading,
    error: estimatesError,
  } = useQuery(GET_ESTIMATES, {
    variables: inputValues,
  })

  if (estimatesError) {
    toast.error(estimatesError.toString())
    return <div>Error</div>
  }

  const estimates = estimatesData?.getEstimates
    ?.filter((estimate) =>
      companyTypeState ? estimate.company.type === companyTypeState : true
    )
    .sort((a, b) => (a.company.name > b.company.name ? 1 : -1))

  return (
    <>
      <Seo title='♾ Estimates' />
      <PageTitle>
        <h1>Estimates</h1>
      </PageTitle>
      <div css={sPage}>
        <EstimatesInputs
          inputValues={inputValues}
          setInputValues={setInputValues}
          companyTypeState={companyTypeState}
          setCompanyTypeState={setCompanyTypeState}
          loading={estimatesLoading}
          initialValues={initialValues}
        />
      </div>

      {!estimatesLoading && (
        <EstimatesData
          viewCompanies
          viewCategories
          estimates={estimates}
          inputValues={inputValues}
          companyTypeState={companyTypeState}
        />
      )}

      <PageWrapper>
        <EstimatesSettings
          estimates={estimates || []}
          estimatesLoading={estimatesLoading}
          getEstimatesVaraibles={inputValues}
        />
      </PageWrapper>
      <ActivitiesEditWrapper />
      <EstimateEditWrapper />
    </>
  )
}

const sPage = {
  padding: '0 0 2rem',
}

export default Estimates
