import { gql } from '@apollo/client'

export const USER_CLICKUP_AUTH = gql`
  mutation userClickupAuth($code: String!, $clientId: String!) {
    userClickupAuth(code: $code, clientId: $clientId) {
      message
      token
    }
  }
`

export const GET_CLICKUP_TASK = gql`
  query getClickupTask($id: ID!, $token: String) {
    getClickupTask(id: $id, token: $token) {
      name
      company
      project
    }
  }
`
