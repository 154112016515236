/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import Seo from '../../components/Seo'
import TeamNow from './TeamNow'
import TeamActivities from './TeamActivities'
import { useAuthContext } from '../../context/auth.context'
import { roleNameToValue } from '../../utils'
import { MANAGER } from '../../constants'
import ActivitiesEditWrapper from '../Activities/ActivitiesEditWrapper'
// import { useUIContext } from '../../context/ui.context'

const Team = () => {
  const { userRole } = useAuthContext()
  const [now, setNow] = useState('')
  const isManager = userRole >= roleNameToValue(MANAGER)
  // const { editActivityOpen } = useUIContext()
  // console.log(editActivityOpen)

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().toString().slice(16, 21))
    }, 1000)

    return () => clearInterval(interval)
  }, [setNow])

  return (
    <>
      <Seo title='👨‍👩‍👧‍👦 Team' />

      {isManager ? (
        <>
          <PageTitle>
            <h1>Team Activities</h1>
          </PageTitle>
          <PageWrapper>
            <TeamActivities now={now} />
          </PageWrapper>
          <ActivitiesEditWrapper />
        </>
      ) : (
        <>
          <PageTitle>
            <h1>Team</h1>
          </PageTitle>
          <PageWrapper>
            <TeamNow now={now} />
          </PageWrapper>
        </>
      )}
    </>
  )
}

export default Team
