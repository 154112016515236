/** @jsxImportSource @emotion/react */

import Seo from '../../components/Seo'
import { Redirect } from 'react-router'
import { useAuthContext } from '../../context/auth.context'
import { roleNameToValue } from '../../utils'
import { MANAGER } from '../../constants'

import SettingsCompanies from './SettingsCompanies'
import SettingsProjects from './SettingsProjects'
import SettingsCategories from './SettingsCategories'
import SettingsEmployees from './SettingsEmployees'

import PageWrapper from '../../components/PageWrapper'
import PageTitle from '../../components/PageTitle'

const Settings = () => {
  const { userRole } = useAuthContext()
  const notAuth = userRole < roleNameToValue(MANAGER)

  if (notAuth) return <Redirect to='/' />

  return (
    <>
      <Seo title='⚙️ Settings' />
      <PageTitle>
        <h1>Settings</h1>
      </PageTitle>
      <PageWrapper>
        <section>
          {/* <h2>Projects</h2> */}
          <SettingsProjects />
        </section>
        <section>
          {/* <h2>Companies</h2> */}
          <SettingsCompanies />
        </section>
        <section>
          {/* <h2>Categories</h2> */}
          <SettingsCategories />
        </section>
        <section>
          {/* <h2>Employees</h2> */}
          <SettingsEmployees />
        </section>
      </PageWrapper>
    </>
  )
}

export default Settings
