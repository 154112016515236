/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import Seo from '../../components/Seo'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import { useQuery } from '@apollo/client'
import { GET_SHIFTS } from '../../graphql/shifts.graphql'
import { Paper, Grid, IconButton, TextField } from '@material-ui/core'
import { ViewState } from '@devexpress/dx-react-scheduler'
import {
  // AppointmentTooltip,
  Scheduler,
  // WeekView,
  Appointments,
  Toolbar,
  // ViewSwitcher,
  MonthView,
  DateNavigator,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui'
import SettingsShifts from './SettingsShifts'
import ClearIcon from '@material-ui/icons/Clear'
import { SHIFTMANAGER, MANAGER } from '../../constants'
import { roleNameToValue } from '../../utils'
import { colors } from '../../style'
import { useAuthContext } from '../../context/auth.context'
// import Room from '@material-ui/icons/Room'
import ShiftContainer from './ShiftContainer'

const Shifts = () => {
  const { userId, userRole } = useAuthContext()
  const hasUserAccess =
    userId == SHIFTMANAGER || userRole >= roleNameToValue(MANAGER)

  const nowYear = new Date().getFullYear()
  const nowMonth = new Date().getMonth()

  const initialValues = {
    dateFrom: new Date(nowYear, nowMonth, 1, 2, 0).toISOString().slice(0, 10),
    dateTo: new Date(nowYear, nowMonth + 1, 0, 24, 59)
      .toISOString()
      .slice(0, 10),
    users: null,
  }

  const defaultCurrentDate = new Date().toISOString().slice(0, 10)

  const [inputValues, setInputValues] = useState(initialValues)
  const [currentDate, setCurrentDate] = useState(defaultCurrentDate)

  const {
    data: dataShifts,
    loading: loadingShifts,
    error: errorShifts,
  } = useQuery(GET_SHIFTS, { variables: { ...inputValues } })

  const shifts = dataShifts?.getShifts.map(
    ({ _id, user, date, isDone, info }) => {
      const $24h = 24 * 60 * 60 * 1000
      const newDate = new Date(date)
      const startDate = newDate.toISOString().slice(0, 10)
      const nextDay = new Date(newDate.getTime() + $24h)
        .toISOString()
        .slice(0, 10)

      const isPast =
        new Date(defaultCurrentDate).getTime() - newDate.getTime() >= $24h

      return {
        _id,
        title: user.firstName + ' ' + user.lastName,
        user,
        date,
        info,
        startDate,
        endDate: nextDay,
        isDone,
        isPast,
        allDay: true,
        location: info,
      }
    }
  )

  return (
    <>
      <Seo title='🧹 Shifts' />
      <PageTitle>
        <h1>Shifts</h1>
      </PageTitle>
      <ShiftContainer />
      <div css={sPage}>
        <PageWrapper>
          <Paper>
            <Scheduler data={shifts} height={660} firstDayOfWeek={1}>
              <ViewState
                defaultCurrentDate={defaultCurrentDate}
                currentDate={currentDate}
                onCurrentDateChange={(currentDate) =>
                  setCurrentDate(currentDate)
                }
              />
              <MonthView />
              {/* <WeekView
                startDayHour={0}
                endDayHour={1}
                name='work-week'
                displayName='Work Week'
                excludedDays={[0, 6]}
              /> */}
              <Toolbar />
              {/* <ViewSwitcher /> */}
              <DateNavigator />
              <TodayButton />
              <Appointments appointmentComponent={Appointment} />
              {/* <AppointmentTooltip
              // headerComponent={AppointmentHeader}
              // contentComponent={AppointmentContent}
              /> */}
            </Scheduler>
          </Paper>
        </PageWrapper>
        <PageWrapper>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <div css={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Date From'
                  type='date'
                  value={inputValues.dateFrom}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, dateFrom: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  css={sInput}
                />
                <IconButton
                  size='small'
                  aria-label='delete'
                  css={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '4rem',
                  }}
                  onClick={() =>
                    setInputValues({ ...inputValues, dateFrom: '' })
                  }>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div css={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Date To'
                  type='date'
                  value={inputValues.dateTo}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, dateTo: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  css={sInput}
                />
                <IconButton
                  size='small'
                  aria-label='delete'
                  css={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '4rem',
                  }}
                  onClick={() =>
                    setInputValues({ ...inputValues, dateTo: '' })
                  }>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </PageWrapper>
        <PageWrapper>
          {dataShifts && hasUserAccess && (
            <SettingsShifts dataShifts={shifts} inputValues={inputValues} />
          )}
        </PageWrapper>
      </div>
    </>
  )
}

// const AppointmentHeader = ({
//   children,
//   appointmentData,
//   classes,
//   ...restProps
// }) => (
//   <AppointmentTooltip.Header {...restProps} appointmentData={appointmentData}>
//     <div>XDDD</div> {console.log(classes)}
//   </AppointmentTooltip.Header>
// )

// const AppointmentContent = ({
//   children,
//   appointmentData,
//   classes,
//   ...restProps
// }) => (
//   <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
//     <Grid container alignItems='center'>
//       <Grid item xs={2}>
//         <Room />
//       </Grid>
//       <Grid item xs={10}>
//         <span>{appointmentData.location}</span>
//       </Grid>
//     </Grid>
//   </AppointmentTooltip.Content>
// )

const Appointment = ({ children, data, style, ...restProps }) => {
  const { isDone, isPast, info, user } = data

  return (
    <Appointments.Appointment
      {...restProps}
      css={[
        {
          ...style,
          // boxShadow: `inset 0px 0px 0px 2px ${user.color}`,
          // borderBottom: `4px solid ${user.color}`,
          // position: 'relative !important',
          // transform: 'translateY(-6px)',
          // height: 'auto !important',
          lineHeight: '2.3em',
          // '& > div': {
          //   padding: '1rem',
          // },
          '&:hover': {
            zIndex: '99999',
            transform: 'scale(1) translate3D(0, 0, 100px)',
            height: '100%',
            minHeight: 64,
          },
        },
        isDone
          ? {
              backgroundColor: colors.green800,
              '&:hover': { backgroundColor: colors.green },
            }
          : isPast
          ? {
              backgroundColor: colors.red600,
              '&:hover': { backgroundColor: colors.red },
            }
          : {
              backgroundColor: colors.gray600,
              '&:hover': { backgroundColor: colors.text },
            },
      ]}>
      {children}
    </Appointments.Appointment>
  )
}

const sPage = {
  padding: '0 0 4rem',
}

const sInput = {
  input: { fontSize: '0.875rem' },
  label: { fontSize: '0.75rem' },
}

export default Shifts
