/** @jsxImportSource @emotion/react */
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import Seo from '../../components/Seo'

const Raports = () => {
  return (
    <div>
      <Seo title='🆚 Raports' />
      <PageTitle>
        <h1>Raports</h1>
      </PageTitle>
      <PageWrapper></PageWrapper>
    </div>
  )
}

export default Raports
