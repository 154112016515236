/** @jsxImportSource @emotion/react */

import { useContext, useState, useEffect } from 'react'
import Seo from '../../components/Seo'
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'
import { sBackground, sTitle, sForm, sPage, sInput } from '../Login/Login'
import citoLogo from '../../assets/cito_logo.svg'
import { Link } from 'react-router-dom'
import useUserMutations from '../../hooks/useUser.mutations'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { AuthContext } from '../../context/auth.context'

const initValues = {
  password: '',
  password2: '',
}

const PasswordNew = () => {
  const [inputValues, setInputValues] = useState(initValues)
  const [isLoading, setIsLoading] = useState(false)
  const { handlePasswordNew } = useUserMutations()
  const history = useHistory()
  const location = useLocation()
  let params = queryString.parse(location.search)
  const { token, logout } = useContext(AuthContext)

  useEffect(() => {
    if (!!token) logout()
  }, [token])

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (!inputValues.password || !inputValues.password2) {
      toast.error('PASSWORD REQUIRED')
      return setIsLoading(false)
    }

    if (inputValues.password !== inputValues.password2) {
      toast.error('PASSWORDS NOT MATCH')
      return setIsLoading(false)
    }

    if (inputValues.password.length < 6) {
      toast.error('PASSWORD TOO SHORT')
      return setIsLoading(false)
    }

    handlePasswordNew(
      { password: inputValues.password, token: params.token, id: params.id },
      (data) => {
        setIsLoading(false)
        if (data?.success) {
          toast.success(
            <div>
              <strong>Hasło Zresetowane</strong>
              <br />
              <span>
                Utworzono nowe hasło do konta CITO{' '}
                <strong>{params.email}</strong>. Zaloguj się
              </span>
              👈
            </div>,
            { autoClose: 10000 }
          )
          history.push('/login')
        }
      }
    )
  }

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  return (
    <>
      <Seo title='🔑 New Password' />
      <div css={sPage}>
        <div css={sBackground} />

        <form onSubmit={handleSubmit} css={sForm}>
          <div css={sTitle}>
            <img alt='CITO' src={citoLogo} />
          </div>
          <Typography
            variant='h5'
            css={{ textAlign: 'left', width: '100%', marginBottom: '1rem' }}>
            Nowe Hasło
          </Typography>
          <p css={{ fontSize: '0.75rem', width: '100%', textAlign: 'left' }}>
            Wpisz nowe hasło do konta CITO <strong>{params.email}</strong>
          </p>
          <Grid container css={{ width: '100%' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                name='password'
                type='password'
                label='Password'
                autoComplete='password'
                value={inputValues.password}
                onChange={onChange}
                css={sInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                name='password2'
                type='password'
                label='Confirmation'
                autoComplete='password'
                value={inputValues.password2}
                onChange={onChange}
                css={sInput}
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            css={{ marginTop: '2rem', width: '100%' }}
            disabled={isLoading}>
            RESET
            {isLoading && (
              <CircularProgress
                size={24}
                css={{
                  position: 'absolute',
                  top: 6,
                }}
              />
            )}
          </Button>
          <div
            css={{
              display: 'flex',
              width: '100%',
              marginTop: '2rem',
              fontSize: '0.75rem',
              opacity: 0.5,
              '&:hover': {
                opacity: 1,
              },
            }}>
            <Link to='/login'>Pamiętasz hasło? Zaloguj się</Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default PasswordNew
