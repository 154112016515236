/** @jsxImportSource @emotion/react */

import { s } from '../style'

const PageWrapper = ({ children, extraCss }) => {
  return <div css={[sPageWrapper, extraCss]}>{children}</div>
}

const sPageWrapper = {
  padding: '0 2rem 2rem',
  [s.sm_down]: {
    padding: '0 1rem 2rem',
  },
  section: {
    padding: '0 0 4rem',
  },
}

export default PageWrapper
