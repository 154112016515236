import { createContext, useContext } from 'react'

function noop() {}

export const ShiftsContext = createContext({
  hasShift: null,
  onCompleteShift: noop,
})

export const useShiftsContext = () => useContext(ShiftsContext)
