/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Seo from '../../components/Seo'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import PackageJson from '../../../package.json'
import { s } from '../../style'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { sText, sLink, sLinkIcon } from '../../components/Menu'
import { Collapse } from '@material-ui/core'
import packageJson from '../../../package.json'

export const updates = [
  {
    title: '20 February',
    subtitle: '⚙️ - Admin setting functions',
    version: '1.3.6',
    date: '20.02.2024 21:00',
    update: [
      {
        title: 'Ustawienia',
        desc: 'Dla roli użytkownika administrator oraz chief zostały dodane opcje usuwania: użytkowników, firm, projektów. Należy pamiętać że zmiany nie są odwracalne jeżeli usuniemy jakiś element nie będzie można go przywrócić tak więc proszę o rozważne usuwanie 😏.',
      },
      {
        title: 'Logo',
        desc: 'Zostało zmienione logo systemu oraz dodany pod nim tytuł.',
      },
    ],
  },
  {
    title: '28 June',
    subtitle: '🤳🏻 - QR Tracking',
    version: '1.3.5b',
    date: '28.06.2022 18:00',
    add: [
      {
        title: 'QR Tracking',
        desc: 'Nowa fukcjonalność! System dostosowano do aktywowania trackowania aktywności poprzez specjalne linki, które mogą być zapisane w formie kodu QR. Wyróżniamy 2 rodzaje linków: /track - link, który przekierowuje użytkownika do nowego mobilnego widoku trackowania i automatycznie rozpoczyna aktywność w oparciu o informacje w url oraz zatrzymuje wcześniejsze aktywności; /tracking - link przekierowuje do standarowego widoku /tracking, gdzie formularz zostaje automatycznie wypełniony, ale aktywność będzie rozpoczęta po wciśnięciu przycisku START, umożliwiając użytkownikowi edycję parametrów. Do czego mogą przydać się kody QR?',
        points: [
          {
            title: 'CITO OFF',
            desc: 'Od teraz jesteś w stanie trackować swoją pracę bez ręcznego uruchamiania aplikacji CITO. Wstarczy, że zamienisz swój plan na postać kodów QR i je wydrukujesz. Za pomocą telefonu będziesz rozpoczynać aktywności poprzez skanowanie kodów, a twoje urządzenie uruchomi wszystko za Ciebie.',
          },
          {
            title: 'Quick Switch',
            desc: 'Jak często zdarza Ci się oderwać od tego, co właśnie trackujesz? Nie masz czasu lub możliwości zmiany aktywności w aplikacji? Przygotuj kody do aktywności, które wybijają Cie z rytmu, lub poproś o pokazanie go osobę, która Cie wyciąga - wystarczy uruchomić skaner QR',
          },
          {
            title: 'Meeting',
            desc: 'Wchodzisz na spotkanie i nie wiesz co trackować? Zeskanuj wcześniej przygotowany kod i zobacz w telefonie z czym masz doczynienia.',
          },
          {
            title: 'Skanuj',
            desc: 'Skaner QR CITO działa na wszystkich urządzeniach z kamerką i w głownej mierze różni się od innych skanerów tym, że otwiera link natychmiastowo, zamiast pytać użytkownika czy otworzyć stronę. Możesz swobodnie skanować kody z laptopa na telefonie i odwrotnie. Pamiętaj o tym, że niektóre systemy i przeglądarki wymagają dodatkowych zezwoleń (na MacOS musisz wejść w Preferencje/Ochrona i Prywatność/Kamera i zaznaczyć swoją przeglądarkę oraz zezwolić aplikacji CITO na korzystanie z aparatu (w przeglądarce)).',
          },
        ],
      },
      {
        title: 'QR Track View',
        desc: 'Nowy widok /track jest dostępny podczas trackowania aktywności uruchomionych poprzez kod QR w wersji AUTO oraz aktywności już za kończonych i można go aktywować kilkając ikonkę nowego okna w widoku /tracking. Zawiera podstawowe informacje o aktywności, licznik czasu oraz kilka funkcjonalności:',
        points: [
          'STOP - zatrzymanie aktywności przyciskiem (analogicznie jak w widoku podstawowym)',
          'RESUME - ponowne uruchomienie zatrzymanej aktywności (tworzony jest nowy rekord)',
          'QR - wyświetlenie kodu QR bieżącej aktywności',
          'SCAN - uruchomienie skanera kodu QR w celu rozpoczęcia kolejnej aktywności',
        ],
      },
      {
        title: 'QR Codes',
        desc: 'Nowy widok /qrcodes, który umożliwia generowanie kodów QR, uruchomienie skanera kodów QR oraz stworzenie pliku PDF z wieloma kodami QR. Opis działania',
        points: [
          'Formularz tworzenia kodu jest analogiczny jak w widoku /trakcing',
          'Polę ClickUp, po wcześniejszym połączeniu, pozwala pobrać dane z ClickUp oraz zapisać ID Taska w kodzie QR.',
          'Firma jest polem wymaganym',
          'Projekt jest polem wymaganym tylko, kiedy ustawienia wybranej firmy to wymuszają',
          'Kategoria jest polem wymaganym',
          'Tytuł jest polem opcjonalnym. Jeżeli pozostanie puste, kod QR wygeneruje tytuł na podstawie kategorii lub projektu aktywności oraz dopisze frazę "[AUTO]".',
          'Polę Check umożliwia dostosowanie kodu QR pod automatyczne rozpoczynanie aktywności (zaznaczony - widok /track) lub pozostawienie rozpoczęcia trackowania użytkownikowi, uruchamiania ręczne (odznaczony - widok /tracking)',
          'Wygenerowany kod można zapisać w formie zrzutu ekranu, pobrać jako PDF, skopiować link aktywacyjny do schowka (i na przykład dodać do ulubionych zakładek w przeglądarce).',
          'Wygenerowany kod QR można dodać do planszy QR, znajdującą się pod formularzem, dzięki której można zbudować i wydrukować tablicę kodów.',
          'Plansza kodów QR umożliwia dodawanie i usuwanie kodów, a także wyświetlenie widoku QR View dodanego kodu.',
          'Kody na planszy są zapisane w pamięciu urządzenia. Dzięki temu widok /qrcodes może służyć jako widok ulubionych lub szybki aktywności',
        ],
      },
      {
        title: 'QR View',
        desc: 'W widoku /track oraz /qrcodes, wygenerowany kod można wyświetlić w osobnym widoku. Umożliwia on udostępnienie kodu innym osobom. Zawiera informacje o firmie (nazwa, logo), kategorii i projekcie aktywności, tytuł oraz oznaczenie, czy kod aktywuje tracking automatycznie czy nie (napis AUTO przy tytule), a także ikonkę ClickUp, jeżeli aktywność została powiązania z zadaniem ClickUp',
      },
      {
        title: 'System QR a inne funkcjonalności',
        desc: 'W przypadku wystąpienia błędu podczas próby uruchomienia aktywności z kodu QR np. NENA lub OVERTIME, aplikacja wyświetli informacje o błędzie i przerzuci użytkownika do widoku /tracking, wypełniając pola tymi samymi danymi. Wszystkie funkcjonalności wcześniej zaimplementowane współgrają z systemem kodów QR tj. kontrola czasu, weryfikacja użytkownika, cenzorowanie słów, zliczanie czasu, nadpisywanie trackingu w ClickUp itd.',
      },
      {
        title: 'QR STOP Tracking',
        desc: 'Dodano nową ścieżkę w aplikacji /stop, po uruchomieniu której wszystkie zadania z bieżącego dnia użytkownika zostaną zatrzymane i wyświetlą się statystyki. Link do ścieżki można zapisać w postaci QR i powiesić np. na wyjściowych drzwiach biura. Link: {URL_CITO}/stop',
      },
    ],
    update: [
      {
        title: 'Searching Button',
        desc: 'W widokach /activities, /team (MANAGER), /estimates zmodyfikowano wyszukiwarkę tak, aby z serwera nie były zaciągane dane w momencie zmiany ustawień przez użytkownika, tylko po wciśnięciu przycisku "SEARCH". UWAGA! Zacięcie się ikony ładowania na przycisku oznacza, że dane zostały już pobrane do aplikacji klienta, ale przeglądarka jest zajęta drukowaniem interfejsu i wykresów, co może potrwać kilka sekund w przypadku dużej ilości danych.',
      },
      {
        title: 'ClickUp Native App Linking',
        desc: 'Od teraz wszystkie linki do tasków w ClickUp prowadzą do aplikacji natywnej ClickUp zamiast strony internetowej (należy pobrać aplikację ClickUp na urządzenie (Native lub PWA)). Wyjątkiem jest autoryzacja ClickUp przez CITO - otwiera się w przeglądarce. Jeśli korzystasz z Safari na iOS, a autoryzacja otwiera się w aplikacji, skopiuj link pod przyciskiem "CONNECT CLICKUP" i otwórz w przeglądarce. Linkowanie do aplikacji natywnych tycz się widoków:',
        points: [
          '/tracking',
          '/activities',
          '/team',
          '/estimates',
          '/dashboard',
          '/projects',
          '/tasks',
        ],
      },
    ],
    info: [
      {
        title: 'Authors /activities',
        desc: 'Naprawiono błąd filtracji aktywności po użytkownikach, w którym lista autorów do wyboru była pusta, w przypadku użytkowników z rolą EXPERT',
      },
      {
        title: 'Form Zoom Safari',
        desc: 'Zablokowano możliwość zoomowania w aplikacji w celu pozbycia się błędu graficznego na Safari iOS, który pojawiał się po kliknięciu w polę formularza. (Dodanie max_scale=1)',
      },
      {
        title: 'Zmiana kategorii w generowanych raportach',
        desc: 'Od teraz w raportach PDF i XLSX w kolumnie kategorii zamiast nazwy wyświetlany jest typ kategorii',
      },
    ],
    errors: [
      {
        title: 'Server Crash',
        desc: 'W tej aktualizacji dodano modyfikacje (Searching Button), które powinny wyeliminować crashowanie serwera (w końcu). Problem crashowania serwera wynikał z tego, że niektóre niecierpliwe osoby 🤬  zmieniały parametry wyszukiwania, zanim wcześniejsze rządania nie zostały zrealizowane, co w przypadku pobierania ogromnej ilości informacji (np. całej bazy danych) powodowały zbombardowanie pamięci serwera danymi, doprowadzając do jej przepełnienia i unieruchomienia całego serwera (na ten moment serwer posiada RAM wynoszący zaledwie 470 MB, co widocznie nie było wystarczające do wyżej opisanych zapytań). Wprowadzenie przycisku potwierdzającego wysyłanie rządania uniemożliwi wykonanie kilku zapytań w krótkim czasie.',
      },
    ],
  },
  {
    title: '17 May',
    subtitle: '🟪 - Tasks View',
    version: '1.3.4',
    date: '17.05.2022 13:30',
    add: [
      {
        title: 'Tasks View',
        desc: 'Dodano nowy widok Tasks (Zadania) - /tasks. Przedstawia on listę zdań przydzielony użytkownikowi na podstawie ID tasków z ClickUp, które zostały dodane do estymacji, przypisanej do tego użytkownika. Funkcjonalności:',
        points: [
          {
            title: 'Użytkownik',
            desc: 'Sekcja użytkownika jest podsumowaniem wszystkich aktywności związanych z przydzielonymi mu estymacjiami zawierającymi odniesienie do ClickUp',
          },
          {
            title: 'Dane z ClickUp',
            desc: 'Lista zadań zawiera aktualne informacje z ClickUp takie, jak: status zadania, priorytet, jego nazwę, opis oraz daty rozpoczęcia i zakończenia.',
          },
          {
            title: 'Estymacja CITO',
            desc: `Kolorowa linia jest podsumowaniem estymacji z CITO. Wskazuje do jakiej firmy, projektu oraz kategorii została stworzona estymacja związana z tym zadaniem. 
            `,
          },
          {
            title: 'Aktywności CITO',
            desc: `Podsumowanie aktywności informuje o zakończonych i trwających aktywnościach w ramach danej estymacji. Aktywności trwające wliczają się do podsumowania, 
          ale ich czas jest wyliczony w momencie otwarcia widoku zamiast, tak, jak w innych widokach, odświeżane co minutę (ograniczenie ClickUp API związane z 
            ilością pobrań danych co minutę). Stylowanie OVERTIME oraz OVERSOON analogiczne, jak w widokach /estimates i /projects.`,
          },
          {
            title: 'Podział na osoby',
            desc: 'Szczegóły aktywności można rozwinąć poprze kliknięcie linii ilustrującą estymację. Pojawi się wówczas lista użytkowników, którzy w ramach tej estymacji (czytaj w ramach tego zadania) prowadzili trackowanie. Element wskazuje procentowy udział użytkownika w odniesieniu do całej estymacji. Kliknięcie w danego użytkownika rozwinie listę jego aktywności trackowanych w ramach tej estymacji.',
          },
          {
            title: 'Szybki Tracking',
            desc: 'Najechanie kursorem na zadanie wyświetli przycisk TRACK w miejscu statusu zadania. Wciśnięcie przycisku przekieruje na podstronę /tracking oraz automatycznie wypełni wszystkie pola formularza nowej aktywności.',
          },
          {
            title: 'Przypominajka',
            desc: 'W menu nawigacji, przy zakładce Tasks, pojawia się wskaźnik notyfikacji. Przejście do widoku /tasks ukrywa wskaźnik i wyświetli się ponownie następnego dnia.',
          },
          {
            title: 'Linkowanie do APLIKACJI ClickUp',
            desc: 'Dodano testowa wersję uruchamiania podglądu zadania w natwynej aplikacji ClickUp, zamiast w werji przeglądarkowej. Kliknięcie w nazwę zadania powinno otworzyć aplikacji ClickUp z wybranym zadaniem.',
          },
        ],
      },
      {
        title: 'Team Assigned Tasks',
        desc: 'Użytkownicy z rolę większą niż MANAGER widzą dodatkową sekcję z zadaniami wszystkich użytkowników. Elementy są analogiczne jak w widoku standardowym z jednym wyjątkiem. Przy linii estymacji wyświetla się przycisk umożliwiający otworzenie widoku edycji estymacji związanej z wybranym zadaniem.',
      },
      {
        title: 'Tworzenie Tasks',
        desc: 'Aby zadanie wyświetliło się w widoku Tasks, należy utworzyć Assigned Estimate (estymację przydzieloną do użytkownika/użytkowników, oraz estymacji musi zawierać conajmniej jeden ClickUp ID (identyfikator zadania z ClickUp). Dodanie więcej niż jednego, np. 2, ClickUp ID do estymacji spowoduje stworznie dwóch oddzielnych zadań w widoku Tasks, które będą współdzielić tą samą estymację czasową.',
      },
    ],
  },
  {
    title: '05 May',
    subtitle: '👷‍♂️ - Assigned Estimates',
    version: '1.3.3',
    date: '05.05.2022 00:00',
    add: [
      {
        title: 'Assigned Estimates',
        desc: 'Dodano nową funkcjonalność, która umożliwia przypisanie wielu osób do estymacji (Assigned Estimates). Poniżej lista kluczowych informacji:',
        points: [
          {
            title: 'Cel',
            desc: `Funkcjonalność została dodana w celu rozwiązania problemu z przepełnionymi estymacjami. Podstawowe działanie Assgined Estimate polega na tym, że jeżeli użytkownik spróbuje uruchomić tracking na aktywność, która przejdzie przez algorytm NENA, OVERTIME, napotka kolejny algorytm - NACT (Not Assigned Can't Track), który sprawdzi, czy powiązana estymacja ma przypisaną listę użytkowników. Jeśli tak, to sprawdzi, czy osoba zlecająca może rozpocząć tą aktywność (znajduje się na liście). Jeżeli osoba nie została przypisana do takiej estymacji, serwer zwróci komunikat "NACT - ESTYMACJA PRZYDZIELONA DO INNEGO UŻYTKOWNIKA".`,
          },
          {
            title: 'Estymacje nieprzypisane',
            desc: 'Estymacje, które nie posiadają listy przypisanych użytkowników działają na tych samych zasadach, co dotychczas. Upraszczając, nieprzypisane estymacje są otwarte dla wszystkich użytkowników.',
          },
          {
            title: 'Wymagania',
            desc: 'Warunek konieczny jest taki, że estymacja z przypisanymi osobami nie może być estymacją zbiorczą, czyli wymaga określenia firmy, projektu oraz kategorii. W ten sposób unikamy konfliktów związanych z tym, że estymacja zbiorcza została przypisana do konkretnych osób, a estymacje szczegółowe w domyśle miały być otwarte dla każdego. Próba stworzenia estymacji zbiorczej, przypisanej zakończy się niepowodzeniem. Dodatkowo po stronie aplikacji klienta dodano wskazówki w widoku dodawania estymacji.',
          },
          // {
          //   title: 'Problem estymacji nadrzędnych',
          //   desc: 'Jeżeli zostanie utworzona estymacja nadrzędna dla projektu, to użytkownicy, którzy rozpoczną aktywność w tym projekcie o kategorii innej, niż kategoria estymacji przypisanej, będą wciąż mogli trackować w ramach tego projektu. Przykałd: abstrahując od firmy, w projkcie jest utworzona estymacja A - bez kategorii oraz estymacja B - z określoną kategorią K i przypisaną osobą O, jeżeli użytkownik rozpocznie trackowanie aktywności o kategorii innej niż K, a projekt nie jest OVERTIME, to będzie mógł de facto unimożliwić osobie O trackowanie na rzecz przypisanej do siebie estmacji o kategorii K, ponieważ sumaryczy czas całego projektu wciąż zezwala na trackowanie. Rozwiązanie problemu poniżej:',
          // },
          {
            title: 'Zasady',
            desc: 'Jeżeli w estymacji projektu ma się znaleźć podrzędna estymacja przypisana do konkretnej osoby, to nie powinno się tworzyć estymacji bez kategorii w tym projekcie, aby uniemożliwić użytkownikom wypełnienie estymacji aktywnościami innymi, niż tymi związanymi z estymacją przypisaną. W takim projekcie należy utworzyć estymacje z wszystkimi potrzebnymi kategoriami, niezależnie czy będą one przypisane do osób czy nie. Dzięki temu, osoby z przypisaną estymacją nie napotkają problemu OVERTIME w wypadku, w którym przypisana dla nich estymacja nie została w pełni wykorzystana.',
          },
          {
            title: 'Edytowanie',
            desc: 'Aktywności związane z estymacjami przypisanymi mogą być edytowane tylko przez osoby do nich przypisane. Taka reguła uniemożliwia obejście algorytmu NACT poprzez manipulację autora zadania. Również użytkownicy z rolą CHIEF, są objęci tym algorytmem - w innym wypadku jego implementacja niemiałaby sensu. W przypadku potrzeby edycji aktywności powiązanej z estymacją przypisaną należy użyć konta z rolą ADMIN.',
          },
          {
            title: 'Wyświetlanie',
            desc: 'W widokach /estimates, /dashboard, /projects, estymacje przypisane można odróżnić tym, że nazwa kategorii danego projektu zawiera listę przypisanych osób np. W projekcie X, jedna z kategorii będzie miała nazwię np. "IT [Adam]". Oznacza to, że estymacja dla tego projektu, w kategorii "IT" została przypisana tylko użytkownikowi o imieniu Adam. W widoku ustawień estymacji lista przypisanych użytkowników wyświetla się w formie tzw. chipów.',
          },
        ],
      },

      {
        title: 'Estimate Delete',
        desc: 'Dodano możliwość trwałego usuwania estymacji pod przyciskiem DELETE w widoku edycji estymacji. Przycisk jest nieaktywny dopóki pole z tytułem estymacji nie zostanie wyczyszczone (forma weryfikacji). W razie przypadkowego usunięcia należy skontaktować się z administratorem - serwer przed usunięciem wyświetla dane tej estymacji w konsoli, więcj można ja ręcznie przywrócić. Z założenia estymacja powinna zostać usunięta, gdy została stworzona przez pomyłkę. Estymacje z poprzednich miesięcy powinny pozostać w systemie, aby przechowywać dane historyczne.',
      },
      {
        title: 'Projects ClickUp',
        desc: 'W widoku /projects, przy poszczególnym projekcie, po rozwinięciu, pojawiać się będą ID tasków ClickUp, które zostały przypidane przez menedżerów do estymacji związanych z tym projektem. Najechanie kursorem na ID zadania wykona zapytanie do API ClickUp w celu pobrania nazwy taska, a jego wciśnięcie otworzy aplikację ClickUp z tym zadaniem.',
      },
    ],
    update: [
      {
        title: 'Estimate Warnings',
        desc: 'Dodano następujące ostrzeżenia podczas dodawania nowej estymacji',
        points: [
          {
            title: 'Projekt ukryty',
            desc: 'Ostrzeżenie o wyborze projektu, który jest ukryty w widoku /tracking',
          },
          {
            title: 'Firma ukryta',
            desc: 'Ostrzeżenie o wyborze firmy, która jest ukryta w widoku /tracking',
          },
          {
            title: 'Przypisane osoby',
            desc: 'Wyświetenie błędu po próbie dodania/aktualizacji estymacji przypisanej do osób, bez określenia projektu lub/i kategorii',
          },
        ],
      },
      {
        title: 'Estimate Modal',
        desc: 'Naprawiono błędy związane z odświeżaniem listy estymacji w widoku podglądowym estymacji zarówno w wersji listujacej estymacje firmy, jak i estymacje danego projektu',
      },
    ],
    info: [
      {
        title: 'Duplicate Estimate',
        desc: 'Ponieważ nowa fukcjonalność Assigned Estimates wymaga, aby estymacje szczegółowe (tj. firma + projekt + kategoria) miałby jedną instancję w bazie danych, wyłączono możliwość duplikowania estymacji szczegółowych. Przycisk DUPLICATE wówczas jest niewidoczny, a próba stworzenia nowej estymacji o powiązanych parametrach poskutkuje wyświetleniem błędu "PODOBNA ESTYMACJA JUŻ ISTNIEJE"',
      },
    ],
  },
  {
    title: '21 April',
    subtitle: '📱 - UI Fixes for Mobile',
    version: '1.3.2',
    date: '21.04.2022 13:40',
    update: [
      {
        title: 'Menu',
        desc: 'Poprawiono działanie i wygląd Menu dla urządzeń mobilnych. Przycisk wylogowania jest teraz widoczny na przeglądarce Safari',
      },
      {
        title: 'Edycja Aktywności',
        desc: 'Poprawiono widok edytowania oraz dodano przycisk zamknięcia okna',
      },
      {
        title: 'Edycja Estymacji',
        desc: 'Poprawiono widok edytowania oraz dodano przycisk zamknięcia okna',
      },
      {
        title: 'Tracking',
        desc: 'Zmieniono kolory wyróżnionych aktywności, aby poprawnie wyświetlały się na urządzeniach Apple',
      },
      {
        title: 'ClickUp UI',
        desc: 'Poprawiono wygląd przycisków integracji z ClickUp na urządzeniach mobilnych',
      },
      {
        title: 'ClickUp Links',
        desc: 'Zmieniono sposób, w jaki CITO linkuje aktywności z zadaniami ClickUp. Przeglądarka powinna uruchomić aplikację ClickUp, jeżeli jest zainstalowana na urządzeniu.',
      },
      {
        title: 'ClickUp Connect na Mobile',
        desc: 'W przypadku integracji konta CITO z kontem ClickUp na urządzeniu mobilnym, na którym jest zainstalowana natywna aplikacja ClickUp, należy skopiować link z przycisku "Connect ClickUp" w widoku /tracking i wkleić go do przeglądarki. Natywna aplikacja mobilna ClickUp nie posiada widoku autoryzacji i należy ją przeprowadzić przez wersję przeglądarkową.',
      },
    ],
  },
  {
    title: '05 April',
    subtitle: '⏱ - ClickUp Time Tracking',
    version: '1.3.1',
    date: '05.04.2022 14:00',
    add: [
      {
        title: 'Zapis trackingu w ClickUp',
        desc: 'Dodano funkcjonalność, która dodaje tracking do zadania w ClickUp po zakończeniu trackowania zadania w CITO (wymaga dodania ID taska)',
      },
      {
        title: 'Taski w Estymacjach',
        desc: 'Umożliwiono zapis wielu tasków z ClickUp przy estymacjach w CITO. Dodatkowo, po najechaniu kursorem na ID zadania, pobrana zostanie jego nazwa z ClickUp. Wciśnięcie ID zadania, otworzy w nowej karcie przeglądarki aplikację ClickUp z tym taskiem',
      },
    ],
    update: [
      {
        title: '/tracking',
        desc: 'Zmieniono pozycję pola ClickUp w formularzu dodawania nowej aktywności (pozycja pierwsza). Zakłada się, że użytkownik podlinkuje ID zadania z ClickUp jako pierwsze, a pozostałe pola wypełnią się automatycznie',
      },
    ],
    info: [
      'Naprawiono błąd w widoku /team, który uniemożliwiał wyświetlenie okna edycji aktywności',
    ],
  },
  {
    title: '30 March',
    subtitle: '💜 - ClickUp Task Tracking',
    version: '1.3.0',
    date: '30.03.2022 14:00',
    add: [
      {
        title: 'Integracja z ClickUp',
        desc: 'System CITO został połączony z narzędziem ClickUp. Za pomocą autoryzacji konta ClickUp, możliwe jest pobieranie danych (w przyszłości również modyfikowanie) za pomocą klienta CITO.',
        points: [
          {
            title: 'Autoryzacja',
            desc: 'W celu nawiązania połączenia z ClickUp należy w widoku /tracking lub /clickup wcisnąć przycisk Connect. Wyświetli się okno aplikacji ClickUp, gdzie należy wybrać workspace. Po weryfikacji użytkownik zostanie przekierowany spowrotem do aplikacji CITO i na urządzeniu lokalnym zostanie zapisany token, który posłuży do wysyłania zapytań. W razie wygaśnięcia tokenu, należy wygenerować go ponownie w widoku /clickup (wpisz URL ręcznie) wciskając przycisk "Autoryzuj ponownie".',
          },
          {
            title: 'Tracking',
            desc: 'Widok dodawania nowych aktywności /tracking został rozwinięty o pole ClickUp, gdzie należy podać ID zadania np. #276bbvy (CITO przyjmuje zarówno wersję z #, jak i bez). ID taska można pobrać wciskając ikonę 3 kropek w ClickUp w widoku danego zadania, a następnie wybrać opcję "Copy ID".',
          },
          {
            title: 'Linkowanie do ClickUp',
            desc: 'Dzięki zapisywaniu informacji o tasku ClickUp, możliwe jest otwieranie odpowiedniego zadania z interfejsu CITO. W widokach /tracking, /activities, /estimates, /dashboard przy aktywnościach dodano ikonę ClickUp, po wyciśnięciu której użytkownikowi otworzy się aplikacja z wybranym taskiem.',
          },
          {
            title: 'Automatyczne wypełnienie projektu',
            desc: 'Podczas wpisywania ID taska z ClickUp w widoku /tracking, serwer CITO wykona połączenie do ClickUp w celu pozyskania informcji o zadaniu. Jeżeli wszystko przebiegnie pomyślnie, klient CITO wyświetli nazwę zadania oraz automatycznie wypełni pola aktywności (firmę, projekt oraz tytuł zadania) na podstawie danych z ClickUp, jeżeli zgodność systemów jest zachowana',
          },
          {
            title: 'Automatyczne wypełnienie firmy',
            desc: 'Od teraz w formularzu na /tracking, niezależnie czy podany projekt został ustawiony ręcznie, czy pobrany na podstawie ID taska z ClickUp, firma zostanie określona i wpisana automatycznie na podstawie relacji projekt - firma.',
          },
          {
            title: 'ClickUp Team real-time',
            desc: 'Od teraz w widoku /team, przy każdej osobie, która aktualnie prowadzi aktywność, pojawia się przycisk przejścia do zadania w ClickUp (o ile osoba ta dodała ID zadania w /tracking). Zmiana została wprowadzona zarówno w widoku Team w wersji dla pracowników, jak i w wersji Team dla managerów',
          },
        ],
      },
      {
        title: 'Zasady Poprawnej Integraci',
        desc: 'Opis zależności danych pomiędzy ClickUp oraz CITO w kontekście automatyzacji wypełnienia formularza nowej aktywności.',
        points: [
          'Nazwa komórki związanej z projektem ("custom_fields") w ClickUp zawiera frazę "cito" niezależnie od wielkości liter np. Projekt CITO ...',
          'Nazwa listy, w której znajduje się task w ClickUp zawiera nazwię firmy tożsamą z nazwą firmy w CITO. Skrypt próbkuję ciąg znaków do momentu napotkania symbolu " - " (spacja, myślink, spacja).',
        ],
      },
      {
        title: 'Przykład Integracji',
        desc: 'Na przykład w ClickUp nazwa listy, do której należy zadanie to "Omida Logistics - Strategiczne", a komórka custom_fields o nazwie "Projekt CITO" ma wartość "Aktualizacja strony www". Program przysła następujące informacje',
        points: [
          'Tytuł: {nazwa zadania}',
          'Firma: Omida Logistics',
          'Projekt: Aktualizacja strony www',
          'Następnie po stronie klienta skrypt próbuje odnaleźć wskazany projekt, a ponieważ wiele firm posiada projekt o takiej samej nazwie, dodatkowo weryfikuje, czy nazwa firmy związana z odnalezionym projektem pokrywa się z nazwem projektu przesłanego z ClickUp API. Powyższa weryfikacja normalizuje porównania znaków niezależnie od ich wielkości. Jeżeli projekt jest odnaleziony, formularz automatycznie wstawi dane.',
        ],
      },
    ],
    update: [
      {
        title: 'Estimates',
        desc: 'W widoku /estimates wyszukiwarka estymacji została przeniesiona na samą górę (spowrotem). Ustawienia estymacji zostały w dolnej części widoku',
      },
    ],
  },
  {
    title: '16 February',
    subtitle: '🆗 - Fix Update',
    version: '1.2.8',
    date: '16.02.2022 15:15',
    update: [
      {
        title: 'Projects',
        desc: 'W widoku projektów dodano kilka zmian. Od teraz wyświetlane są wszystkie projekty, których suma godzin aktywności i estymacji z bieżącego miesiąca są większe od 0. Estymacje i Aktywności bez określonego projektu są wciąż CAŁKOWICIE POMIJANE. Dodano następujące zmiany:',
        points: [
          'Wyświetlanie projektów z estymacjami z zerowymi aktywnościami,',
          'Wyświetlanie projektów z estymacjami kategorii z zerowymi aktywnościami,',
          'Wyświetlanie estymacji bez kategorii,',
          'Wyłączono filtrowanie ukrytych projektów i dodano efekt wyszarzenia dla takich projektów.',
        ],
      },
      {
        title: 'Estimate Edit',
        desc: 'Podczas edytowania estymacji, datą początkową i końcową zakresu jest pierwszy i ostatni dzień miesiąca, który był wcześniej ustawiony dla tej estymacji, zamiast dni miesiąca bieżącego. Dodając nową aktywność, miesiącem zakresu jest miesiąc bieżący (pozostało bez zmian).',
      },
      {
        title: 'Środowisko Produkcyjne',
        desc: 'Zmieniono program uruchomieniowy aplikacji na środowisku produkcyjnym (nodemon na node), w celu pozbycia się problemu crashy aplikacji. Od teraz, po wystąpieniu istniejącego błędu z alokacją pamięci, środowisko powinno nieprzestannie udostępniać aplikację.',
      },
    ],
    info: [
      {
        title: 'FIX: OVERTIME',
        desc: 'Istniał błąd, w którym możnabyło dodać aktywność, która powinna zostać odrzucona przez algorytm OVERTIME. Problem polegał na tym, że pobierane były aktywności począwszy od godziny 01:00, pierwszego dnia miesiąca, zamiast od godziny 00:00. Suma aktywności próbkowanych była wówczas mniejsza od tej rzeczywistej, co zaniżało sumaryczną ilość godzin. Skorygowano datę początkową. Błąd pojawił się po zmianie ustawień strefy czasowej w bazie danych.',
      },
      {
        title: 'FIX: Estimates',
        desc: 'Usunięto z bazy danych błędnie skonstruowane estymacje, które mogą negatywnie wpływać na algorytmy NENA i OVERTIME. Mowa o estymacjach, których np. data początkowa to 01.02, a data końcowa to 31.01.',
      },
    ],
  },
  {
    title: '20 January',
    subtitle: '💀 - N.E.N.A.',
    version: '1.2.7',
    date: '20.01.2022 15:00',
    add: [
      {
        title: 'NENA - No Estimate No Activity',
        desc: 'Wprowadzono walidację dodawanych aktywności pod kątem istnienia powiązanych estymacji. W przypadku braku estymacji, rozpoczęcie zadania nie jest możliwe. Wyróżnia się następujące komunikaty:',
        points: [
          {
            title: 'BRAK ESTYMACJI DLA FIRMY',
            desc: 'Dodawana jest aktywność, której firma nie posiada zdefiniowanej jakiejkolwiek estymacji',
          },
          {
            title: 'BRAK ESTYMACJI DLA KATEGORII',
            desc: 'Dodawana jest aktywność bez projektu, nie istnieje estymacja dla wybranej kategorie (wykluczając te po za jakimś projektem) oraz nie istnieje estymacja zbiorowa dla firmy',
          },
          {
            title: 'BRAK ESTYMACJI DLA PROJEKTU',
            desc: 'Dodawana jest aktywność z projektem i nie istnieje ani zbiorowa estymacja dla projektu, ani estymacja z określoną kategorią',
          },
          {
            title: 'BRAK ESTYMACJI DLA PROJEKTU W TEJ KATEGORII',
            desc: 'Dodawana jest aktywność z projektem, nie istnieje zbiorowa estymacja dla projektu lub nie istnieje estymacja ze sprecyzowaną kategorią ',
          },
        ],
      },
      {
        title: 'NENA - Estymacje Zbiorcze',
        desc: 'Estymacjami zbiorczymi nazywa się takie, które określają aktywności dla kategorii lub całej firmy. Poniżej kilka przykładów:',
        points: [
          'Estymacja bez kategorii oraz bez projektu umożliwia dodawanie aktywności niezależnie od projektu i kategorii',
          'Estymacja bez projektu (czyli estymacja kategorii) umożliwia dodawania aktywności niezależnie od projektu (również brak sprecyzowania projektu aktywności) w ramach tej kategorii',
          'Estymacja bez kategorii (czyli z projektem) umożliwia dodawania aktywności niezależnie od kategorii w ramach tego projektu',
          'Jedynie estymacje z projektem i kategorią nie zaliczają się do estymacji zbiorczych, ponieważ aktywność z projektem A i kategorią B będzie możliwa do dodania tylko, jeżeli sprecyzowano taką estymację',
        ],
      },
      {
        title: 'NENA - edytowanie i zatrzymywanie aktywności',
        desc: 'Od teraz weryfikacja NENA jest uruchamiana podczas aktualizowania i stopowania aktywności. Niemożliwym jest więc rozpoczęcie zadania zgodnego z NENA oraz OVERTIME, a następnie modyfikacji aktywności na taką, która podczas dodawania nie spełniała wyżej wymienionych reguł. Weryfikacja OVERTIME w tym wypadku jest wyłączona, ponieważ aktywności, które przekroczyły estymacje z samego faktu działania, byłyby niemożliwe do zatrzymania (zatrzymianie zadania jest tożsame z jego edycją). W tym wypadku możliwe jest przekroczenie estymacji, po zatrzymaniu aktywności, ale jej ponowne uruchomienie nie będzie możliwe.',
      },
      {
        title: 'Estimate Modal',
        desc: 'W widoku estymacji oraz Dashboard (CHIEF LEVEL) pozwala na szybką edycję estymacji. Po wciśnięciu ikony zegara przy "słupku analizy" (aktywności powiązane z estymacjami) wyświetli się okno z listą estymacji, które wpływają na wyświetlaną informację. Wciśnięcie ikony zegara przy podsumowaniu firmy spowoduje wyświetlenie okna z listą wszystkich estymacji z bieżącego miesiąca.',
      },
      {
        title: 'Estimate Settings',
        desc: 'W widoku dodawania i edycji estymacji dodano polę wyboru miesiąca, zamiast 2 pola: data rozpoczęcia, data zakończenia estymacji. Formularz tworzenia estymacji domyślnie ustawia zakres trwania na bieżący miesiąc, zamiast miesiąca następnego.',
      },
      {
        title: 'Cenzura',
        desc: 'Dodano funkcjonalność, która sprawdza treść tytułu dodawanej oraz edytowanej aktywności pod kątem wystąpienia zakazanych słów i ewentualnie blokuje dodanie tej aktywności. Uwzględnia wielkość liter, użycie spacji lub znaków specjalnych oraz wyrazy pochodne - lista poniżej. Powodzenia.',
        points: ['plan', 'task', 'sprin', 'spotkanie', 'pracy'],
      },
    ],
  },
  {
    title: '19 January',
    subtitle: '⛔ - Overtime',
    version: '1.2.6',
    date: '19.01.2022 11:00',
    add: [
      {
        title: 'Overtime',
        desc: 'Dodano funkcjonalność po stronie serwera, która uniemożlwia rozpoczęcie nowej aktywności gdy:',
        points: [
          'COMPANY OVERTIME - Analiza łączna wszystkich aktywności i estymacji danej firmy, w przeciągu bieżącego miesiąca, wykazała, że przekroczono założoną ilość godzin.',
          'CATEGORY OVERTIME - Użytkownik dodaje aktywność bez projektu i istnieje estymacja dla tej firmy związana z wybraną kategorią (bez projektu), której analiza wykazała, że przekroczono możliwą ilość godzin.',
          'PROJECT OVERTIME - Niezależnie od wybranej kategorii, użytkownik dodaje aktywność związaną z danym projektem i istnieje estymacja dla tej firmy związana z tym projektem, której suma aktywności oraz estymacji (z kategorią lub bez) została przekroczona',
          'PROJECT WITH CATEGORY OVERTIME - Użytkownik dodaje aktywność z określonym projektem i kategorią. Istnieje estymacja dla tego projektu z wyspecyfikowaną kategorią i pomimo, iż sumaryczne aktywności mieszczą się w estymacji, aktywności w ramach tej jednej kategorii zostały przekroczone',
        ],
      },
      {
        title: 'Projects Settings',
        desc: 'Dodano alfabetyczne sortowanie projektów po nazwie firmy',
      },
    ],
  },
  {
    title: '22 November',
    subtitle: '⏱ - Timed Scripts',
    version: '1.2.5',
    date: '22.11.2021 16:30',
    add: [
      {
        title: 'Automatyczne zakończenie zadań',
        desc: 'Dodano funkcjonalność serwerową planowanych skryptów "handleUnfinishedActivities".',
        points: [
          'Każdego dnia o godzinie 00:00:00 z bazy danych pobrana zostanie lista niezakończonych aktywności z dnia poprzedniego.',
          'Dla każdej takiej aktywności, pobrany zostanie jej autor i przesłana do niego zostanie wiadomość email z powiadomieniem.',
          'Niezakończone zadanie są automatycznie zamykane przez system poprzez ustalenie czasu zakończenia na 10 minut od czasu rozpoczęcia.',
          'W przypadku aktywności rozpoczętych po godzinie o 23:49, czas zakończenia będzie ustawiany na 23:59, więc nie każda automatycznie zamknięta aktywności będzie miała wartość czasu trwania równą 10 minut (np. dla aktywności rozpoczętej 23:54 będzie to czas trwania 5 miniut)',
          'Użytkownik, który otrzymał wiadomość o niezakończonym zadaniu powinien przesłać rządanie korekty do przełożonego (adres podany w wiadomości email), określając rzeczywisty czas zakończenia omawianej aktywności',
        ],
      },
      {
        title: 'Raporty automatycznie zakończonych zadań',
        desc: 'Dodano funkcjonalność serwerową planowanych skryptów "raportUnfinishedActivities".',
        points: [
          'Każdego 1 dnia miesiąca, o godzinie 00:00:00 z bazy danych pobrana zostanie lista automatycznie zakończonych aktywności z ostatniego miesiąca.',
          'Lista zostaje następnie sformatowana, tak aby zawrzeć informację kiedy i kto dokonał modyfikacji i przesłana do odpowiedniego menedżera, określonego w konfiguracji serwera',
          'Menedżer może dokonać następującej walidacji: jeżeli zadanie zostało automatycznie zakończone przez SYSTEM, i jego ostatnia modyfikacja została przeprowadzona przez SYSTEM, oznacza to, że aktywność nie została zgłoszona do korekty i należy przeprowadzić wywiad w celu pozbycia się niepoprawnych danych',
        ],
      },
    ],
    update: [
      {
        title: 'Predefiniowane Zadania',
        desc: 'Menedżerowanie dyżurów podczas dodawania nowego Shift mają możliwość wybrania wcześniej zdefiniowanych zadań',
      },
    ],
  },
  {
    title: '09 November',
    subtitle: '🧹 - Shifts',
    version: '1.2.4',
    date: '09.11.2021 22:30',
    add: [
      {
        title: 'Dyżury (Shifts)',
        desc: 'Dodano widok /shifts, w którym wyświetlany jest kalendarz z dyżurami. Funkcjonalności:',
        points: [
          'Użytkownik, który ma dyżur danego dnia zostanie poinformowany podczas wejścia na aplikację za pomocą toast',
          'W widoku /shifts oraz /tracking wyświetlany jest banner przypominający',
          'Dyżur może zostać zakończony poprzez przycisk "ZROBIONE". Użytkownik z rolą niższą niż MANAGER może to zrobić do końca tego dnia',
          'Dyżury mogą być tworzone przez użytkowników z rangą MANAGER lub z bonusową rolą SHIFTMANAGER (na ten moment hardcoded user.id)',
          'SHIFTMANAGER oraz MANAGER mogą przypisywać dodatkowe informacje do dyżuru np. w przypadku DeepCleaning wiele użytkowników będzie miało tego dnia Shift, a dodatkowa informacja (zadanie) zostanie opisana przez menedżera w formie tekstowej np. śmieci, zmywanie, odkurzanie',
          'Dodatkowa informacja jest widoczna tylko dla menedżerów oraz użytkownika z tym dyżurem i jest ona wyświetlana na bannerze (pracownik) lub na liście ustawień dyżurów (menedżer)',
          'W widoku /shifts, na kalendarzu, dyżury zaplanowane mają kolor szary, dyżury wykonane kolor zielony, a dyżury niewykonane kolor czerowny',
        ],
      },
    ],
    // update: [
    //   {
    //     title: 'Logo',
    //     desc: 'Zmieniono pustą, smutną ikonę aplikacji na logo RDB Group (low quality bo na cito) 🙃',
    //   },
    // ],
    info: [
      {
        title: 'Projects',
        desc: 'Naprawiono dane wyświetlane w wiodku /projects. Estymacje były błędnie próbkowane z powodu zmiany czasu serwera. Dodatkowo odfiltrowano projekty, które w bieżącymi miesiącu nie posiadają ani estymacji, ani aktywności. Jeżeli projekt w podsumowaniu progresu wskazuje na 0/0h, oznacza to, że zostały w ramach tego projektu wykonane aktywności, ale mają one sumaryczną wartość godzin poniżej 1h. Przypominienie: ',
        points: [
          'w widoku /projects zawarte są podsumowania jedynie aktywności z określonym projektem',
          'poziom naładowania projektu nie odzwierciedla faktycznego stanu rzeczy np. suma aktywności z nieokreślonym projektem przewyższa wszystkie estymacje',
          'zaleca się wymuszenie określania projektu podczas tworzenia aktywności. MANAGER+ może dokonać to w ustawieniach firmy, zaznaczając opcję "Require Project"',
        ],
      },
      {
        title: 'Resetowanie Hasła',
        points: [
          'Wejście na podstroną do generowania tokenu potwierdzającego resetowanie hasła /password-reset od teraz wyświetla formularz nawet, gdy użytkownik jest zalogowany',
          'Wejście na podstronę do tworzenia nowego hasła /password-new od teraz automatycznie wyloguje użytkownika, jeżeli użytkownik jest zalogowany',
        ],
      },
    ],
    errors: [
      {
        title: 'Login Reload',
        desc: 'Zauważono błąd związany z resetowaniem danych logowania. W momencie, w którym wylogujemy się lub zostaniemy automatycznie wylogowani i odświeżymy stronę będąc niezalogowanym, a następnie pomyślnie się zalogujemy, stany globalne (auth.context) związane z informacjami o zalogowanym użytkowniku, są resetowane. Utrudnia to tworzenie zapytań automatycznych np. sprawdzenie dyżuru podczas włączenia aplikacji. Prawdopodobnie jest to wywołane faktem, iż podczas pierwszego logowania, kiedy w pamięci urządzenia nie zostały zapisane dane logowania (localStorage), dochodzi o samozwańczego zresetowania stanów autoryzacji. Aktualnym rozwiązaniem jest wymuszenie odświeżenia aplikacji przez przeglądarkę. Wówczas inicjalizacja aplikacji i zapis contextu logowania jest poprawne, z uwagi na istniejące dane w pamięci urządzenia. Skutkiem tego rozwiązania jest wydłużenie czasu ładowania aplikacji oraz (w przypadku kont typu CHIEF) rozpoczęcie zaciągania dużej ilości danych, które zostaną odrzucone na skutek zerwania aktualnego połączenia (chodzi o widok Dashboard)',
      },
    ],
  },
  {
    title: '22 October',
    subtitle: '💲 - Cost Calculation',
    version: '1.2.3',
    date: '22.10.2021 16:10',
    add: [
      {
        title: 'Kalkulacja Kosztów',
        desc: 'W widoku /estimates dla sekcji z firmami dodano sumaryczną kalkulację kosztów oraz budżetu dla poszczególnej z firm. Algorytm działa w sposób nastepujący:',
        points: [
          'Wyróżniono 2 typy kosztów: costA - łączny koszt realizacji na podstawie wykonanych aktywności oraz costE - planowany koszt na podstawie estymacji',
          'Podsumowanie kosztów firmy (costA / costE) jest sumą kosztów składowych projektów (rozwinięcie poniżej) oraz sumą aktywności "Bez projektu" pomnożony o wartość cph_default (domyślny Cost Per Hour) ustaloną w opcjach firmy (/settings), chyba, że zostały utworzone estymacja "Bez projektu", ale z kategoriami - co?',
          'Koszt projektu jest wyliczony na podstawie sumy estymacji tego projektu z wyróżnieniem kosztów konkretnych kategorii (każda estymacja dla tego projetu może mieć inne cph). Dla aktywności wykonanych na rzecz danego projektu "Bez kategorii", brany pod uwagę jest cph estymacji tego projektu (może być inny od cph estymacji z określonym projektem).',
          'Jeżeli istnieją aktywności dla jakiegoś projektu, a nie ma on żadnej estymacji, to cph zostaje ustalone na podstawie cph_default tej firmy.',
          'Koszt kategorii (w kontekście danego projektu) jest wyliczony na podstawie ilości godzin aktywności i estymacji, pomnożony o cph ustalony w estymacji dla tej kategorii',
          'Jeżeli istnieją aktywności dla jakiegoś projektu, ale są to aktywności w ramach konkretnej kategorii, która nie posiada swojej estymacji, ale istnieje estymacja bez kategorii dla tego projektu, to wszystkie takie aktywności będą liczone na podstawie cph estymacji bez kategorii tego projektu.',
          'W przypadku, w którym utworzono identyczną estymację tj. ten sam projekt, taka sama kategoria (lub obydwie bez kategorii), ale inne cph, to cph dla przynależnych aktywności będzie liczone na podstawie pierwszej wartości cph, jaką uda się znaleźć algorytmowi (pierwsza dodana estymacja), ponieważ nie ma możliwości określenia pod którą estymacją jest dana aktywność, gdy są niemalże identyczne',
          'Ponieważ system estymacji jest złożony (firmy, projekty, kategorie itd.) dodano przycisk "CPH DETAILS", którego aktywacja pokazuje szczegóły kalukacji kosztów w ramach poszczególnej grupy estymacji oraz pozyskane cph (z firmy, estymacji projektu bez kategorii lub estymacji projektu w kategorii lub estymacji kategorii bez projektu',
          'Całkowite, sumaryczne podsumowanie kosztów firmy jest widoczne na dole sekcji tej firmy po jej rozwinięciu',
          'W przypadku braku określonej domyślnej wartości cph_default dla danej firmy, zostanie wyświetlony komunikat, a tym samym, część kosztów, pomimo estymacji, może nie być zliczony!',
        ],
      },
      {
        title: 'Excel Raports',
        desc: 'W widoku /activities dodano możliwość wygenerowania pliku .xls. Zawiera 3 arkusze, analogicznie tak, jak raport .pdf posiada 3 tabele.',
      },
      'W widokach /team oraz /dashboard w sekcji Team Activities dodano możliwość edytowania wybranej aktywności (brak automatycznego odświeżenia po edycji - problematyczna implementacja i ogromna złożoność danych)',
      {
        title: 'Duplikowanie Estymacji',
        desc: 'W widoku /estimates w sekcji ustawień, po najechaniu na estymację pojawia się przycisk DUPLICATE, który dodaje kopię wybranej estymacji oraz otwiera panel edycji. Dzięki tej o opcji można szybciej tworzyć estymację dla projektu o różnych kategoriach.',
      },
    ],
    update: [
      {
        title: 'Dashboard Companies',
        desc: 'Poprawiono wygląd przycisków firm w widoku Dashboard. Od teraz niezaznaczone firmy w podsumowaniu bedą mniej widoczne w celu lepszej czytelności. Firmy oznaczone alertem zawsze pozostają widoczne.',
      },
      {
        title: 'Projects',
        points: [
          'Dodano sortowanie po ilości godzin aktywności',
          'Dodano wizualizację procentowego postępu w przypadku aktywności w kategorii bez estymacji (procentowy udział całego projektu)',
        ],
      },
      {
        title: 'Sortowanie',
        points: ['Poprawiono sortowanie w widokach /estimates oraz /dashboard'],
      },
      {
        title: 'Raports',
        points: [
          'Dodano kolumnę Projekt w raporcie PDF',
          'Dodano arkusz Projekty',
        ],
      },
    ],
    info: [
      {
        title: 'Dasboard',
        desc: 'Sekcja analizy firmy w widoku Dashboard może wskazywać na aktywne, puste zadanie - tak się dzieje w przypadku, kiedy jest niezakończone zadanie z innego dnia niż dzisiejsze. Należy wówczas w widoku /activities wyszukać to zadanie (na podstawie firmy, projektu oraz kategorii z zakresu kilku dni) i określić czas zakończenia',
      },
      {
        title: 'Nocny Marek',
        desc: 'Naprawiono błąd, w którym niemożliwe było edytowanie aktywności między godziną 00:00, a 02:00 z powodu różnicy w czasie między użytkownikiem, a serwerem. Dostosowano czas lokalny serwera do strefy czasowej Polski',
      },
    ],
  },
  {
    title: '15 October',
    subtitle: '🔼 - Estimated Projects',
    version: '1.2.2',
    date: '15.10.2021 13:00',
    add: [
      {
        title: 'Projects',
        desc: 'Został dodany nowy widok /projects dostępny dla wszystkich użytkowników, w którym pokazane są wszystkie bieżące projekty wraz z opisami oraz dostepnymi godzinami na podstawie estymacji z aktualnego miesiąca. Dodatkowo, jeżeli zostały zdefiniowane estymacje dla danego projektu z różnymi wersjami kategorii, to po wciśnięciu projektu zostanie wyświetlona z podziałem na kategorie. Sygnalizacja zagrożeń i overtime analogiczna jak dla widoku /estimates. UWAGA. Dane o aktywnościach, które nie mają nieokreślonego projektu nie są wliczone w tym widoku!',
        points: [
          'Alert ostrzegawczy (zółty) sygnalizuje, że estymacja lub jej składowa została przekroczona o 80% swojego czasu',
          'Alert ostrzegawczy (pomarańczowy) sygnalizuje, że estymacja lub jej składowa została przekroczona o 90% swojego czasu',
          'Alert ostrzegawczy (czerwony) sygnalizuje, że estymacja lub jej składowa została przekroczona o 101% swojego czasu',
        ],
      },
      {
        title: 'Mergowanie aktywności',
        desc: 'W widoku /activities dodano przycisk "Scal Aktywności", który łączy aktywności zawierające identyczne dane (autor, firma, kategoria, tytuł, data) w jedną aktywności, której czas trwania jest sumą wszystkich scalonych aktywności (włącznie z czasem obliczonym dynamicznie aktywności niezakończonych). Generowany raport z włączoną tą opcją będzie zawierał tylko zmergowane aktywności.',
      },
      {
        title: 'Offboarding',
        desc: 'Dodano dodatkowe zabezpieczenie w przypadku logowaniu użytkowników z rolą INACTIVE. Od teraz taki użytkownik nie będzie mógł przejść procesu autoryzacji i wyświetlony zostanie komunikat USER INACTIVE. W celu zmiany roli użytkownika należy przejść do widoku /settings (MANAGER). Ustawienie isActive na wyłączone jedynie wyłącza tego użytkownika z opcji filtracji w aktywnościach.',
      },
      {
        title: 'Require Projects',
        desc: 'Dodano nowe pole w kolekcji Companies "require_projects", którego uruchomienie powoduje, że aktywności związane z tą firmą muszą mieć wypełnione pole Project. Opcję można uruchomić w widoku Settings, edytując daną firmę. Zaleca się uruchomienie tej opcji dla firm, dla których zostały określone estymacje na podstawie projektów.',
      },
      {
        title: 'Raporty',
        desc: 'Zaktualizowano generator raportów aktywności w formacie PDF. Dodano:',
        points: [
          'Tabelę z podziałem na pracowników',
          'Pierwszą literę nazwiska pracownika tak, gdzie występuje',
          'Tytuł raportu teraz zmienia nazwę zależnie od ilości sfiltrowanych firm tj. dla więcej niż 1 wyświetli tytuł : Raport LICZBA firm, a dla jednej firmy wyświetli jej nazwę',
        ],
      },
    ],
    update: [
      {
        title: '/team',
        points: [
          'Companies - w sekcji Team Activities dodano dodatkowo podział na firmy -> projekty -> kategorie -> aktywności',
          'Categories - W sekcji Team Activities dodano dodatkowo podział na kategorie -> firmy -> projekty -> aktywności',
          'W Team Activities dodano informację o bieżącym zadaniu',
          'W Team Activities jeżeli użytkownik nie wykonuje żadnej aktywności, kolor jego podsumowania jest szary, zamiast koloru użytownika',
          'Ukryto widok startowy (Team Now) dla kont (MANAGER+), ponieważ widok Team Activities zawiera wszystkie niezbędne informacje',
        ],
      },

      {
        title: '/estimates',
        desc: 'Estymacje od teraz priorytetyzują projekty, ale umożliwiono dodawanie duplikatów estymacji dla danego projektów z rozróżnieniem kategorii i ilości godzin',
        points: [
          'Formularz wyszukiwarki estymacji domyślnie przyjmuje datę od dnia pierwszego bieżącego miesiąca, do dnia ostatniego bieżącego miesiąca',
          'Sekcja danych Companies posiada dodatkowe zagłębienie z podziałem na projekty tj. firma -> projekt -> kategoria -> użytkownik -> aktywność. Zarówno firma, projekt jak i kategoria sumuje przynależne aktywności i estymacje',
          'Dodano alert ostrzegawczy (zółty) kiedy estymacja zostanie przekroczona o 80% swojego czasu',
          'Dodano alert ostrzegawczy (pomarańczowy) kiedy estymacja zostanie przekroczona o 90% swojego czasu',
          'Dodano alert ostrzegawczy (czerwony) kiedy estymacja zostanie przekroczona o 101% swojego czasu',
          'Ustawiono sortowanie analizy estymacji począwszy od firm z największym procentowym czasem wykonanych aktywności (stosunkowo do estymacji)',
          'Do wylistowanych aktywności rozwiniętej estymacji dodano przycisk edycji (pióro), które po wciśnięciu uruchamia widok modyfikacji aktywności, analogiczny jak w widokach /tracking oraz /activities',
          'Dodano indykację aktualnych zadań w widoku /estimates w postaci efektu shine oraz kropki w wylistowanych sekcjach',
        ],
      },
      {
        title: 'Tworzenie Estymacji',
        points: [
          'Od teraz informacje w formularzu tworzenia nowych estymacji są pobierane za pomocą takich samych zapytań, jak w widoku /settings, przez co nie ma potrzeby odświeżać aplikacji po edycji kolekcji',
          'Tytuł estymacji uzupełnia się sam, gdy wybrany zostanie projekt i przyjmuje jego nazwę',
          'Daty estymacji domyślnie są ustawiane na podstawie następnego miesiąca tj. pierwszy i ostatni dzień następnego miesiąca',
        ],
      },
      {
        title: 'Relacje bazodanowe',
        desc: 'Odblokowano powiązania pomiędzy kolekcjami, dzięki czemu relacje pomiędzy projektami, a firmami nie powinno powodować błędów po ich aktualizacjach.',
      },
      {
        title: 'Tracking Projects',
        desc: 'W widoku /tracking, jeżeli istnieją projekty dla wybranej firmy, pokaże się ich ilość. Jeżeli pole Project jest wymagane dla tej firmy, interfejs zmieni kolor pola wyboru, dopóki nie zostanie określony projekt - tymsamym niemożliwe będzie rozpoczęcie aktywności. Analogiczna funkcjonalność rówież w widoku edycji estymacji.',
      },
      {
        title: 'Filtracja użytkowników',
        desc: 'Wprowadzono zmiany w mechanice filtracji użytkowników w widoku /activities. Aktualne zasady:',
        points: [
          'INTERN może jedynie zaciągnać informacje o samym sobie',
          'EXPERT+ mogą pobrać informacje jedynie o użytkownikach z rolą większą od INACTIVE',
          'CHIEF+ wyświetalją wszystkich użytkowników, którzy mają zaznaczone pole isActive (zmiana na /settings)',
        ],
      },
    ],
    info: [
      {
        title: 'Nocny Marek - tymczasowa łatka',
        desc: 'Ponieważ naprawa błędu "Nocny Marek" wymaga przetestowania zmian w warunkach błędowych tj. między 00:00, a 02:00 czasu polskiego, pełnoprawna naprawa zostanie przeprowadzona w innym, nieokreślonym czasie. Na ten momemt dodano wykluczenie walidacji edycji aktywności dla użytkownika Robert W., ponieważ jego godziny aktywności pokrywają się w czasie istnienia usterki, a błąd uniemożliwia mu poprawne trackowanie aktywności.',
      },
    ],
    errors: [
      {
        title: 'Nocny Marek',
        desc: 'Istnieje błąd, który uniemożliwia edycję aktywności w godzinach 00:00 - 02:00. Czas serwerowy jest przesunięty względem naszego o 2h. Aktualna, trywialna walidacja edycji aktywności sprawdza różnicę w czasie, nieuwzględniając daty (np. różnica w czasie pomiędzy godziną 22:00 a 00:00 nie wynosi 2h, tylko 22h) - problem dotyczy użytkowników z rolami INTERN, EXPERT',
      },
    ],
  },
  {
    title: '16 September',
    subtitle: '🔑 - Reset Password',
    version: '1.2.1',
    date: '16.09.2021 21:30',
    add: [
      {
        title: 'Resetowanie haseł',
        desc: 'Dodano możliwość zmiany hasła. Odpowiedzialny widok znajduje się pod adresem /password-reset lub pod przyciskiem "Zapomniałem hasła" w widoku logowania',
      },
      {
        title: 'Tracking Company Type',
        desc: 'Dodano wykres w widoku /tracking pokazujący podział na typy firm.',
      },
    ],
    update: [
      {
        title: 'Wykresy - format czasu',
        desc: 'Dodano przycisk "Format" w widoku /activities nad wykresami, dzięki któremu można zmienić format godzin (HH:MM lub dziesiętny)',
      },
    ],
    info: [
      {
        title: 'BUG: Overtime',
        desc: 'Naprawiono błąd graficzny, który uniemozliwiał korzystanie z interfejsu w wersji mobilnej, gdy użytkownik przekroczył dzienny czas pracy.',
      },
      {
        title: 'Sortowanie Aktywności',
        desc: 'Ponownie dodano sortowanie aktywności na poziomie backendu. Spadek szybkości zaciągnięcia całej bazy danych: ...TESTING',
      },
    ],
    errors: [
      {
        title: 'Nocny Marek',
        desc: 'Istnieje błąd, który uniemożliwia edycję aktywności w godzinach 00:00 - 02:00. Czas serwerowy jest przesunięty względem naszego o 2h. Aktualna, trywialna walidacja edycji aktywności sprawdza różnicę w czasie, nieuwzględniając daty (np. różnica w czasie pomiędzy godziną 22:00 a 00:00 nie wynosi 2h, tylko 22h) - problem dotyczy użytkowników z rolami INTERN, EXPERT',
      },
    ],
  },
  {
    title: '1 September',
    subtitle: '💻 - Dedicated DB',
    version: '1.2.0',
    date: '01.09.2021 02:00',
    add: [
      {
        title: '/activities - Tabela',
        desc: 'Dodano przycisk "Pokaż Tabelę" w widoku /activities. Opcja ta pokazuje tabelę z aktywnościami (od teraz domyślnie wyłączona). Dzięki tej opcji w przypdku zaciągania danych z całego miesiąca oszczędza się ok. 30 sekund na "drukowanie" danych w interfejsie.',
      },
      {
        title: '/team polling',
        desc: 'Odświeżono widok /team oraz dodano automatyczne zaciąganie danych z bieżącego dnia co 15 minut (wyłącznie, kiedy widok jest aktywny)',
      },
      {
        title: '/team (MANAGER)',
        desc: 'Dodano oznacznia i animacje bieżących aktywności. Dodano możliwość automatycznego zaciągania danych co 5 minut poprzez zaznaczenie opcji POLLING (wyłącznie, kiedy widok jest aktywny)',
      },
    ],
    update: [
      'poprawiono widoczność elementów w widoku /team',
      'w widoku /estimates, zapytanie o aktywności nie uwzględnia już projektów w celu zwiększenia szybkości odczytywania danych',
      'wprowadzono drobne poprawki w strukturze danych, w celu optymalizacji zapytań',
      {
        title: 'usunięto data nesting',
        desc: 'w celu zwiększenia prędkości odczytu danych, usunięto zagnieżdżanie referencji, ponieważ na ten moment nie istnieje funkcjonalność, która by to wykorzystywała (np. firma -> aktywność -> użytkownik -> aktywności -> firma)',
      },
    ],
    info: [
      {
        title: 'Dedykowany serwer bazodanowy',
        desc: 'Przeniesiono wszystkie dane na nowy dedykowany serwer (zamiast współdzielonej mocy obliczeniowej). Po wykonaniu testów zaobserowano kilkukrotnie większoną szybkość wykonywania operacji. W przypadku zaciągania wszystkich aktywności z danego miesiąca, wynik wyniósł 13 sekund, zamiast 6 minut! 🤯',
      },
      {
        title: 'FIX: Token Expired',
        desc: 'Naprawiono błąd, który uniemożliwiał pobranie danych z serwera w momencie, w którym mineło 14 dni od zalogowania się',
      },
      {
        title: 'FIX: Team Activities',
        desc: 'Naprawiono błędy, które powodował, że rozwinięte szczegóły aktywności samoistnie się przesuwały po aktualizacji danych',
      },
    ],
    errors: [
      {
        title: 'Nocny Marek',
        desc: 'Istnieje błąd, który uniemożliwia edycję aktywności w godzinach 00:00 - 02:00. Czas serwerowy jest przesunięty względem naszego o 2h. Aktualna, trywialna walidacja edycji aktywności sprawdza różnicę w czasie, nieuwzględniając daty (np. różnica w czasie pomiędzy godziną 22:00 a 00:00 nie wynosi 2h, tylko 22h) - problem dotyczy użytkowników z rolami INTERN, EXPERT',
      },
    ],
  },
  {
    title: '30 August 👨‍👩‍👧‍👦 - Team',
    version: '1.1.7',
    date: '30.08.2021 17:30',
    add: [
      {
        title: '/team',
        desc: 'Dodano widok /team, w którym zaprezentowano wszytskich użytkowników, a także zadanie, jakie realizują w danej chwili (dostęp EXPERT)',
      },
      {
        title: 'Team Activities',
        desc: 'Dodano sekcję aktywności z podziałem na użytkowników (dalej, podział na firmy i kategorie (dalej, na zadania)) - dostęp MANAGER',
      },
    ],
    update: [
      {
        title: '/estimates views',
        desc: 'Rozwinięto widok /estimates. Od teraz wylistowane firmy zawierają podział na kategorie, te na użytkowników, a następnie podział na poszczególne aktynowści - analogiczna mechanika dla listy kategorii ',
      },
      {
        title: '/estimates range',
        desc: 'Zmieniono zakres, w jakim pobierane są estymacje. Po wybraniu zakresu DateFrom oraz DateTo pobrane zostaną wszystkie aktywności będące w tym przedziale oraz wszystkie estymacje, które zaczęły się przed wybraną datą dateFrom oraz zakończyły się po wybranej dacie dateTo',
      },
      {
        title: '/estimates no_category',
        desc: 'Od teraz pole kategorii w Estimates nie jest wymagane. Estymacje z nieokreśloną kategorią są oznaczone jako undefined. Suma estymacji danej firmy zawrze ilość takiej estymacji, ale nie zobrazuje to postępu, ponieważ aktywności zawsze mają jakąś kategorię',
      },
    ],
    info: [],
    errors: [
      {
        title: 'Token Expired',
        desc: 'Istnieje błąd, który uniemożliwia pobranie danych z serwera w momencie, w którym mineło 14 dni od zalogowania się. Ponieważ nie stworzono funkcjonalności do zapamiętywania czasu zalogowania, po stronie frontendu token istnieje, ale po stronie serwera jest już nieważny. W celu pozbycia się problemu należy się wylogować oraz zalogować ponownie.',
      },
    ],
  },
  {
    title: '28 August 🏴 - Estimates',
    version: '1.1.6',
    date: '28.08.2021 03:15',
    add: [
      {
        title: '/estimates CRUD',
        desc: 'Dodano możliwość modyfikacji estymacji',
        points: [
          'edycja instniejących estymacji',
          'dodawanie nowych estymacji',
        ],
      },
      {
        title: '/estimates filtracja',
        desc: 'Wyświetlanie estymacji i aktywności ze względu na:',
        points: [
          'Date From',
          'Date To',
          'Companies',
          'CompanyType',
          'Projects',
          'Categories',
        ],
      },
      {
        title: '/estimates szczegóły',
        desc: 'Wyświetlanie zagregowanych danych',
        points: [
          'Stosunek aktywności do estymacji dla każdej z firmy z podziałem na kategorie',
          'Stosunek aktywności do estymacji dla każdej z kategorii z podziałem na firmy',
        ],
      },
      {
        title: '/activiities inputs',
        desc: 'W opcji filtracji /activities dodano opcję zaznaczenia wszytskich możliwych rekordów dla pól:',
        points: ['Authors', 'Companies', 'Projects', 'Categories'],
      },
    ],
    update: [
      {
        title: 'getActivities',
        desc: 'Zaktualizowano funkcję do pobierania aktywności - usunięto sortownie po dacie oraz po czasie w celu zmniejszenia złożoności obliczeniowej zapytania oraz zmodyfikowano logikę układania żądania do bazy danych',
      },
      'poprawiono algorytmy obliczeniowe wykresów',
    ],
    info: [],
    errors: [
      {
        title: 'Token Expired',
        desc: 'Istnieje błąd, który uniemożliwia pobranie danych z serwera w momencie, w którym mineło 14 dni od zalogowania się. Ponieważ nie stworzono funkcjonalności do zapamiętywania czasu zalogowania, po stronie frontendu token istnieje, ale po stronie serwera jest już nieważny. W celu pozbycia się problemu należy się wylogować oraz zalogować ponownie.',
      },
    ],
  },
  {
    title: '18 August 👩‍💼 - Panel Managera',
    version: '1.1.5',
    date: '18.08.2021 22:20',
    add: [
      {
        title: 'EditActivity',
        desc: 'Dodano dodatkowe opcje edycji aktywności',
      },
    ],
    update: [
      {
        title: 'Avatar',
        desc: 'zaktualizowano avatary użytkowników we wszystkich miejscach, w których się pojawiają',
      },
      {
        title: 'Nazwisko',
        desc: 'od teraz nazwisko użytkownika nie jest wymagane',
      },
    ],
    info: [
      {
        title: 'Panel Managera',
        desc: 'Naprawiono szereg błędów w Panelu Managera',
        points: [
          'naprawiono błąd podczas dodawania nowych użytkowników',
          'dodano zapis nowych projektów/kategorii/firm/użytkowników w pamięci cache',
          'dodano automatyczne scrollowanie tabeli po dodaniu nowego rekordu',
        ],
      },
      {
        title: 'Tracking Summary',
        desc: 'Naprawiono błąd, w którym pasek ilustrujący "WorkDay" wychodził po za ekran, gdy użytkownik wyrobił nadgodziny',
      },
    ],
    errors: [
      {
        title: 'Token Expired',
        desc: 'Istnieje błąd, który uniemożliwia pobranie danych z serwera w momencie, w którym mineło 14 dni od zalogowania się. Ponieważ nie stworzono funkcjonalności do zapamiętywania czasu zalogowania, po stronie frontendu token istnieje, ale po stronie serwera jest już nieważny. W celu pozbycia się problemu należy się wylogować oraz zalogować ponownie.',
      },
    ],
  },
  {
    title: '10 August 🔥 - Performance Update',
    version: '1.1.3',
    date: '09.08.2021 23:03',
    info: [
      {
        title: 'Performance Update 💪',
        desc: 'Zrefaktoryzowano kod odpowiedzialny za tworzenie, aktualizację i pobieranie danych w celu redukcji zapytań do serwera. Wprowadzono następujące zmiany:',
        points: [
          {
            title: 'usunięto pollInterval /trakcing',
            desc: 'usunięto okresowe, automatyczne zaciąganie danych z serwera (pollInterval: 0) w widok /tracking. /activities posiada interwał co 30 minut i zostanie skrócony po przeprowadzeniu testów',
          },
          {
            title: 'usunięto refetchQueries',
            desc: 'poprzednio stosowano metodę refetchQueries, która wymuszała pobranie świeżych danych po dodaniu lub modyfikacji aktywności. Funkcjonalnośc ta została usunięta w celu redukcji zapytań',
          },
          {
            title: 'zmodyfikowano strukturę danych',
            desc: 'od teraz modyfikacja jakichkolwiek danych zwraca aktualizowany obiekt, dzięki czemu aplikacja po stronie przeglądarki poprawnie modyfikuje pamięć cache',
          },
          {
            title: 'dodano writeQuery',
            desc: 'od teraz podczas dodawania nowej aktywności, pamięć cache jest "ręcznie" modyfikowana poprzez skrypty, zamiast automatycznie poprzez środowisko',
          },
          {
            title: 'usunięto Usuwanie',
            desc: 'w wyniku licznych zmian funkcjonalność usuwania aktywności mających 0 minut została wyłączona - pozostają one w bazie danych, ale dalej są ukrywane w widokach',
          },
          {
            title: 'lag /activities',
            desc: 'usunięto spadek płynności w widoku /activities poprzez generowanie raportu pdf w momencie wciśnięcia przycisku zamiast w momencie pobrania danych',
          },
        ],
      },
    ],
  },
  {
    title: '09 August',
    subtitle: '📈 - Raports',
    version: '1.1.2',
    date: '09.08.2021 17:35',
    add: [
      {
        title: 'Raporty PDF',
        desc: 'Dodano możliwość wygenerowania pliku PDF na podstawie pobranych danych w widoku /activities',
      },
      {
        title: 'Wykresy PDF',
        desc: 'Dodano możliwość wygenerowania pliku PDF z danym wykresem - przycisk poniżej wykresu',
      },
    ],
    update: [
      {
        title: 'pollInterval /trakcing',
        desc: 'Zmieniono częstotliwość automatycznego zaciągania danych z 10 sekund na 5 minut w celu odciążenia serwera',
      },
      {
        title: 'pollInterval /activities',
        desc: 'Zmieniono częstotliwość automatycznego zaciągania danych z 30 sekund na 30 minut w celu odciążenia serwera',
      },
      {
        title: 'Pie Charts',
        desc: 'Dodano możliwość wyświetlenia danych na wykresach kołowych poprzez przycisk: Oznaczenia',
      },
    ],
    soon: [
      {
        title: 'isBlocked',
        desc: 'Każdy użytkownik może dowolnie określić, jakie firmy, kategorie i projekty mają być wyłączone z jego widoków',
      },
      {
        title: 'isSilent',
        desc: 'Użytkownicy z rangą MANAGER mogą uruchomić tryb SilenceMode, dzięki czemu dodane aktywności podczas tego trybu, będą inaczej wyświetlane w interfejsie',
      },
      {
        title: 'CITO Now',
        desc: 'Komponent wyświetlający informacje o aktualnych aktywnościach każdego z użytkowników w czasie rzeczywistym - widok główny',
      },
    ],
  },
  {
    title: '06 August',
    subtitle: '📊 - Analysis',
    version: '1.1.1',
    date: '06.08.2021 16:30',
    add: [
      {
        title: 'Detekcja Gap & Overlap',
        desc: 'Dodano algorytm, który przed wyświetleniem danych sprawdzi sąsiadujące aktywności. Jeżeli istnieje różnica pomiędzy zakończeniem taskA, a rozpoczęciem taskaB, która jest większa niż 1 minuta, to te aktywności zostaną oznaczone jako hasGap (kolor żółty) - tworzą one lukę w czasie pracy. Jeżeli różnica pomiędzy taskami jest mniejsza niż 1 minuta, to te aktywności zostaną oznaczone jako hasOverlap (kolor czerwony) - nachodzą one na siebie w czasie. W widoku /activities algorytm dodatkowo sprawdza, czy badane aktywności są tego samego użytkownika i czy zostały utworzone w tej samej dacie. Tutaj UWAGA! Jeżeli w /activities filtrujemy wiele osób, to algorytm może pominąć niektóre błędy, ponieważ sąsiadujące taski należą do różnych osób. W celu wyeryfikacji Gapów i Overlapów należy w filtracji wybrać tylko jedną osobę, aby mieć pewność, że wszystkie defekty zostaną pokazane. Defekt powinien być rozstrzygnięty i usunięty. Dodatkowo na kolor niebieski zaznaczone są aktualne aktywności (te, które nie mają czasu zakończenia)',
      },
      {
        title: 'Zliczanie aktualnych aktywności',
        desc: 'Dodano szereg usprawnień skryptów wyświetlających dane. Od teraz aktywności, które nie mają określonego czasu zakończenia (z założenia są to aktywności aktualne - te, które są wykonywane w danej chwili czasu) będą miały obliczaną w czasie rzeczywistym liczbę minut (od czasu rozpoczęcia do godziny pobranej z urządzenia, odświeżanej co 1 sekundę). Dzięki temu wszystkie statystyki i pozostałe komponenty wyświetlające dane będą uwzględniały aktualnie wykonywane czynności',
      },
      {
        title: 'Wykresy Radialne',
        desc: 'W widoku /activities dodano następujące wykresy:',
        points: [
          'company activities',
          'companyType activities',
          'category activities',
          'categoryType activities',
          'author activities',
          'project activities',
        ],
      },
      {
        title: 'Wykresy Słupkowe',
        desc: 'Dodano możliwość zmiany typu wykresów na słupkowe w widoku /activities',
      },
      {
        title: 'Wykresy Tracking',
        desc: 'Dodano kolorow wykresy aktywności z danego dnia (podział na firmy i kategorie) uwzględniające aktualne aktywności. Po najechaniu na sektor, zostaną przedstawione szczegóły związane z aktywnościami w danej kategorii lub na rzecz danej firmy',
      },
      {
        title: 'Summary',
        desc: 'Komponent w widoku /tracking oraz /activities podsumowujący ile pozostało do końca dnia pracy (w skali 8h / ilość dni / osoby - w przypadku trakcingu poprostu w skali 8h)',
      },
      {
        title: 'Podsumowanie Aktywności',
        desc: 'W widokach /tracking oraz /activities dodano komponent ze statystykiami:',
        points: [
          {
            title: 'Activities',
            desc: 'łączna liczba aktywności + podział na dni + podział na użytkowników',
          },
          {
            title: 'Time',
            desc: 'łączny czas aktywności + minuty + podział na dni',
          },
          {
            title: 'Average',
            desc: 'średnia liczba minut aktywności',
          },
          {
            title: 'Breaks',
            desc: 'łączny czas przerw + procent aktywności + średnia liczba minut przerw na dzień',
          },
          {
            title: 'Current',
            desc: 'Łączny czas trwania wszystkich aktualnych aktywności + liczba aktywnych użytkowników',
          },
          {
            title: 'Workday',
            desc: 'Procentowa wartość czasu jaki pozostał do wykonania 8 godzin w danym dniu (uwzględnia zakres dat oraz liczbę użytkowników) + liczba godzin pozostała do 8 godzin aktywności w danym dniu',
          },
        ],
      },
      {
        title: 'Routing',
        desc: 'Niezalogowany użytkownik będzie przekierowywany na podstronę /login, niezależnie od wpisanego adresu',
      },
      {
        title: 'Users Colors',
        desc: 'Dodano kolory do użytkowników na rzecz wykresów',
      },
      {
        title: 'Companies Colors',
        desc: 'Dodano kolory do firm na rzecz wykresów',
      },
      {
        title: 'Dodawanie aktywności klawaiturą',
        desc: 'Dodano możliwość tworzenia aktywności poprzez klawiatruę. Należy wciśnąć myszką pierwsze pole (Companies), a następnie klawiszem TAB przechodzić i wypełniać kolejne pola. W momencie "skupienia" na przycisku START należy wciśnąć klawisz ENTER',
      },
      {
        title: 'hasVersionSeen',
        desc: 'Wprowadzono detekcję, czy użytkownik zapoznał się ze zmianami na /version. Dopóki tego nie zrobi, w menu przy przycisku Version pokaże się czerowne kółko informacyjne. Informacja jest przechowywana w pamięci urządzenia i jest resetowana z każdą aktualizacją aplikacji',
      },
      {
        title: 'CategoryType',
        desc: 'Dodano nowe typt kategorii w celu lepszego rozróżniania danych na wykresach',
      },
    ],
    update: [
      {
        title: 'fetchPolicy: cache-first',
        desc: 'Dostosowano kod tak, aby wszelkie zmiany w aktywnościach były aktualizowane poprzez cache w pierwszej kolejności (wcześniejsze ustawienie priorytezowało zapytania do serwera. Aktualizowanie aktywności na początku będzie przeprowadzane w pamięci użytkownika, dzięki czemu widok powinien aktualizować się szybciej, a dodatkowo ograniczy to ilość przesyłanych informacji do serwera. Opcja automatycznej okresowej synchoronizacji wciąż pozostaje włączona, a jej częstotliwość ma wartość 10 sekund.',
      },
      'zmiany w pasku wersji',
      {
        title: 'Dokumentacja',
        desc: 'dodano możliwość automatycznego otwarcia dokumentacji poprzez parametr w adresie url - https://cito.appclever.pl/version?docs=true',
      },
      {
        title: 'userCreate Mail',
        desc: 'Zaktualizowano mail wysyłany do nowo utworzonego użytkownika. Zamiast instrukcji jest zawartym w nim link do podstrony z dokumentacją.',
      },
      {
        title: 'UserInfo Avatar',
        desc: 'Dodano avatar w widgetcie użytkownika - w przypadku braku zdjęcia pojawi się kolorowe kółko z inicjałami. Kolor jest określany w ustawieniach Employees - dostępny dla rang MANAGER',
      },
    ],
    info: [
      {
        title: 'Aktualizacja niezakończonej aktywności',
        desc: `Naprawiono błąd: "podczas aktualizacji aktywności, która nie posiada czasu zakończenia i jest on ręcznie wpisywany w widoku edycji. Czas zakończenia należy ustawić poprzez wcięnięcie ikony kalendarza i ręczne wybranie godziny suwakami". Teraz można wpisać czas zakończenia klawaiturą`,
      },
      {
        title: '/activities domyślne zapytanie',
        desc: 'Naprawiono błąd, który domyślnie ustawiał datę wyszukiwanych aktywności na podstawie czasu globalnego, zamiast czasu lokalnego (było przesynięcie 2 godzin)',
      },
      {
        title: 'Server Logs',
        desc: 'Naprawiono błąd w zapisie czasu logów serwerowych',
      },
      {
        title: 'Settings Create',
        desc: 'Naprawiono fałszywy błąd o istnieniu firmy/kategorii/projektu podczas dodawania w panelu managera',
      },
      {
        title: 'activityUpdate',
        desc: 'W widoku edycji aktywności nie można już wybrać innej kategorii niż Przerwa dla firmy PRZERWA i nie można wybrać innej firmy niż PRZERWA dla kategorii Przerwa ',
      },
      {
        title: 'Error: time is not a function',
        desc: 'Naprawiono błąd w kodzie wywołujący komunikat o błędzie poczas korzystania z funkcji repeatActivity',
      },
    ],
    soon: [
      {
        title: 'isBlocked',
        desc: 'Każdy użytkownik może dowolnie określić, jakie firmy, kategorie i projekty mają być wyłączone z jego widoków',
      },
      {
        title: 'isSilent',
        desc: 'Użytkownicy z rangą MANAGER mogą uruchomić tryb SilenceMode, dzięki czemu dodane aktywności podczas tego trybu, będą inaczej wyświetlane w interfejsie',
      },
      {
        title: 'CITO Now',
        desc: 'Komponent wyświetlający informacje o aktualnych aktywnościach każdego z użytkowników w czasie rzeczywistym - widok główny',
      },
    ],
  },
  {
    title: '04 August',
    subtitle: '🧾 - Docs',
    version: '1.1.0',
    date: '04.08.2021 05:00',
    add: [
      {
        title: '/activities PollInterval',
        desc: `W widoku Activities, wyszukane wcześniej dane będą zaciągane z serwera ponownie, automatycznie co 30 sekund`,
      },
      {
        title: 'Dokumentacja',
        desc: 'W widoku /version, po wciśnięciu przycisku "Dokumentacja", wyświetlone zostanie podstawowe informacje o aplikacji',
      },
    ],
    update: [
      {
        title: 'newActivity Title Autocomplete',
        desc: 'W wyniku wdrożenia spellCheck dla pola Title, w widoku /tracking, oraz z powodu wdrożenia funkcjonalności repeatActivity, wyłączono autocomplete dla pola Title',
      },
      {
        title: 'deleteActivity Toast',
        desc: 'Akcja usunięcia aktywności, poprzez zmianę czasów rozpoczęcia i zakończenia na tą samą wartośc, wyświetli komunikat o usunięciu, zamiast komunikatu o aktualizacji aktywności',
      },
    ],
    info: [
      {
        title: 'production',
        desc: 'Przejście do wyższej wersji 1.1 - aplikacjia upublicziona grupie docelowej',
      },
      {
        title: 'companyUpdate',
        desc: 'Naprawiono błąd, w którym nie można było zmienić typu firmy na EXTERNAL',
      },
      {
        title: 'BŁĄD newActivity',
        desc: 'Błąd, w którym niemożliwym było dodanie nowej aktywności, pomimo wypełnienia wymaganych pól, powinien zostać rozwiązany, ze względu na usunięcie funkcjonalności autocomplete dla pola Title w widoku /activity',
      },
    ],
    soon: [
      {
        title: 'isBlocked',
        desc: 'Każdy użytkownik może dowolnie określić, jakie firmy, kategorie i projekty mają być wyłączone z jego widoków',
      },
    ],
  },
  {
    title: '03 August',
    subtitle: '⏸ - Break',
    version: '1.0.31',
    date: '03.08.2021 00:00',
    add: [
      {
        title: 'SpellCheck',
        desc: `Dodano sprawdzanie poprawności pisowni w polu 'title' podczas dodawania aktywności w widoku /tracking (TEST - zauważono wpływ na wydajność)`,
      },
    ],
    update: [
      {
        title: 'Aktywność: Przerwa',
        desc: 'Podczas dodawania nowej aktywności w widoku /trakcing, po wybraniu firmy: PRZERWA, jedyną dostępną kategorią jest PRZERWA. Wybierając kategorie PRZERWA, jedyną dostępną opcją jest firma PRZERWA',
      },
    ],
    errors: [
      {
        title: 'BŁĄD newActivity',
        desc: 'istnieje błąd, w którym dodawanie aktywności jest niemożliwe, pomimo wypełnienia wszystkich pól. Należy wówczas wprowadzić tytuł ponownie. Błąd jest związany z niepoprawnym czyszczeniem pola autocomplete',
      },
    ],
    info: [
      {
        title: 'activityUpdate',
        desc: 'Naprawiono błąd, w którym INTERN mógł zmienić datę aktywności',
      },
      {
        title: 'Menu Mobile',
        desc: 'naprawiono błąd, w którym nie można było korzystać z Menu (mobile), kiedy zmieniono widok, gdy element Menu został przescrollowny w dół',
      },
      {
        title: 'Menu Mobile',
        desc: 'naprawiono błąd, w którym Menu przyjmowało stan rozwinięty po odświeżeniu strony na urządzeniach mobile',
      },
    ],
    soon: [
      {
        title: 'isBlocked',
        desc: 'Każdy użytkownik może dowolnie określić, jakie firmy, kategorie i projekty mają być wyłączone z jego widoków',
      },
    ],
  },
  {
    title: '02 August',
    subtitle: '➰ - Activity Repeat',
    version: '1.0.30',
    date: '02.08.2021 17:30',
    add: [
      {
        title: '/version',
        desc: 'dodano nowy widok, w którym zapisywane będą zmiany w aplikacji',
      },
      {
        title: 'CompanyType',
        desc: 'do firm można przypisać typ. Przyjmuje on wartości: INTERNAL, EXTERNAL oraz OTHER (domyślnie). W widoku /activities dodano nowe pole "CompanyType", które filtruje dane o określony typie firmy (jedynie po stronie frontendu)',
      },
      {
        title: 'Repeat Activity',
        desc: 'w widoku /tracking, przy zakończonych aktywnościach, wprowadzono przycisk START, który doda identyczną aktywność, stopując wcześniejszą 😉',
      },
      {
        title: 'isHidden Autocomplete',
        desc: 'Pomijanie z podpowiedzi firm, projektów, kategorii, które zostały oznaczone jako isHidden (ukryte). Jedynie w widoku /tracking',
      },
    ],
    update: [
      'dodano /version do menu',
      'zmieniono dolny pasek wersji',
      'zrefakturyzowano kod komponentów widoku /tracking',
      'zaktualizowno wygląd modułu aktualizacji aktywności (ostrzeżenie o usunięciu)',
      {
        title: 'TrackingHead',
        desc: 'animowane oznaczenie na karcie przeglądarki od teraz pokazuje nazwę firmy, kiedy aktywność jest uruchomiona',
      },
      {
        title: '/activities',
        desc: 'odświeżono widok aktywności tak, aby wyglądał identycznie jak widok /tracking. Tymsamym tymczasowo odebrano możliwość sortowania wyników',
      },
    ],
    info: [
      {
        title: 'Menu',
        desc: 'naprawiono błąd, w którym menu nie chowało się po wciśnięciu w link na mobile',
      },
    ],
  },
  {
    title: '31 July',
    subtitle: '📱 - Mobile',
    version: '1.0.29',
    date: '31.07.2021 04:08',
    add: ['wersja mobile', 'nowe menu', 'nowe komunikaty', 'logo'],
    update: [
      'znaczące zmiany wizualne',
      'zaktualizowany widok logowania',
      'aktualizacja panelu managera',
      {
        title: 'Usuwanie Aktywności',
        desc: 'Jeżeli aktywność posiada już czas zakończenia i jest on równy czasowi rozpoczęcia lub dodana aktywność została zakończona mniej niż minutę temu - aktywność zostanie trwale usunięta z bazy danych',
      },
    ],
  },
  {
    title: '29 July',
    subtitle: '⚙️ - Settings',
    version: '1.0.20',
    date: '29.07.2021 19:06',
    add: [
      '/settings - Projects',
      '/settings - Companies',
      '/settings - Categories',
      '/settings - Employees',
    ],
    info: ['changelog niepełny'],
    update: [
      'poprawa styli',
      'aktualizacja mailingu',
      'naprawa timerów',
      'nowy TrackingHead (animowany tytuł karty w przeglądarce)',
      'aktualizacja toastów (powiadomienia)',
    ],
  },
  {
    title: '28 July',
    version: '1.0.16',
    subtitle: '🖥 - Database',
    date: '28.07.2021',
    add: [
      {
        title: 'Baza Danych',
        desc: 'Wprowadzenie danych istniejących firm i kategorii',
      },
    ],
    info: ['changelog niepełny'],
    update: ['poprawa styli', 'aktualizacja mailingu'],
  },
  {
    title: '27 July',
    subtitle: '✅ - Tracking',
    version: '1.0.10',
    date: '27.07.2021',
    add: [
      'aktualizacja do HTTPS',
      '/tracking - rozpoczynanie i stopowanie aktywności',
    ],
    info: ['changelog niepełny'],
  },
  {
    title: '26 July',
    subtitle: '🔎 - Activities Search',
    version: '1.0.6',
    date: '26.07.2021',
    add: ['/activities - wyszukiwarka aktywności'],
    info: ['changelog niepełny'],
  },

  {
    title: '23 July',
    subtitle: '📛 - Auth',
    version: '1.0.2',
    date: '23.07.2021',
    add: ['Podstawowy routing', 'logowanie i wylogowywanie'],
    info: ['changelog niepełny'],
  },

  {
    title: '22 July',
    subtitle: '🌐 - Backend',
    version: '1.0.1',
    date: '22.07.2021',
    add: ['Zakończenie fazy backend'],
    info: ['changelog niepełny'],
  },
]

const documentation = [
  {
    title: 'CITO',
    desc: 'CITO (Company Internal Tracking/Task Organizer) to aplikacja, która służy do zapisywania i analizowania aktywności, wykonywanych podczas pracy',
  },
  {
    title: 'Rangi',
    desc: 'Użytkownicy CITO mają określone rangi. Zostały ułożone od najniższej do najwyższej, a każda kolejna ranga zawiera możliwości poprzednich. Wyróżniamy:',

    points: [
      {
        title: 'INACTIVE',
        desc: 'konto dezaktywowane - brak możliwości zalogowania i pobrania danych',
      },
      {
        title: 'INTERN',
        desc: 'podstawowe konto - trackowanie aktywności z danego dnia oraz ograniczona czasowo edycja, przeglądanie wszystkich autorskich aktywności',
      },
      {
        title: 'EXPERT',
        desc: 'możliwość przeglądania aktywności innych użytkowników',
      },
      {
        title: 'MANAGER',
        desc: 'możliwość edycji aktywności innych użytkowników oraz modyfikowanie dostępnych firm, kategorii oraz projektów',
      },
      {
        title: 'CHIEF',
        desc: 'możliwość dodawania i modyfikowania użytkowników, estymacji oraz dostęp do rozszerzonych, utajnionych danych',
      },
      {
        title: 'SHIFTMANAGER',
        desc: 'dodatkowy atrybut. możliwość dodawania i modyfikowania dyżurów',
      },
    ],
  },
  {
    title: 'Login',
    desc: 'Logowanie do aplikacji następuje poprzez widok startowy, na którym należy podać email oraz hasło do konta. Po poprawnym zalogowaniu otworzy się główny ekran aplikacji, a informacja o autoryzacji zostanie zapisana w pamięci przeglądarki na urządzeniu (tzw. token). Token może być ważny do 2ch tygodni od utworzenia, więc nie ma potrzeby logowania się za każdym razem do aplikacji. W przypadku, kiedy jesteśmy zalogowani, a serwer zwracam błąd autoryzacji, należy zalogować się ponownie. Po 2ch tygodniach od ostatniego logowania, aplikacja automatycznie zresetuje token, wymuszając ponowne zalogowanie.',
  },
  {
    title: 'Logout',
    desc: 'W celu wylogowania się z konta, należy wcisnąć przycisk strzałki na samym dole Menu (aktualnie jest on ukryty pod czarnym paskiem informującym o wersji aplikacji - można go zamknąć poprzez ikonę krzyżyka po prawej stronie paska)',
  },
  {
    title: 'Hasło',
    desc: 'Hasło do konta można zresetować w widoku logowania (po wylogowaniu) oraz w widoku /password-reset. Należy podać email, na który ma zostać wysłany link do resetowania hasła. Po otrzymaniu wiadomości i wejści do aplikacji poprzez link z tokenem można utworzyć nowe hasło. Link jest ważny tylko przez 15 minut',
  },
  {
    title: '/tracking',
    desc: 'Trackowanie aktywności polega na dodaniu nowego rekordu w widoku /tracking poprzez wprowadzenie następujących pól: Firma (Company), Projekt (Project) - pole może być wymagane dla konkretnej firmy (domyślnie opcjonalne), Kategoria (Category) oraz Tytuł/opis aktywności (Title). Czas rozpoczęcia aktywności jest automatycznie dodawany w momencie wciśnięcia przycisku START',
  },
  {
    title: 'Activity Stop',
    desc: 'Zatrzymanie aktywności można wykonać poprzez wciśnięcie przycisku STOP przy aktywności, edycji aktywności i ręczne ustawienie czasu zakończenia lub poprzez rozpoczęcie nowej aktywności - wówczas aktualna aktywność zostanie automatycznie zakończona w danej chwili czasu',
  },
  {
    title: 'Activity Break',
    desc: 'W widoku /tracking można również dodawać przerwy - jest to aktywność o określonych parametrach, ale rządzi się takimi samymi prawami, jak pozostałe aktywności. Przerwę można rozpocząć poprzez wciśnięcie przycisku BREAK - zatrzymane zostanie wówczas aktualne zadanie',
  },
  {
    title: 'Activity Start',
    desc: 'Dodawanie aktywności jest możliwe tylko i wyłącznie w przeciągu 24 godzin tj. niemożliwym jest dodanie aktywności, które miały lub będą mieć miejsce więcej niż 1 dobę od aktualnego czasu. Po stronie fronte-endu aplikacji, możliwym jest dodanie wyłącznie aktywności z aktualną datą',
  },
  {
    title: 'Activity Update',
    desc: 'Edycję aktywności można dokonać poprzez kliknięcie ikony długopisu po prawej stronie wiersza aktywności, zarówno w widoku /tracking, jak i /activities. Otworzy się wówczas panel z wypełnionymi polami. Odpowiednia edycja pola oraz wciśnięcie przycisku SAVE zapisze aktywnośc. Oto kilka reguł:',
    points: [
      'Edytowanie aktywności, która nie została jeszcze zakończona, jest możliwe w granicach 1 godziny od rozpoczęcia tej aktywności',
      'Edytowanie aktywności, która została już zakończona, jest możliwe w granicach 1 godziny od zakończenia tej aktywności',
      'Konta rangi MANAGER mają nieograniczoną możliwość edycji aktywności innych użytkowników',
    ],
  },
  {
    title: '/activities',
    desc: 'W widoku /activities znajduje się wyszukiwarka aktywności. Można skorzystać z filtracji danych poprzez odpowiednie ustawienie parametrów w polach. Kilka informacji',
    points: [
      'użytkownicy INTERN mogą jedynie przeglądać autorskie aktywności',
      'można dowolnie określać przedział czasowy porządanych aktywności poprzez wybór dat granicznych',
      'podanie daty początkowej spowoduje zaciągnięcie wszystkich aktywności, które rozpoczęły się po określonej dacie',
      'podanie daty końcowej spowoduje zaciągnięcie wszystkich aktywności, które zakończyły się przed określoną datą',
      'usunięcie dat granicznych spowoduje zaciągnięcie wszystkich możliwych aktywności',
      'opcje filtracji pomiędzy polami należy traktować jako operację logiczną AND, natomiast opcje filtracji wewnątrz pól jako operację logiczną OR. Prościej: wybranie w polu Companies opcji: firmaA, firmaB oznacza, że będziemy szukać aktywności które zostały dodane na poczet firmyA lub (OR) firmyB. Natomiast wybranie kolejnego pola, np. Categories z opcjami: kategoria1, kategoria2 oznacza, że będziemy szukać aktywności które zostały oznaczone jako kategoria1 lub kategoria2 i (AND - warunek konieczny) są przyporządkowane do którejś z wybranych firm: firmaA lub firmaB, czyli w skrócie: pobrane zostaną aktywności (firmaA OR firmaB) AND (kategoria1 OR kategoria2)',
      'brak wskazania opcji filtracji dla danego pola powoduje zaciągnięcie aktywności o dowolnych parametrach w kontekście tego pola ',
    ],
  },
  {
    title: 'Activity Delete',
    desc: 'Aktywności, których czas trwania jest mniejszy niż minutę są automatycznie ukrywane we wszystkich możliwych widokach. Można więc "usunąć" istniejącą aktywność poprzez jej edycję i ustawienie czasów rozpoczęcia i zakończenia na tą samą wartość. Rozpoczęcie nowej aktywności i zatrzymanie jej (poprzez przycisk STOP lub rozpoczęcie kolejnej) w czasie krótszym niż 1 minuta również spowoduje jej ukrycie. Mimo, że aktywności są ukrytę, można je odzyskać w bazie danych.',
  },
  {
    title: '/tracking Poll',
    desc: 'Każda zamiana w aktywnościach powoduje aktualizację danych w pamięci aplikacji oraz automatyczną próbę zaciągnęcia nowych danych z serwera. W przypadku problemów należy odświeżyć stronę',
  },
]

const Version = () => {
  const params = new URLSearchParams(useLocation().search)
  const [docOpen, setDocOpen] = useState(params.get('docs') ? true : false)

  useEffect(() => {
    localStorage.setItem(packageJson.version, true)
  }, [])

  return (
    <>
      <Seo title='🆔 Version' />
      <PageTitle>
        <h1
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [s.sm_down]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          }}>
          <span>
            {process.env.REACT_APP_NAME} Version {process.env.REACT_APP_VERSION}
          </span>
          <div
            onClick={() => setDocOpen(!docOpen)}
            css={[
              sLink,
              { width: 'max-content' },
              docOpen && { opacity: 0.3 },
            ]}>
            <div css={sLinkIcon}>
              <AssignmentIcon fontSize='inherit' />
            </div>
            <div css={[sText]}>Dokumentacja</div>
          </div>
        </h1>
      </PageTitle>
      <Collapse in={docOpen}>
        <Documentation />
      </Collapse>

      <PageWrapper>
        {updates.map(
          (
            {
              title,
              subtitle,
              version,
              date,
              add,
              update,
              info,
              deleted,
              errors,
              soon,
            },
            id
          ) => (
            <section
              css={[sSection, id === 0 && { h2: { paddingTop: 0 } }]}
              key={id}>
              <h2>
                {title}&nbsp;
                {subtitle}
              </h2>
              <h5>
                <span>{version}</span> - {date}
              </h5>
              <RenderSubsection title='New' data={add} color='#008000' />
              <RenderSubsection title='Update' data={update} color='#007aff' />
              <RenderSubsection title='Info' data={info} color='#ffaa00' />
              <RenderSubsection title='Delete' data={deleted} color='#ff8000' />
              <RenderSubsection title='Errors' data={errors} color='#ff0000' />
              <RenderSubsection title='Soon' data={soon} color='#c1c1c1' />
            </section>
          )
        )}
      </PageWrapper>
    </>
  )
}

const Documentation = () => (
  <>
    <PageTitle extraCss={{ paddingTop: 0 }}>
      <h1>Dokumentacja</h1>
    </PageTitle>
    <PageWrapper extraCss={{ paddingBottom: 0, marginBottom: '4rem' }}>
      <section css={[sSection]}>
        <RenderSubsection
          extraCss={{ marginTop: 0 }}
          title='Dokumentacja'
          data={documentation}
          color='#c1c1c1'
        />
      </section>
    </PageWrapper>
  </>
)

const RenderSubsection = ({ title, data, color, extraCss }) => {
  if (data?.length > 0)
    return (
      <div css={[{ display: 'flex', margin: '2rem 0 0' }, extraCss]}>
        {title && (
          <div css={[sSubsection, colorize(color)]}>
            <span>{title}</span>
          </div>
        )}
        <ul>
          {data.map((item, id) => (
            <li key={id}>
              {typeof item === 'object' ? (
                <>
                  {item.title}
                  <p>{item.desc}</p>
                  {item.points && (
                    <ul>
                      {item.points.map((item, id) => (
                        <li key={id}>
                          {typeof item === 'object' ? (
                            <>
                              {item.title}
                              <p>{item.desc}</p>
                            </>
                          ) : (
                            <span>{item}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                item
              )}
            </li>
          ))}
        </ul>
      </div>
    )

  return <></>
}

const colorize = (color) => ({
  // backgroundColor: color.concat(alpha[10]),
  // color: color,
  backgroundColor: color,
  color: 'white',
})

const sSubsection = {
  display: 'flex',
  position: 'relative',
  textAlign: 'center',
  width: 20,
  fontWeight: 900,
  fontSize: '0.5rem',
  letterSpacing: '0.25em',
  textTransform: 'uppercase',
  // padding: '4px 6px',
  margin: 0,
  borderRadius: '4px',
  flexShrink: 0,
  span: {
    transformOrigin: '0 0',
    transform: 'rotate(-90deg) translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    opacity: 1,
  },
}

const sSection = {
  margin: '0 2rem',
  [s.sm_down]: {
    margin: '0 1rem',
  },
  borderBottom: '1px solid rgb(0 0 0 / 25%)',
  h2: {
    paddingTop: '4rem',
    fontWeight: 300,
    fontSize: '1.5rem',
    marginBottom: '0.5rem',
  },

  h5: {
    fontSize: '0.75rem',
    fontWeight: 800,
    color: 'rgb(0 0 0 / 25%)',
    span: {
      color: 'rgb(0 0 0 / 100%)',
    },
  },
  ul: {
    fontSize: '0.75rem',
  },
  li: {
    [s.lg]: {
      maxWidth: '40vw',
    },
    marginBottom: '1rem',
    p: {
      marginTop: '0.125rem',
      opacity: 0.5,
      fontStyle: 'italic',
    },
    'ul li': {
      marginTop: '0.5rem',
      span: {
        color: 'rgb(0 0 0 / 50%)',
      },
    },
  },
}

export default Version
