/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import Seo from '../../components/Seo'
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  Typography,
} from '@material-ui/core'
import { sBackground, sTitle, sForm, sPage, sInput } from '../Login/Login'
import citoLogo from '../../assets/cito_logo.svg'
import { Link } from 'react-router-dom'
import useUserMutations from '../../hooks/useUser.mutations'
import emailValidate from '../../utils/emailValidate'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const initValues = {
  email: '',
}

const PasswordReset = () => {
  const [inputValues, setInputValues] = useState(initValues)
  const [isLoading, setIsLoading] = useState(false)
  const { handlePasswordReset } = useUserMutations()
  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (!emailValidate(inputValues.email)) {
      toast.error('EMAIL INVALID')
      return setIsLoading(false)
    }
    handlePasswordReset({ email: inputValues.email }, (data) => {
      setIsLoading(false)
      if (data?.success) {
        toast.dark(
          <div>
            <strong>Resetowanie Hasła</strong>
            <br />
            <span>
              Link do zresetowania hasła został wysłany na email{' '}
              {inputValues.email}. Bedzie aktywny{' '}
              <strong>tylko przez 15 minut!</strong>
            </span>
          </div>,
          { autoClose: 15 * 60 * 1000 }
        )
        history.push('/login')
      }
    })
  }

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  return (
    <>
      <Seo title='🔑 Reset Password' />
      <div css={sPage}>
        <div css={sBackground} />

        <form onSubmit={handleSubmit} css={sForm}>
          <div css={sTitle}>
            <img alt='CITO' src={citoLogo} />
          </div>
          <Typography
            variant='h5'
            css={{ textAlign: 'left', width: '100%', marginBottom: '1rem' }}>
            Zresetuj hasło
          </Typography>
          <p css={{ fontSize: '0.75rem' }}>
            Podaj swój email do konta CITO. Na skrzynkę pocztową zostanie
            wysłany link umożliwiający podanie nowego hasła.
          </p>
          <Grid container css={{ width: '100%' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                name='email'
                type='text'
                label='Email'
                autoComplete='email'
                value={inputValues.email}
                onChange={onChange}
                css={sInput}
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            css={{ marginTop: '2rem', width: '100%' }}
            disabled={isLoading}>
            SEND REQUEST
            {isLoading && (
              <CircularProgress
                size={24}
                css={{
                  position: 'absolute',
                  top: 6,
                }}
              />
            )}
          </Button>
          <div
            css={{
              display: 'flex',
              width: '100%',
              marginTop: '2rem',
              fontSize: '0.75rem',
              opacity: 0.5,
              '&:hover': {
                opacity: 1,
              },
            }}>
            <Link to='/login'>Pamiętasz hasło? Zaloguj się</Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default PasswordReset
