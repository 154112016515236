/** @jsxImportSource @emotion/react */

import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'

import { useQuery, useMutation } from '@apollo/client'

import {
  GET_ESTIMATES,
  ESTIMATE_CREATE,
  ESTIMATE_UPDATE,
  ESTIMATE_DELETE,
} from '../../graphql/estimate.graphql'

import { Link } from 'react-router-dom'

// import { GET_NAMES } from '../../graphql/data.graphql'
import { GET_COMPANIES_SETTINGS } from '../../graphql/company.graphql'
import { GET_PROJECTS_SETTINGS } from '../../graphql/project.graphql'
import { GET_CATEGORIES } from '../../graphql/category.graphql'
import { GET_USERS } from '../../graphql/user.graphql'

import { toast } from 'react-toastify'
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Chip,
  Avatar,
  Checkbox,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { imageLink } from '../../utils/imageLink'
import moment from 'moment'
import FormProject from './FormProject'
import ClickupTaskChip from '../Clickup/ClickupTaskChip'
import { s, alpha } from '../../style'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const EstimatesSettings = ({
  estimates,
  estimatesLoading,
  getEstimatesVaraibles,
  setEditEstimate,
  editEstimate,
  refetchModal,
  refetchModalCompanies,
}) => {
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery(GET_USERS)

  const {
    data: companiesData,
    loading: companiesLoading,
    error: companiesError,
  } = useQuery(GET_COMPANIES_SETTINGS)

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery(GET_PROJECTS_SETTINGS)

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(GET_CATEGORIES)

  const [estimateCreate] = useMutation(ESTIMATE_CREATE)
  const [estimateUpdate] = useMutation(ESTIMATE_UPDATE)
  const [estimateDelete] = useMutation(ESTIMATE_DELETE)
  const [open, setOpen] = useState(false)
  const [openProject, setOpenProject] = useState(false)
  const tableRef = useRef(null)

  const scrollToBottom = () => {
    tableRef.current?.scrollTo({
      top: tableRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  if (
    estimatesLoading ||
    companiesLoading ||
    projectsLoading ||
    usersLoading ||
    categoriesLoading
  )
    return <Skeleton variant='rect' height={300} css={{ width: '100%' }} />

  if (companiesError || projectsError || categoriesError || usersError) {
    const error =
      companiesError || projectsError || categoriesError || usersError
    toast.error(error.message)
    return <div>Error: {error.message}</div>
  }

  const companies = companiesData.getCompanies
  const projects = projectsData.getProjects
  const categories = categoriesData.getCategories
  const users = usersData.getUsers

  const namesData = { companies, projects, categories, users }

  const userValidation = (input) => {
    if (input.users?.length > 0 && (!input.category || !input.project)) {
      toast.error(
        <>
          Estymacje przypisane użytkownikom nie mogą być zbiorcze!
          <br />
          <span>Ustal projekt i kategorię lub usuń przypisane osoby</span>
        </>,
        { autoClose: false }
      )
      return false
    }
    return true
  }

  const onUpdateSubmit = ({ id, input, callback }) => {
    if (!userValidation(input)) return null
    const newInput = {
      ..._.omit(input, ['__typename', '_id']),
      company: input.company?._id,
      category: input.category?._id,
      project: input.project?._id || null,
      cph: parseFloat(input.cph),
      hours: parseFloat(input.hours),
    }
    estimateUpdate({
      variables: { id, input: newInput },
      refetchQueries: [
        {
          query: GET_ESTIMATES,
          variables: getEstimatesVaraibles,
        },
      ],
    })
      .then((res) => {
        if (res?.data?.estimateUpdate)
          toast.info(
            <div>
              <strong>
                ♾&nbsp;&nbsp;<span>UPDATED</span>
              </strong>
              <p>
                <strong>{input.title}</strong>
                {input.company?.name && (
                  <span>&nbsp;({input.company.name})</span>
                )}
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  const onCreateSubmit = ({ input, callback }) => {
    if (!userValidation(input)) return null
    const newInput = {
      ..._.pickBy(input),
      company: input.company?._id,
      category: input.category?._id,
      project: input.project?._id || null,
      cph: parseFloat(input.cph),
      hours: parseFloat(input.hours),
    }
    estimateCreate({
      variables: { input: newInput },
      refetchQueries: [
        {
          query: GET_ESTIMATES,
          variables: getEstimatesVaraibles,
        },
      ],
      update: (cache, { data }) => {
        const estimatesCache = cache.readQuery({
          query: GET_ESTIMATES,
          variables: getEstimatesVaraibles,
        })
        cache.writeQuery({
          query: GET_ESTIMATES,
          variables: getEstimatesVaraibles,
          data: {
            getEstimates: [
              ...estimatesCache?.getEstimates,
              data?.estimateCreate,
            ],
          },
        })
      },
      // refetchQueries: [{ query: GET_ESTIMATES, variables: getEstimatesVaraibles }],
    })
      .then((res) => {
        const resData = res?.data?.estimateCreate
        if (resData) {
          toast.success(
            <div>
              <strong>
                ♾&nbsp;&nbsp;<span>CREATED</span>
              </strong>
              <p>
                <strong>{input.title}</strong>
                {input.company?.name && (
                  <span>&nbsp;({input.company.name})</span>
                )}
              </p>
            </div>,
            { autoClose: 10000 }
          )
          if (setEditEstimate && editEstimate?.estimateIds) {
            setEditEstimate({
              ...editEstimate,
              estimateIds: [...editEstimate?.estimateIds, resData._id],
            })
          }
          if (refetchModalCompanies) refetchModalCompanies()
          if (refetchModal) refetchModal()
          if (callback) callback()
        }
        // scrollToBottom()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  const onDeleteSubmit = ({ id, callback }) => {
    estimateDelete({
      variables: { id },
      update: (cache, { data }) => {
        const estimatesCache = cache.readQuery({
          query: GET_ESTIMATES,
          variables: getEstimatesVaraibles,
        })
        // console.log(data.estimateDelete)
        // console.log(estimatesCache.getEstimates)
        // console.log(
        //   estimatesCache.getEstimates.filter((estimate) => estimate._id !== id)
        // )
        cache.writeQuery({
          query: GET_ESTIMATES,
          variables: getEstimatesVaraibles,
          data: {
            getEstimates: [
              ...estimatesCache?.getEstimates.filter(
                (estimate) => estimate._id !== id
              ),
            ],
          },
        })
      },
      // refetchQueries: [{ query: GET_ESTIMATES, variables: getEstimatesVaraibles }],
    })
      .then((res) => {
        if (res.data.estimateDelete) {
          toast.warn(
            <div>
              <strong>
                ♾&nbsp;&nbsp;<span>DELETED</span>
              </strong>
              <p>Estymacja {id} została trwale usunięta</p>
            </div>,
            { autoClose: false }
          )
          if (setEditEstimate && editEstimate) {
            setEditEstimate({
              ...editEstimate,
              estimateIds: editEstimate?.estimateIds?.filter((e) => e !== id),
            })
          }
          if (refetchModalCompanies) refetchModalCompanies()
          if (callback) callback()
        }
        // scrollToBottom()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  return (
    <>
      <Paper elevation={1}>
        <TableContainer css={{ [s.sm]: { maxHeight: '50vh' } }} ref={tableRef}>
          <Table stickyHeader aria-label='collapsible table' size='small'>
            <TableHead
              css={{
                th: { fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)' },
              }}
            >
              <TableRow>
                <TableCell css={{ paddingLeft: '3rem' }} align='left'>
                  Company
                </TableCell>
                <TableCell css={{ paddingLeft: '3rem' }} align='left'>
                  Category
                </TableCell>
                <TableCell css={{ paddingLeft: '3rem' }} align='left'>
                  Project
                </TableCell>
                <TableCell css={{ paddingLeft: '1rem' }} align='left'>
                  Users
                </TableCell>
                <TableCell>Estimate</TableCell>
                <TableCell align='center'>ClickUp</TableCell>
                <TableCell align='center'>From</TableCell>
                <TableCell align='center'>To</TableCell>
                <TableCell align='center'>Days</TableCell>
                <TableCell align='center'>CPH</TableCell>
                <TableCell align='right'>Hours</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {estimates.map((row, id) => (
                <Row
                  key={row._id}
                  data={row}
                  onSubmit={onUpdateSubmit}
                  names={namesData}
                  onDuplicate={onCreateSubmit}
                  onDeleteSubmit={onDeleteSubmit}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper elevation={1}>
        <Collapse in={open}>
          <Form
            open={open}
            setOpen={setOpen}
            onSubmit={onCreateSubmit}
            button={{ title: 'CREATE' }}
            names={namesData}
          />
        </Collapse>
      </Paper>
      <Paper elevation={1}>
        <Collapse in={openProject}>
          <FormProject
            open={openProject}
            setOpen={setOpenProject}
            button={{ title: 'CREATE' }}
            names={namesData}
          />
        </Collapse>
      </Paper>
      <Grid container css={{ marginTop: '0.5rem' }} spacing={4}>
        <Grid item>
          <Button
            size='small'
            onClick={() => setOpen(!open)}
            color={open ? 'default' : 'primary'}
            variant='contained'
          >
            {!open ? 'NEW ESTIMATE' : 'CANCEL CREATION'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size='small'
            onClick={() => setOpenProject(!openProject)}
            color={openProject ? 'default' : 'primary'}
            variant='contained'
          >
            {!openProject ? 'NEW PROJECT' : 'CANCEL CREATION'}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

const Row = ({ data, onSubmit, names, onDuplicate, onDeleteSubmit }) => {
  const [open, setOpen] = useState(false)
  const {
    _id,
    title,
    company,
    category,
    project,
    dateStart,
    dateEnd,
    cph,
    hours,
    clickupTasks,
    users,
  } = data

  return (
    <>
      <TableRow
        css={[
          {
            td: { fontSize: '0.75rem' },
            '&:hover': {
              backgroundColor: '#fcfcfc',
              '.repeatEstimate': {
                opacity: 1,
              },
            },
          },
        ]}
      >
        <TableCell>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 'inherit',
            }}
          >
            {company.icon ? (
              <img
                src={imageLink(company.icon)}
                css={{ width: 16, height: 16 }}
                alt={company.name}
              />
            ) : (
              <div css={{ width: 16, height: 16, backgroundColor: 'white' }} />
            )}
            <span
              css={{
                paddingLeft: '1rem',
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}
            >
              {company.name}
            </span>
          </div>
        </TableCell>
        <TableCell component='th' scope='row'>
          {category && (
            <div css={[sTableCategory(category.color), { width: '100%' }]}>
              {category.name}
            </div>
          )}
        </TableCell>
        <TableCell component='th' scope='row'>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 'inherit',
            }}
          >
            <div
              css={{
                width: 8,
                height: 8,
                marginLeft: 8,
                backgroundColor: project?.color || 'transparent',
                border: project?.color ? 'none' : '1px solid rgb(0 0 0 /20%)',
                transform: 'rotate(45deg)',
              }}
            />

            <span
              css={{
                paddingLeft: '1rem',
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}
            >
              {project?.name}
            </span>
          </div>
        </TableCell>
        <TableCell component='th' scope='row'>
          {users?.length > 0 &&
            users.map((user) => (
              <Chip
                css={{ fontSize: '0.675rem', fontWeight: 'bold' }}
                size='small'
                avatar={
                  <Avatar alt={user.firstName} src={imageLink(user.image)} />
                }
                label={user.firstName}
              />
            ))}
        </TableCell>
        <TableCell css={{ position: 'relative' }}>
          <span css={{ opacity: 0.5, fontStyle: 'italic' }}>
            {/* {_id} */}
            {title}
          </span>
          <Button
            className='repeatEstimate'
            onClick={() => {
              onDuplicate({
                input: {
                  title,
                  dateStart,
                  dateEnd,
                  company: { _id: company._id },
                  category: category?._id ? { _id: category._id } : null,
                  project: project?._id ? { _id: project._id } : null,
                  cph: parseFloat(cph),
                  hours: parseFloat(hours),
                },
                callback: setOpen(true),
              })
            }}
            size='small'
            variant='contained'
            css={[
              {
                opacity: 0,
                position: 'absolute',
                top: '50%',
                left: 16,
                transform: 'translate(0%,-50%)',
                minWidth: 32,
                backgroundColor: 'green',
                color: 'white',
                fontSize: '0.675rem',
                '&:hover': {
                  backgroundColor: 'green',
                },
              },
              company._id &&
                category?._id &&
                project?._id && { display: 'none' },
            ]}
          >
            DUPLICATE
          </Button>
        </TableCell>
        <TableCell>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {clickupTasks?.map((task, id) => (
              <a
                key={id}
                href={`clickup://t/${task.replace('#', '')}`}
                target='_blank'
                rel='noreferrer noopener'
              >
                <ClickupTaskChip taskId={task} />
              </a>
            ))}
          </div>
        </TableCell>
        <TableCell align='center' css={{ opacity: 0.5 }}>
          {dateStart.slice(0, 10)}
        </TableCell>
        <TableCell align='center' css={{ opacity: 0.5 }}>
          {dateEnd.slice(0, 10)}
        </TableCell>
        <TableCell align='center'>
          {(() => {
            const daysPassed = moment().diff(moment(dateStart), 'days')
            const daysTotal = moment(dateEnd).diff(moment(dateStart), 'days')
            if (daysPassed >= daysTotal) return `${daysTotal} / ${daysTotal}`
            return `${daysPassed} / ${daysTotal}`
          })()}
        </TableCell>
        <TableCell align='center'>{cph}</TableCell>
        <TableCell align='right' css={{ fontWeight: 'bold' }}>
          {hours}
          <span css={{ opacity: 0.5 }}> h</span>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <CloseIcon /> : <EditIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow css={{ backgroundColor: '#f8f8f8', width: '100%' }}>
        <TableCell css={{ width: '100%', padding: 0, margin: 0 }} colSpan={10}>
          <Collapse
            in={open}
            timeout='auto'
            unmountOnExit
            css={{ width: '100%' }}
          >
            <Form
              open={open}
              setOpen={setOpen}
              data={data}
              onSubmit={onSubmit}
              onDeleteSubmit={onDeleteSubmit}
              button={{ title: 'UPDATE' }}
              names={names}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const Form = ({
  onSubmit,
  open,
  setOpen,
  data,
  button,
  names,
  onDeleteSubmit,
}) => {
  const { companies, categories, projects, users } = names
  const [nowMonth, setNowMonth] = useState(
    data ? new Date(data.dateStart).getMonth() : new Date().getMonth()
  )

  const nowYear = new Date().getFullYear()
  // const nowMonth = new Date().getMonth() + 1

  const initialValues = {
    title: '',
    company: '',
    category: '',
    project: '',
    dateStart: new Date(nowYear, nowMonth, 1, 2, 0).toISOString().slice(0, 10),
    dateEnd: new Date(nowYear, nowMonth + 1, 0, 24, 59)
      .toISOString()
      .slice(0, 10),
    cph: 0.0,
    hours: 0,
    clickupTasks: [],
    users: [],
  }

  const [inputValues, setInputValues] = useState(
    open
      ? {
          ...data,
          dateStart: data?.dateStart?.slice(0, 10),
          dateEnd: data?.dateEnd?.slice(0, 10),
          users: data?.users.map((user) => user._id),
        } || initialValues
      : initialValues
  )

  // console.log(inputValues)

  const handleOnChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  useEffect(() => {
    if (!inputValues.cph && inputValues.company)
      setInputValues({
        ...inputValues,
        cph: parseFloat(inputValues.company?.cph_default) || 0.0,
      })
  }, [inputValues.company])

  useEffect(() => {
    if (!inputValues.title && inputValues.project) {
      setInputValues({ ...inputValues, title: inputValues.project.name })
    }

    if (!inputValues.title && inputValues.company && !inputValues.project) {
      setInputValues({ ...inputValues, title: inputValues.company.name })
    }
  }, [inputValues.project, inputValues.company])

  useEffect(() => {
    if (inputValues?.project?.isHidden) {
      toast.warn(
        <>
          Projekt <strong>{inputValues.project.name}</strong> jest ukryty!
          <br />
          <span>
            Przjedź do <Link to='/settings'>Ustawień</Link> aby odblokować
          </span>
        </>,
        { autoClose: 10000 }
      )
    }
  }, [inputValues.project])

  useEffect(() => {
    if (inputValues?.company?.isHidden) {
      toast.warn(
        <>
          Firma <strong>{inputValues.company.name}</strong> jest ukryta!
          <br />
          <span>
            Przjedź do <Link to='/settings'>Ustawień</Link> aby odblokować
          </span>
        </>,
        { autoClose: 10000 }
      )
    }
  }, [inputValues.company])

  useEffect(() => {
    return () => setInputValues(initialValues)
  }, [open])

  useEffect(() => {
    setInputValues({
      ...inputValues,
      dateStart: new Date(nowYear, nowMonth, 1, 2, 0)
        .toISOString()
        .slice(0, 10),
      dateEnd: new Date(nowYear, nowMonth + 1, 0, 24, 59)
        .toISOString()
        .slice(0, 10),
    })
  }, [nowMonth])

  // console.log(users)
  // console.log(inputValues.users)

  return (
    <Grid
      container
      css={{
        // width: '100%',
        padding: '1rem',
        justifyContent: 'space-between',
        [s.xs]: { maxWidth: 'calc(100vw - 4rem)' },
      }}
      spacing={2}
    >
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          value={inputValues.company}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              company: newValue,
            })
          }}
          id='company'
          autoComplete={false}
          options={companies}
          filterOptions={(options, state) => {
            // console.log(options)
            if (inputValues.category?.type === 'PRZERWA')
              return options.filter((option) => option.name === 'PRZERWA')
            const isProject = inputValues.project
            const newOptions = options.filter(
              (option) =>
                (isProject
                  ? option?.projects?.filter(
                      (project) => project._id === isProject._id
                    ).length > 0
                  : true) &&
                option.name
                  .toLowerCase()
                  .includes(state.inputValue?.toLowerCase())
            )
            return newOptions
          }}
          getOptionLabel={(option) => (option.name ? option.name : '')}
          renderOption={(option, { selected }) => (
            <div
              css={{
                fontSize: '0.75rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {option.icon ? (
                <img
                  src={option.icon}
                  css={{ width: 16, height: 16, marginRight: 16 }}
                  loading='lazy'
                  alt={option.name}
                />
              ) : (
                <div
                  css={{
                    width: 16,
                    height: 16,
                    marginRight: 16,
                    border: '1px solid black',
                  }}
                />
              )}
              {option.name}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete='off'
              variant='outlined'
              label='Company'
              placeholder='Company'
              size='small'
              css={sInput}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          value={inputValues.category}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              category: newValue,
            })
          }}
          id='category'
          options={categories}
          getOptionLabel={(option) => option.name ? option.name : ''}
          filterOptions={(options, state) => {
            if (inputValues.company?.name === 'PRZERWA')
              return options.filter((option) => option.type === 'PRZERWA')
            return options.filter((option) =>
              option.name
                .toLowerCase()
                .includes(state.inputValue?.toLowerCase())
            )
          }}
          renderOption={(option, { selected }) => (
            <div
              css={[
                sTableCategory(option?.color),
                {
                  width: '100%',
                },
              ]}
            >
              {option.name}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              label='Category'
              placeholder='Category'
              css={sInput}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          value={inputValues.project}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              project: newValue,
            })
          }}
          id='project'
          options={projects}
          filterOptions={(options, state) => {
            const companyID = inputValues.company?._id; // Użycie opcjonalnego łańcuchowania
            const newOptions = options.filter((option) => {
              // Pomijanie elementów, gdzie option.company jest null lub undefined
              if (!option.company) return false;

              // Sprawdzenie, czy ID firmy z projektu zgadza się z wybraną firmą (lub true, jeśli firma nie została wybrana)
              const matchesCompany = companyID ? option.company._id === companyID : true;
              const matchesName = option.name.toLowerCase().includes(state.inputValue?.toLowerCase());

              return matchesCompany && matchesName;
            });

            return newOptions;
          }}

          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <div
              css={{
                fontSize: '0.75rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                css={{
                  width: 8,
                  height: 8,
                  marginRight: 16,
                  backgroundColor: option.color || 'transparent',
                  border: option.color ? 'none' : '1px solid black',
                  transform: 'rotate(45deg)',
                }}
              />
              {option.name}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              label='Project'
              placeholder='Project'
              css={sInput}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='title'
          label='Title'
          type='text'
          required
          value={inputValues.title}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Select
          value={nowMonth}
          onChange={(e) => setNowMonth(e.target.value)}
          variant='outlined'
          css={sSelect}
        >
          <MenuItem value={0}>Styczeń</MenuItem>
          <MenuItem value={1}>Luty</MenuItem>
          <MenuItem value={2}>Marzec</MenuItem>
          <MenuItem value={3}>Kwiecień</MenuItem>
          <MenuItem value={4}>Maj</MenuItem>
          <MenuItem value={5}>Czerwiec</MenuItem>
          <MenuItem value={6}>Lipiec</MenuItem>
          <MenuItem value={7}>Sierpień</MenuItem>
          <MenuItem value={8}>Wrzesień</MenuItem>
          <MenuItem value={9}>Październik</MenuItem>
          <MenuItem value={10}>Listopad</MenuItem>
          <MenuItem value={11}>Grudzień</MenuItem>
        </Select>
      </Grid>

      {/* <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          required
          fullWidth
          variant='outlined'
          label='From'
          type='date'
          value={inputValues.dateStart}
          onChange={(e) =>
            setInputValues({ ...inputValues, dateStart: e.target.value })
          }
          size='small'
          css={sInput}
        />
      </Grid> */}

      {/* <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          required
          fullWidth
          variant='outlined'
          label='From'
          type='date'
          value={inputValues.dateEnd}
          onChange={(e) =>
            setInputValues({ ...inputValues, dateEnd: e.target.value })
          }
          size='small'
          css={sInput}
        />
      </Grid> */}

      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          required
          fullWidth
          variant='outlined'
          label='CPH'
          type='number'
          value={inputValues.cph}
          onChange={(e) =>
            setInputValues({ ...inputValues, cph: e.target.value })
          }
          size='small'
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          required
          fullWidth
          variant='outlined'
          label='Hours'
          type='number'
          value={inputValues.hours}
          onChange={(e) =>
            setInputValues({ ...inputValues, hours: e.target.value })
          }
          size='small'
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={inputValues.clickupTasks}
          onChange={(event, newValue) => {
            setInputValues({ ...inputValues, clickupTasks: newValue })
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size='small'
                variant='default'
                css={{ fontSize: '0.675rem' }}
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              variant='outlined'
              label='ClickUp'
              placeholder='TASK ID'
              css={sInput}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          value={users?.filter((user) => inputValues.users?.includes(user._id))}
          onChange={(event, newValue) => {
            const ids = newValue?.map((obj) => obj._id)
            setInputValues({
              ...inputValues,
              users: ids,
            })
          }}
          multiple
          id='users'
          options={users}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.firstName + ' ' + option?.lastName}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size='small'
                avatar={
                  <Avatar
                    alt={option.firstName + ' ' + option.lastName}
                    src={imageLink(option.image)}
                    css={{ backgroundColor: option.color.concat(alpha[50]) }}
                  >
                    {option.firstName[0]}
                    {option.lastName?.[0]}
                  </Avatar>
                }
                variant='default'
                label={
                  option.firstName +
                  ' ' +
                  (option.lastName ? option.lastName : '')
                }
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={(option, { selected }) => (
            <div
              css={{
                fontSize: '0.75rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                css={[{ marginRight: 8 }]}
                checked={selected}
              />
              <Avatar
                alt={option.firstName + ' ' + option.lastName}
                src={imageLink(option.image)}
                css={{
                  width: 24,
                  height: 24,
                  marginRight: 16,
                  fontSize: 12,
                  backgroundColor: option.image ? 'transparent' : option.color,
                }}
              >
                {option.firstName?.[0]}
                {option.lastName?.[0]}
              </Avatar>
              <span>
                {option.firstName} {option.lastName ? option.lastName : ''}{' '}
              </span>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Users'
              placeholder='User'
              size='small'
              css={sInput}
            />
          )}
        />
      </Grid>
      {onDeleteSubmit && (
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Button
            onClick={() =>
              inputValues.title
                ? toast.warn(
                    <strong>
                      Wyczyść pole z tytułem estymacji i wciśnij przycisk
                      ponownie, aby trwale usunąć
                    </strong>
                  )
                : onDeleteSubmit({
                    id: data?._id,
                    callback: () => {
                      setOpen(!open)
                      setInputValues(initialValues)
                    },
                  })
            }
            size='small'
            variant='contained'
            css={[
              {
                width: '100%',
                backgroundColor: 'red',
                color: 'white',
                fontSize: '0.75rem',
                '&:hover': {
                  backgroundColor: 'red',
                },
              },
              inputValues.title && { opacity: 0.25 },
            ]}
          >
            DELETE
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Button
          onClick={() =>
            onSubmit({
              id: data?._id,
              input: inputValues,
              callback: () => {
                // console.log('callback')
                setOpen(!open)
                setInputValues(initialValues)
              },
            })
          }
          size='small'
          variant='contained'
          disabled={
            !inputValues.title ||
            !inputValues.company ||
            // !inputValues.category ||
            !inputValues.dateStart ||
            !inputValues.dateEnd ||
            // !inputValues.cph ||
            !inputValues.hours
          }
          css={{
            width: '100%',
            backgroundColor: 'green',
            color: 'white',
            fontSize: '0.75rem',
            '&:hover': {
              backgroundColor: 'green',
            },
          }}
        >
          {button.title}
        </Button>
      </Grid>
    </Grid>
  )
}
const sTableCategory = (color) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color.concat('40') || 'rgba(0,0,0,0.25)',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  color: color.concat('80') || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})

const sInput = {
  input: { fontSize: '0.75rem' },
  label: { fontSize: '0.75rem' },
}

const sSelect = {
  fontSize: '0.75rem',
  width: '100%',
  '.MuiOutlinedInput-input': {
    padding: '10.5px 14px',
  },
}

export default EstimatesSettings
