/** @jsxImportSource @emotion/react */

import _ from 'lodash'
import { useState, useEffect } from 'react'
import Seo from '../../components/Seo'
import { useQuery } from '@apollo/client'
import PageTitle from '../../components/PageTitle'
import { GET_ACTIVITIES } from '../../graphql/activity.graphql'
import { GET_ESTIMATES } from '../../graphql/estimate.graphql'
import { getToday } from '../../utils/getToday'
import PageWrapper from '../../components/PageWrapper'
import Skeleton from '@material-ui/lab/Skeleton'
import { toast } from 'react-toastify'
import { timeDiff } from '../../utils/timeDiff'
import ActivitiesDetails from '../Activities/ActivitiesDetails'
import TeamActivities from '../Team/TeamActivities'
import DashboardCompanies from './DashboardCompanies'
import ActivitiesEditWrapper from '../Activities/ActivitiesEditWrapper'
import EstimateEditWrapper from '../Estimates/EstimateEditWrapper'
import { useAuth } from '../../hooks/auth.hook'

const DashboardChief = () => {
  const { userId } = useAuth()
  const [now, setNow] = useState('')
  const today = getToday(new Date())
  const nowYear = new Date().getFullYear()
  const nowMonth = new Date().getMonth()
  const dateFrom = new Date(nowYear, nowMonth, 1, 2, 0)
    .toISOString()
    .slice(0, 10)
  const dateTo = new Date(nowYear, nowMonth + 1, 0, 24, 59)
    .toISOString()
    .slice(0, 10)

  const {
    data: activitiesData,
    loading: activitiesLoading,
    error: activitiesError,
  } = useQuery(GET_ACTIVITIES, {
    variables: {
      dateFrom,
      dateTo,
    },
    pollInterval: 10 * 60 * 1000, // 10 mins
  })

  const {
    data: estimatesData,
    loading: estimatesLoading,
    error: estimatesError,
  } = useQuery(GET_ESTIMATES, {
    variables: {
      dateFrom,
      dateTo,
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().toString().slice(16, 21))
    }, 1000)

    return () => clearInterval(interval)
  }, [setNow])

  if (!userId) return null

  if (activitiesLoading || estimatesLoading)
    return (
      <>
        <Seo title='🏠 Dashboard' />
        <PageTitle>
          <h1>Dashboard</h1>
        </PageTitle>
        <PageWrapper>
          <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
        </PageWrapper>
      </>
    )

  if (estimatesError || activitiesError) {
    const error = estimatesError || activitiesError
    toast.error(error.toString())
    return <div>{error.toString()}</div>
  }

  let activities = activitiesData.getActivities
  let estimates = estimatesData.getEstimates

  // remove empty activities
  // activities = activities.filter(
  //   (activity) => (activity.timeEnd && activity.minutes < 1)
  // )
  activities = activities.filter((activity) =>
    activity.timeEnd && activity.minutes < 1 ? false : true
  )

  // extend activities with current time
  activities = activities.map((activity) => ({
    ...activity,
    minutes:
      !activity.minutes && now
        ? timeDiff(activity.timeStart, now)
        : activity.minutes,
  }))

  // save activities before date filter
  const activitiesAll = activities

  // filter activities that occur today
  activities = activities.filter(
    (activity) => activity.date.slice(0, 10) === today // '2021-10-11
  )

  // prettier-ignore
  const days =_(activities).groupBy('date').map((objs, key) => ({date: key})).value().length || 1
  // prettier-ignore
  const users = _(activities).groupBy('author._id').map((objs, key) => ({userId: key})).value().length || 1

  const currentMinutes = activities
    .filter((activity) => !activity.timeEnd)
    .reduce((acc, curr) => curr.minutes + acc, 0)
  // const noActivities = !activities || activities.length < 1

  return (
    <>
      <Seo title='🏠 Dashboard' />
      <PageTitle>
        <h1>Dashboard</h1>
      </PageTitle>
      <PageWrapper>
        <PageWrapper>
          <ActivitiesDetails
            data={activities}
            currentMinutes={currentMinutes}
            days={days}
            users={users}
          />
        </PageWrapper>
        <PageWrapper>
          <DashboardCompanies
            now={now}
            activitiesAll={activitiesAll}
            activitiesToday={activities}
            estimates={estimates}
          />
        </PageWrapper>
        <PageWrapper>
          <TeamActivities now={now} hideInputs />
        </PageWrapper>
      </PageWrapper>
      <ActivitiesEditWrapper />
      <EstimateEditWrapper />
    </>
  )
}

export default DashboardChief
