import React from 'react'
import { useUIContext } from '../../context/ui.context'
import ActivityEditModal from './ActivityEditModal'

const ActivitiesEditWrapper = ({ callback }) => {
  const { editActivityOpen, setEditActivityOpen } = useUIContext()

  if (!editActivityOpen) return <div />

  return (
    <ActivityEditModal
      editActivity={editActivityOpen}
      setEditActivity={setEditActivityOpen}
      callback={callback}
    />
  )
}

export default ActivitiesEditWrapper
