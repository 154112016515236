/** @jsxImportSource @emotion/react */

// Hooks
import { useState, useRef, useEffect } from 'react'
import { useAuthContext } from '../../context/auth.context'
import { toast } from 'react-toastify'

// Components
import TrackingHead from './TrackingHead'
import TrackingRow from './TrackingRow'
import TrackingNew from './TrackingNew'
import TrackingAreas from './TrackingAreas'
import PageWrapper from '../../components/PageWrapper'
import PageTitle from '../../components/PageTitle'
import ActivityEditModal from '../Activities/ActivityEditModal'
import ActivitiesDetails from '../Activities/ActivitiesDetails'

// Apollo Client
import { useMutation, useQuery } from '@apollo/client'

// GraphQL
import { GET_NAMES } from '../../graphql/data.graphql'
import {
  ACTIVITY_CREATE,
  ACTIVITY_UPDATE,
  GET_ACTIVITIES,
} from '../../graphql/activity.graphql'

// Utils
import { getToday } from '../../utils/getToday'
import { timeDiff } from '../../utils/timeDiff'

// Material UI
import Skeleton from '@material-ui/lab/Skeleton'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import {
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
} from '@material-ui/core'
import ShiftContainer from '../Shifts/ShiftContainer'

import ClickupAuthButton from '../Clickup/ClickupAuthButton'
import { s } from '../../style'

const Tracking = () => {
  // Hooks
  const { userId } = useAuthContext()
  const [currentActivity, setCurrentActivity] = useState(null)
  const [editActivity, setEditActivity] = useState(false)
  const [isLoading, setLoading] = useState(false) // loading for actions
  const [now, setNow] = useState('')
  const tableRef = useRef(null)

  const newDate = new Date()
  const today = getToday(newDate)
  const refetchVariables = {
    dateFrom: today + 'T00:00:00.000Z',
    dateTo: today + 'T23:59:59.000Z',
    authors: [userId],
  }

  // const refetchQueries = [
  //   {
  //     query: GET_ACTIVITIES,
  //     variables: { ...refetchVariables },
  //   },
  // ]

  // GraohQL
  const {
    data: namesData,
    loading: namesLoading,
    error: namesError,
  } = useQuery(GET_NAMES)

  const {
    data: activitiesData,
    loading: activitiesLoading,
    error: activitiesError,
  } = useQuery(GET_ACTIVITIES, {
    variables: {
      ...refetchVariables,
    },
    skip: !userId,
    // fetchPolicy: 'network-only',
    // fetchPolicy: 'cache-fisrt',
    // fetchPolicy: 'cache-only',
    pollInterval: 0, // 5 * 60000,
  })

  const [activityCreate] = useMutation(ACTIVITY_CREATE)
  const [activityUpdate] = useMutation(ACTIVITY_UPDATE)

  // Functions
  const scrollToBottom = () => {
    tableRef.current?.scrollTo({
      top: tableRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  // TrackingRow Methods

  const onStopActivity = ({ id, data }) => {
    const now = new Date()
    const time = now.toString().slice(16, 21)

    activityUpdate({
      variables: {
        id,
        input: {
          timeEnd: time,
        },
      },
      // refetchQueries,
    })
      .then((res) => {
        if (res.data?.activityUpdate) {
          if (!data) return null //console.log(`✅ ${time} STOPPED PREVIOUS TASK`)
          const { title, project, company, category } = data
          category?.type === 'PRZERWA'
            ? toast.dark(
                <div>
                  <strong>
                    ⏹&nbsp;&nbsp;{time} <span>STOP</span>
                  </strong>
                  <p>
                    <strong>
                      <em>PRZERWA</em>
                    </strong>
                  </p>
                </div>,
                { autoClose: 10000 }
              )
            : toast.dark(
                <div>
                  <strong>
                    ⛔&nbsp;&nbsp;{time} <span>STOP</span>
                  </strong>
                  <p>
                    <em>{title}</em>{' '}
                    <span>({project?.name || company?.name})</span>
                  </p>
                  <p>
                    <strong
                      css={[
                        sTableCategory(category?.color),
                        { color: category?.color, fontWeight: 'bold' },
                      ]}>
                      {category?.name}
                    </strong>
                  </p>
                </div>,
                { autoClose: 30000 }
              )
        } else toast.error('onStopActivity ' + res.toString())
        setLoading(false)
      })
      .catch((error) => {
        toast.error('onStopActivity ' + error.toString())
        setLoading(false)
      })
  }

  const onRepeatActivity = ({ id }) => {
    const now = new Date()
    const time = now.toString().slice(16, 21)
    const activityToStop = currentActivity?._id

    const f = activitiesData?.getActivities?.filter(
      (activity) => activity._id === id
    )[0]

    if (!f) return toast.error('CANNOT REPEAT ACTIVITY')

    activityCreate({
      variables: {
        input: {
          title: f.title,
          company: f.company?._id,
          project: f.project?._id,
          category: f.category?._id,
          date: today + 'T00:00:00.000Z',
          timeStart: time,
          clickup: f.clickup,
        },
      },
      update: (cache, { data }) => {
        const activitiesCache = cache.readQuery({
          query: GET_ACTIVITIES,
          variables: refetchVariables,
        })
        cache.writeQuery({
          query: GET_ACTIVITIES,
          variables: refetchVariables,
          data: {
            getActivities: [
              ...activitiesCache?.getActivities,
              data?.activityCreate,
            ],
          },
        })
      },
      // refetchQueries,
    })
      .then((res) => {
        if (res.data?.activityCreate) {
          toast.dark(<ToastNewActivity data={f} time={time} />, {
            autoClose: 30000,
          })
          if (activityToStop) onStopActivity({ id: activityToStop })
          setCurrentActivity({
            _id: res.data?.activityCreate?._id,
            timeStart: time,
          })
        } else toast.error('onRepeatActivity' + res.toString())
        setLoading(false)
      })
      .catch((error) => {
        toast.error('onRepeatActivity ' + error.toString())
        setLoading(false)
      })
  }

  const onNewBreakActivity = ({ title, callback }) => {
    const now = new Date()
    const time = now.toString().slice(16, 21)

    const activityToStop = currentActivity?._id
    activityCreate({
      variables: {
        input: {
          title: title || 'Przerwa',
          company: namesData?.companies.filter(
            (company) => company.name === 'PRZERWA'
          )[0]?._id,
          category: namesData?.categories.filter(
            (category) => category.type === 'PRZERWA'
          )[0]?._id,
          date: today + 'T00:00:00.000Z',
          timeStart: time,
        },
      },
      update: (cache, { data }) => {
        const activitiesCache = cache.readQuery({
          query: GET_ACTIVITIES,
          variables: refetchVariables,
        })
        cache.writeQuery({
          query: GET_ACTIVITIES,
          variables: refetchVariables,
          data: {
            getActivities: [
              ...activitiesCache?.getActivities,
              data?.activityCreate,
            ],
          },
        })
      },
      // refetchQueries,
    })
      .then((res) => {
        if (res.data?.activityCreate) {
          toast.dark(<ToastNewActivity isBreak time={time} />, {
            autoClose: 5 * 60000,
          })
          if (activityToStop) onStopActivity({ id: activityToStop })
          callback()
          setCurrentActivity({
            _id: res.data?.activityCreate?._id,
            timeStart: time,
          })
        } else toast.error('onNewBreakActivity ' + res.toString())
        setLoading(false)
      })
      .catch((error) => {
        toast.error('onNewBreakActivity ' + error.toString())
        setLoading(false)
      })
  }

  const onNewActivityCreate = ({ data, callback }) => {
    const now = new Date()
    const time = now.toString().slice(16, 21)

    const activityToStop = currentActivity?._id
    const { title, company, project, category, clickup } = data
    activityCreate({
      variables: {
        input: {
          title,
          company: company?._id,
          project: project?._id,
          category: category?._id,
          date: today + 'T00:00:00.000Z',
          timeStart: time,
          clickup,
        },
      },
      update: (cache, { data }) => {
        const activitiesCache = cache.readQuery({
          query: GET_ACTIVITIES,
          variables: refetchVariables,
        })
        cache.writeQuery({
          query: GET_ACTIVITIES,
          variables: refetchVariables,
          data: {
            getActivities: [
              ...activitiesCache?.getActivities,
              data?.activityCreate,
            ],
          },
        })
      },
      // refetchQueries,
    })
      .then((res) => {
        if (res.data?.activityCreate) {
          toast.dark(<ToastNewActivity data={data} time={time} />, {
            autoClose: 30000,
          })
          if (activityToStop) onStopActivity({ id: activityToStop })
          callback()
          setCurrentActivity({
            _id: res.data?.activityCreate?._id,
            timeStart: time,
          })
        } else toast.error('onNewActivityCreate ' + res.toString())
        setLoading(false)
      })
      .catch((error) => {
        toast.error('onNewActivityCreate ' + error.toString())
        setLoading(false)
      })
  }

  // Effects
  useEffect(() => {
    scrollToBottom()
  }, [activitiesData])

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().toString().slice(16, 21))
    }, 1000)

    return () => clearInterval(interval)
  }, [setNow])

  useEffect(() => {
    if (activitiesData?.getActivities) {
      const unfinishedActivity = activitiesData.getActivities
        .filter((activity) => !activity.timeEnd)
        .reverse()[0]
      setCurrentActivity({ ...unfinishedActivity })
    }
  }, [activitiesData])

  // Loading
  if (activitiesLoading || !activitiesData) {
    return (
      <>
        <PageTitle>
          <h1>Tracking</h1>
        </PageTitle>
        <PageWrapper>
          <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
        </PageWrapper>
      </>
    )
  }

  // Error
  if (namesError || activitiesError) {
    toast.error(namesError?.toString() || activitiesError?.toString())
    return (
      <div css={[sCenter]}>
        <SentimentDissatisfiedIcon fontSize='large' />
        <h1>Error</h1>
        {namesError && <p>{namesError?.toString()}</p>}
        {activitiesError && <p>{activitiesError?.toString()}</p>}
      </div>
    )
  }

  const { getActivities } = activitiesData

  let activities = getActivities
    ?.filter((activity) =>
      activity.timeEnd && activity.minutes < 1 ? false : true
    )
    .map((activity) => ({
      ...activity,
      minutes:
        !activity.minutes && now
          ? timeDiff(activity.timeStart, now)
          : activity.minutes,
    }))

  for (let id = 1; id < activities.length; id++) {
    let taskA = activities[id - 1]
    let taskB = activities[id]

    if (taskA.timeEnd) {
      const diff = timeDiff(taskB.timeStart, taskA.timeEnd)
      if (diff < -1) {
        taskA.hasGap = true
        taskB.hasGap = true
      }
      if (diff > 1) {
        taskA.hasOverlap = true
        taskB.hasOverlap = true
      }
    }
  }

  return (
    <>
      {currentActivity && <TrackingHead activity={currentActivity} />}
      <PageTitle>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            [s.xs]: { alignItems: 'center' },
          }}>
          <h1>Tracking</h1>
          <ClickupAuthButton />
        </div>
      </PageTitle>

      <PageWrapper>
        <Paper elevation={1}>
          <TableContainer css={{ maxHeight: 500 }} ref={tableRef}>
            <Table stickyHeader size='small'>
              <TableHead
                css={{ th: { fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)' } }}>
                <TableRow>
                  <TableCell align='left' css={{ paddingLeft: '3rem' }}>
                    Company
                  </TableCell>
                  <TableCell align='left'>Project</TableCell>
                  <TableCell align='left'>Category</TableCell>
                  <TableCell align='left'>Title</TableCell>
                  <TableCell align='center'>Date</TableCell>
                  <TableCell align='center'>Start</TableCell>
                  <TableCell align='center'>End</TableCell>
                  <TableCell align='right'>Time</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.map((row) => (
                  <TrackingRow
                    key={row._id}
                    row={row}
                    currentActivity={currentActivity}
                    setEditActivity={setEditActivity}
                    onStopActivity={onStopActivity}
                    onRepeatActivity={onRepeatActivity}
                    isLoading={isLoading}
                    setLoading={setLoading}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {!namesLoading && (
          <Paper elevation={1}>
            <TableContainer css={{ overflow: 'hidden' }}>
              <Table>
                <TableBody>
                  <TrackingNew
                    onNewActivityCreate={onNewActivityCreate}
                    onNewBreakActivity={onNewBreakActivity}
                    namesData={namesData}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    // activitiesTitles={[
                    //   ...new Set(
                    //     getActivities
                    //       .map((activity) => activity.title)
                    //       .sort((a, b) => a - b)
                    //   ),
                    // ]}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </PageWrapper>
      <ShiftContainer />
      {editActivity && (
        <ActivityEditModal
          editActivity={editActivity}
          setEditActivity={setEditActivity}
        />
      )}
      {activities?.length > 0 && (
        <>
          <PageTitle>
            <h1>Summary</h1>
          </PageTitle>

          <PageWrapper>
            <ActivitiesDetails
              data={activities}
              currentMinutes={activities
                .filter((activity) => !activity.timeEnd)
                .reduce((acc, curr) => curr.minutes + acc, 0)}
              days={1}
              users={1}
            />
          </PageWrapper>
          <PageWrapper>
            <TrackingAreas data={activities} group='company' groupKey='name' />
          </PageWrapper>
          <PageWrapper>
            <TrackingAreas data={activities} group='company' groupKey='type' />
          </PageWrapper>
          <PageWrapper>
            <TrackingAreas data={activities} group='category' groupKey='name' />
          </PageWrapper>
        </>
      )}
    </>
  )
}

const ToastNewActivity = ({ time, data, isBreak }) => {
  if (isBreak || !data)
    return (
      <div>
        <strong>
          ⏸&nbsp;&nbsp;{time} <span>START</span>
        </strong>
        <p>
          <strong>
            <em>PRZERWA</em>
          </strong>
        </p>
      </div>
    )

  const { category, project, company, title } = data

  return (
    <div>
      <strong>
        ✅&nbsp;&nbsp;{time} <span>START</span>
      </strong>
      <p>
        <strong>{title}</strong>
        <span>
          <em>&nbsp;({project?.name || company?.name})</em>
        </span>
      </p>
      <p>
        <strong
          css={[
            sTableCategory(category?.color),
            { color: category?.color, fontWeight: 'bold' },
          ]}>
          {category?.name}
        </strong>
      </p>
    </div>
  )
}

export const sTableCategory = (color) => ({
  transition: '0.3s ease-out',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color.concat('40') || 'rgba(0,0,0,0.25)',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  color: color.concat('80') || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})

const sCenter = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}

export default Tracking
