import { gql } from '@apollo/client'

export const GET_NAMES = gql`
  query getNames {
    companies: getCompanies {
      _id
      name
      icon
      isHidden
      color
      projects {
        _id
        isHidden
      }
      cph_default
      require_projects
    }
    categories: getCategories {
      _id
      name
      type
      color
      isHidden
    }
    projects: getProjects {
      _id
      name
      company {
        _id
      }
      isHidden
      color
    }
    users: getUsers {
      _id
      firstName
      lastName
      image
      color
      isActive
    }
  }
`
