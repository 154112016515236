/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { QrReader } from 'react-qr-reader'
import adapter from 'webrtc-adapter'
import { Button } from '@material-ui/core'
import { s } from '../../style'

const QRScanner = ({ scannerView, setScannerView }) => {
  const [mode, setMode] = useState('environment')
  const history = useHistory()
  if (!scannerView) return null
  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9999,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <QrReader
        // mode={mode}
        constraints={{
          facingMode: mode,
        }}
        delay={500}
        onResult={(result, error) => {
          if (!!result) {
            console.log(result.text)
            const domain =
              process.env[`REACT_APP_SERVER_URI_${process.env.NODE_ENV}`]
            const page = result.text.split(domain)
            console.log(page)
            if (page?.length > 2) history.push(page[1])
            else window.location.replace(result.text)
          }

          // if (!!error) {
          //   console.info(error)
          // }
        }}
        // style={{ width: '100%' }}
        css={{
          width: '100%',
          // border: '2px solid red',
          height: '100vh',
          '& > div': {
            paddingTop: '0 !important',
            height: '100vh',
          },
        }}
        // videoStyle={{ width: '100%', height: '100%' }}
      />

      {/* <div
        onClick={() => setScannerView(false)}
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          width: '100%',
          height: '100%',
        }}
      /> */}
      <div
        css={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          zIndex: 1000,
          width: 250,
          height: 250,
          transform: 'translate(-50%, -50%)',
          borderRadius: '1rem',
          border: '4px solid white',
          boxShadow: '0 0 0 1000px rgb(0 0 0 / 66%)',
          [s.sm]: {
            width: 300,
            height: 300,
          },
        }}></div>
      <div>
        <select
          onChange={(e) => setMode(e.target.value)}
          css={{
            position: 'fixed',
            top: '2rem',
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 1001,
          }}>
          <option value={'environment'}>Back Camera</option>
          <option value={'user'}>Front Camera</option>
          <option value={'left'}>Left</option>
          <option value={'right'}>Right</option>
        </select>
        <Button
          variant='contained'
          color='primary'
          css={{
            position: 'fixed',
            bottom: '4rem',
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 1001,
          }}
          onClick={() => setScannerView(false)}>
          EXIT
        </Button>
      </div>
    </div>
  )
}

export default QRScanner
