import { gql } from '@apollo/client'

export const GET_ALL_TASKS = gql`
  query getAllTasks {
    getAllTasks {
      _id
      users {
        _id
        firstName
        lastName
        image
        color
        title
      }
      name
      description
      status {
        status
        color
      }
      priority {
        priority
        color
      }
      start_date
      due_date
      # time_spent
      # time_estimate
      estimate {
        _id
        title
        dateStart
        dateEnd
        hours
        cph
        company {
          _id
          name
          icon
          color
        }
        category {
          _id
          name
          color
          type
        }
        project {
          _id
          name
          color
        }
        clickupTasks
        users {
          _id
          email
          firstName
          image
        }
      }
      activities {
        _id
        title
        date
        timeStart
        timeEnd
        minutes
        company {
          _id
          name
          icon
        }
        author {
          _id
          firstName
          lastName
          image
          color
        }
        category {
          _id
          name
          color
          type
        }
        project {
          _id
          name
          color
        }
        clickup
      }
    }
  }
`

export const GET_USER_TASKS = gql`
  query getUserTasks {
    getUserTasks {
      _id
      users {
        _id
        firstName
        lastName
        image
        color
        title
      }
      name
      description
      status {
        status
        color
      }
      priority {
        priority
        color
      }
      start_date
      due_date
      # time_spent
      # time_estimate
      estimate {
        _id
        title
        dateStart
        dateEnd
        hours
        cph
        company {
          _id
          name
          icon
          color
        }
        category {
          _id
          name
          color
          type
        }
        project {
          _id
          name
          color
        }
        clickupTasks
        users {
          _id
          email
          firstName
          image
        }
      }
      activities {
        _id
        title
        date
        timeStart
        timeEnd
        minutes
        company {
          _id
          name
          icon
        }
        author {
          _id
          firstName
          lastName
          image
          color
        }
        category {
          _id
          name
          color
          type
        }
        project {
          _id
          name
          color
        }
        clickup
      }
    }
  }
`
