import React from 'react'

const ClickupIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='129.032'
    height='154.84'
    viewBox='0 0 129.032 154.84'
    {...props}
  >
    <path
      id='Path_105'
      data-name='Path 105'
      d='M-5148.6-13149.879l23.813-18.241c12.647,16.51,26.088,24.119,41.05,24.119,14.878,0,27.939-7.52,40.02-23.9l24.149,17.8c-17.429,23.617-39.091,36.1-64.169,36.1C-5108.741-13114-5130.609-13126.4-5148.6-13149.879Zm2.811-65.481,62.062-53.48,61.569,53.521-19.681,22.641-41.979-36.48-42.381,36.521Z'
      transform='translate(5148.602 13268.841)'
      fill='currentColor'
    />
  </svg>
)

export default ClickupIcon
