/** @jsxImportSource @emotion/react */

import Seo from '../../components/Seo'
import { useState } from 'react'
import { useAuthContext } from '../../context/auth.context'

import ActivitiesData from './ActivitiesData'
import ActivitiesInputs from './ActivitiesInputs'
import PageTitle from '../../components/PageTitle'
import { getToday } from '../../utils/getToday'
import { FormControlLabel, Switch } from '@material-ui/core'
import PageWrapper from '../../components/PageWrapper'
import { s } from '../../style'

const Activities = () => {
  const { userId } = useAuthContext()
  const today = getToday(new Date())

  const initialValues = {
    title: '',
    authors: [userId] || [],
    companyType: '',
    companies: [],
    categories: [],
    projects: [],
    dateFrom: today,
    dateTo: today,
    minFrom: undefined,
    minTo: undefined,
  }

  const [inputValues, setInputValues] = useState(initialValues)
  const [onlyUnfinished, setOnlyUnfinished] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [mergeActivities, setMergeActivities] = useState(false)
  const [loading, setLoading] = useState(false)

  // console.log(inputValues)

  return (
    <>
      <Seo title='✅ Activities' />
      <PageTitle>
        <h1>Activities</h1>
      </PageTitle>
      <div css={sPage}>
        <ActivitiesInputs
          initialValues={initialValues}
          inputValues={inputValues}
          setInputValues={setInputValues}
          loading={loading}
        />
        <PageWrapper
          extraCss={{
            display: 'flex',
            [s.sm_down]: { flexDirection: 'column' },
          }}
        >
          <div
            css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setOnlyUnfinished(!onlyUnfinished)}
          >
            <FormControlLabel
              control={<Switch checked={onlyUnfinished} />}
              css={{ marginRight: 0 }}
            />
            <span css={sSwitchLabel}>Niezakończone</span>
          </div>
          <div
            css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setShowAll(!showAll)}
          >
            <FormControlLabel
              control={<Switch checked={showAll} />}
              css={{ marginRight: 0 }}
            />
            <span css={sSwitchLabel}>Pokaż Tabelę</span>
          </div>
          <div
            css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setMergeActivities(!mergeActivities)}
          >
            <FormControlLabel
              control={<Switch checked={mergeActivities} />}
              css={{ marginRight: 0 }}
            />
            <span css={sSwitchLabel}>Scal aktywności</span>
          </div>
        </PageWrapper>
        <ActivitiesData
          inputValues={inputValues}
          onlyUnfinished={onlyUnfinished}
          showAll={showAll}
          mergeActivities={mergeActivities}
          setLoading={setLoading}
        />
      </div>
    </>
  )
}
const sPage = {
  padding: '0 0 4rem',
}

export const sSwitchLabel = {
  marginLeft: '0rem',
  marginRight: '2rem',
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  opacity: 0.5,
  letterSpacing: '0.1em',
}

export default Activities
