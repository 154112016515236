import { useEffect } from 'react'
import { useAuth } from '../hooks/auth.hook'
import { useUI } from '../hooks/ui.hook'
import { useShifts } from '../hooks/shifts.hook'
import { AuthContext } from '../context/auth.context'
import { UIContext } from '../context/ui.context'
import { ShiftsContext } from '../context/shifts.context'
import Routes from './Routes'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../style/index.css'

const App = () => {
  const { login, logout, token, userId, userRole, ready } = useAuth()
  const {
    editActivityOpen,
    setEditActivityOpen,
    editEstimateOpen,
    setEditEstimateOpen,
  } = useUI()
  const { hasShift, onCompleteShift } = useShifts()
  const isAuth = !!token

  // useEffect(() => {
  //   if (userId) {
  //     setUserId(userId)
  //   }
  // }, [userId])

  if (!ready) return <></>

  return (
    <>
      <AuthContext.Provider
        value={{ login, logout, token, userId, userRole, isAuth }}>
        <UIContext.Provider
          value={{
            editActivityOpen,
            setEditActivityOpen,
            editEstimateOpen,
            setEditEstimateOpen,
          }}>
          <ShiftsContext.Provider value={{ hasShift, onCompleteShift }}>
            <Routes isAuth={isAuth} />
            <ToastContainer
              transition={Slide}
              position='top-center'
              autoClose={6000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ShiftsContext.Provider>
        </UIContext.Provider>
      </AuthContext.Provider>
    </>
  )
}

export default App
