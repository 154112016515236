/** @jsxImportSource @emotion/react */

import { useState, useRef } from 'react'
import _ from 'lodash'

import { useQuery, useMutation } from '@apollo/client'
import {DELETE_USER, GET_USERS, USER_CREATE, USER_UPDATE} from '../../graphql/user.graphql'
import { toast } from 'react-toastify'
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { imageLink } from '../../utils/imageLink'
import { roleValueToName, roleToColor } from '../../utils'
import DeleteIcon from "@material-ui/icons/Delete";

const SettingsEmployees = () => {
  const { data, loading, error } = useQuery(GET_USERS)
  const [userCreate] = useMutation(USER_CREATE)
  const [userUpdate] = useMutation(USER_UPDATE)
  const [userDelete] = useMutation(DELETE_USER)
  const [open, setOpen] = useState(false)
  const tableRef = useRef(null)

  const scrollToBottom = () => {
    tableRef.current?.scrollTo({
      top: tableRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  if (loading)
    return <Skeleton variant='rect' height={300} css={{ width: '100%' }} />

  if (error) {
    toast.error(error.toString())
    return <div>{error.toString()}</div>
  }

  const onUpdateSubmit = ({ id, input, callback }) => {
    userUpdate({
      variables: { id, input: _.omit(input, ['__typename', '_id']) },
      refetchQueries: [{ query: GET_USERS }],
    })
      .then((res) => {
        if (res?.data?.userUpdate)
          toast.info(
            <div>
              <strong>
                🐵&nbsp;&nbsp;<span>UPDATED</span>
              </strong>
              <p css={{ display: 'flex', alignItems: 'center' }}>
                <div
                  css={[
                    sRole,
                    {
                      backgroundColor: roleToColor(input.role),
                      fontSize: 10,
                    },
                  ]}>
                  {roleValueToName(input.role)}
                </div>
                <strong>
                  &nbsp;&nbsp;{input.firstName} {input.lastName}{' '}
                  <span>&nbsp;&nbsp;{input.email}</span>
                  {input.title && (
                    <em>
                      <span>&nbsp;&nbsp;({input.title})</span>
                    </em>
                  )}
                </strong>
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  const onCreateSubmit = ({ input, callback }) => {
    userCreate({
      variables: { input: _.pickBy(input) },
      update: (cache, { data }) => {
        const employeesCache = cache.readQuery({
          query: GET_USERS,
        })
        cache.writeQuery({
          query: GET_USERS,
          data: {
            getUsers: [...employeesCache?.getUsers, data?.userCreate],
          },
        })
      },
      // refetchQueries: [{ query: GET_USERS }],
    })
      .then((res) => {
        if (res?.data?.userCreate)
          toast.success(
            <div>
              <strong>
                🐵&nbsp;&nbsp;<span>CREATED</span>
              </strong>
              <p css={{ display: 'flex', alignItems: 'center' }}>
                <div
                  css={[
                    sRole,
                    {
                      backgroundColor: roleToColor(input.role),
                      fontSize: 10,
                    },
                  ]}>
                  {roleValueToName(input.role)}
                </div>
                <strong>
                  &nbsp;&nbsp;{input.firstName} {input.lastName}{' '}
                  <span>&nbsp;&nbsp;{input.email}</span>
                  {input.title && (
                    <em>
                      <span>&nbsp;&nbsp;({input.title})</span>
                    </em>
                  )}
                </strong>
              </p>
            </div>,
            { autoClose: 30000 }
          )
        callback()
        scrollToBottom()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

    const onDeleteSubmit = (projectId) => {

        if (window.confirm("Are you sure you want to delete this user?")) {
            userDelete({
                variables: {id: projectId},
                refetchQueries: [{query: GET_USERS}],
            })
                .then((res) => {
                    if (res.data.userDelete) {
                        toast.success(
                            <div>
                                <strong>
                                    🔽&nbsp;&nbsp;<span>DELETED</span>
                                </strong>
                                <p>
                                    The user was successfully removed
                                </p>
                            </div>,
                            {autoClose: 10000}
                        );

                    }
                })
                .catch((error) => {
                    toast.error(error.toString());
                });
        }
    };

  return (
    <>
      <TableContainer
        component={Paper}
        css={{ maxHeight: '50vh' }}
        ref={tableRef}>
        <Table stickyHeader aria-label='collapsible table' size='small'>
          <TableHead
            css={{
              th: { fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)' },
            }}>
            <TableRow>
              <TableCell css={{ paddingLeft: '3rem' }}>User</TableCell>
              <TableCell css={{ paddingLeft: '2rem' }} align='left'>
                Title
              </TableCell>
              <TableCell align='center'>Role</TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Phone</TableCell>
              <TableCell align='left'>BirthDay</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.getUsers.map((row, id) => (
              <Row key={row._id} data={row} onDelete={onDeleteSubmit} onSubmit={onUpdateSubmit} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper>
        <Collapse in={open}>
          <Form
            open={open}
            setOpen={setOpen}
            onSubmit={onCreateSubmit}
            button={{ title: 'CREATE' }}
          />
        </Collapse>
      </Paper>
      <Button
        css={{ marginTop: '1rem' }}
        size='small'
        onClick={() => setOpen(!open)}
        color={open ? 'default' : 'primary'}
        variant='contained'>
        {!open ? 'NEW EMPLOYEE' : 'CANCEL CREATION'}
      </Button>
    </>
  )
}

const Row = ({ data, onSubmit, onDelete }) => {
  const [open, setOpen] = useState(false)
  const {
    firstName,
    lastName,
    email,
    title,
    image,
    phone,
    birthDate,
    role,
    color,
    isActive,
  } = data

  return (
    <>
      <TableRow
        css={[
          !isActive && { opacity: 0.25 },
          {
            td: { fontSize: '0.75rem' },
            '&:hover': {
              backgroundColor: '#fcfcfc',
            },
          },
        ]}>
        <TableCell component='th' scope='row'>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 'inherit',
            }}>
            {image ? (
              <img
                src={imageLink(image)}
                css={{ width: 16, height: 16 }}
                alt={image}
              />
            ) : (
              <div css={{ width: 16, height: 16, backgroundColor: 'white' }} />
            )}
            <span
              css={{
                paddingLeft: '1rem',
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}>
              {firstName} {lastName}
            </span>
          </div>
        </TableCell>
        <TableCell align='left'>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div
              css={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: color,
                marginRight: '0.5rem',
              }}
            />
            <span css={{ opacity: 0.5, fontStyle: 'italic' }}>{title}</span>
          </div>
        </TableCell>
        <TableCell
          align='center'
          css={{
            padding: 0,
          }}>
          <div
            css={{
              margin: '0 auto',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div css={[sRole, { backgroundColor: roleToColor(role) }]}>
              {roleValueToName(role)}
            </div>
          </div>
        </TableCell>
        <TableCell align='left' css={{ opacity: 0.5, fontWeight: 'bold' }}>
          <a href={`mailto:${email}`}>{email}</a>
        </TableCell>
        <TableCell align='left' css={{ opacity: 0.5, fontWeight: 'bold' }}>
          <a href={`tel:${phone}`}>{phone}</a>
        </TableCell>
        <TableCell align='left'>{birthDate?.slice(0, 10)}</TableCell>
        <TableCell>
            <IconButton aria-label="delete" size="small" onClick={() => onDelete(data._id)}>
                <DeleteIcon />
            </IconButton>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <EditIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow css={{ backgroundColor: '#f8f8f8', width: '100%' }}>
        <TableCell css={{ width: '100%', padding: 0, margin: 0 }} colSpan={10}>
          <Collapse
            in={open}
            timeout='auto'
            unmountOnExit
            css={{ width: '100%' }}>
            <Form
              open={open}
              setOpen={setOpen}
              data={data}
              onSubmit={onSubmit}
              button={{ title: 'UPDATE' }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const Form = ({ onSubmit, open, setOpen, data, button }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    title: '',
    role: 1,
    email: '',
    phone: '',
    image: '',
    color: '#' + Math.floor(Math.random() * 16777215).toString(16),
    birthDate: '',
    isActive: true,
  }

  const [inputValues, setInputValues] = useState(
    open ? data || initialValues : initialValues
  )
  const handleOnChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  return (
    <Grid
      container
      css={{
        width: '100%',
        padding: '1rem',
        justifyContent: 'space-between',
      }}
      spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='firstName'
          label='First Name'
          type='text'
          required
          value={inputValues.firstName}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='lastName'
          label='Last Name'
          type='text'
          value={inputValues.lastName}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='title'
          label='Title'
          type='text'
          value={inputValues.title}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='color'
          label='Color'
          type='color'
          value={inputValues.color}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='email'
          label='Email'
          type='email'
          value={inputValues.email}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='phone'
          label='Phone'
          type='tel'
          value={inputValues.phone}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='image'
          label='Image'
          type='text'
          value={inputValues.image}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='birthDate'
          label='Date'
          type='date'
          value={inputValues.birthDate}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          fullWidth
          value={inputValues.role}
          options={[0, 1, 2, 3, 4, 5]}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              role: newValue,
            })
          }}
          renderOption={(option, { selected }) => (
            <div css={[sRole, { backgroundColor: roleToColor(option) }]}>
              {roleValueToName(option)}
            </div>
          )}
          getOptionLabel={(option) => roleValueToName(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant='outlined'
              label='Type'
              type='text'
              size='small'
              required
              css={sInput}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControlLabel
          css={{ span: { fontSize: '0.75rem' } }}
          control={
            <>
              <Switch
                name='isActive'
                checked={inputValues.isActive}
                onChange={() =>
                  setInputValues({
                    ...inputValues,
                    isActive: !inputValues.isActive,
                  })
                }
                color='primary'
              />
            </>
          }
          label='isActive'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Button
          onClick={() =>
            onSubmit({ id: data?._id, input: inputValues, callback: setOpen })
          }
          size='small'
          variant='contained'
          disabled={!inputValues.email || !inputValues.firstName}
          css={{
            width: '100%',
            backgroundColor: 'green',
            color: 'white',
            fontSize: '0.75rem',
            '&:hover': {
              backgroundColor: 'green',
            },
          }}>
          {button.title}
        </Button>
      </Grid>
    </Grid>
  )
}

const sInput = {
  input: { fontSize: '0.75rem' },
  label: { fontSize: '0.75rem' },
}

const sRole = {
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: 9,
  fontWeight: 'bold',
  backgroundColor: '#007aff',
  color: 'white',
  textAlign: 'center',
  width: 'max-content',
}

export default SettingsEmployees
