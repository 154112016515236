/** @jsxImportSource @emotion/react */

import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import Menu from './Menu'
import VersionFixed from './VersionFixed'

import Home from '../views/Home/Home'
import Login from '../views/Login/Login'
import Tracking from '../views/Tracking/Tracking'
import Activities from '../views/Activities/Activities'
import Projects from '../views/Projects/Projects'
import Project from '../views/Projects/Project'
import Companies from '../views/Companies/Companies'
import Company from '../views/Companies/Company'
import Team from '../views/Team/Team'
import Estimates from '../views/Estimates/Estimates'
import Raports from '../views/Raports/Raports'
import Settings from '../views/Settings/Settings'
import ChangeLog from '../views/Version/Version'
import Shifts from '../views/Shifts/Shifts'
import Clickup from '../views/Clickup/ClickupAuthPage'
import Tasks from '../views/Tasks/Tasks'
import { s } from '../style'

import { routes } from './Menu'
import PasswordReset from '../views/PasswordReset/PasswordReset'
import PasswordNew from '../views/PasswordNew/PasswordNew'
import QRCodes from '../views/QRCodes/QRCodes'
import Track from '../views/Track/Track'
import Stop from '../views/Stop/Stop'
import { Hidden } from '@material-ui/core'

const otherLinks = ['/track', '/stop']

const Routes = ({ isAuth }) => {
  const location = useLocation()

  if (isAuth)
    return (
      <>
        <div css={sLayout}>
          <main css={sMain}>
            <Menu />
            <section css={sSection}>
              {/* {!isAuth && <Redirect to='/login' />}
            {isAuth && <Redirect from='/login' to='/' />} */}

              {/* {userRole <= 0 && <Redirect to='/404' />} */}
              {/* {!isAuth && <Redirect to='/login' />} */}
              <Switch>
                <Redirect exact from='/login' to='/' />
                <Route exact path='/' component={Home} />
                <Route exact path='/track' component={Track} />
                <Route exact path='/stop' component={Stop} />
                <Route exact path='/tracking' component={Tracking} />
                <Route exact path='/activities' component={Activities} />
                <Route exact path='/projects' component={Projects} />
                <Route exact path='/projects/:id' component={Project} />
                <Route exact path='/tasks' component={Tasks} />
                <Route exact path='/companies' component={Companies} />
                <Route exact path='/companies/:id' component={Company} />
                <Route exact path='/team' component={Team} />
                <Route exact path='/shifts' component={Shifts} />
                <Route exact path='/estimates' component={Estimates} />
                <Route exact path='/raports' component={Raports} />
                <Route exact path='/settings' component={Settings} />
                <Route exact path='/version' component={ChangeLog} />
                <Route exact path='/clickup' component={Clickup} />
                <Route exact path='/qrcodes' component={QRCodes} />
                {/* <Route exact path='/login' component={Login} /> */}
                <Route exact path='/404' component={Page404} />
                <Route exact path='/password-reset' component={PasswordReset} />
                <Route exact path='/password-new' component={PasswordNew} />
              </Switch>

              {!['/track'].includes(location.pathname) && (
                <div
                  css={{ paddingBottom: '6rem', [s.xs]: { display: 'none' } }}
                />
              )}
            </section>
          </main>

          <VersionFixed />

          {false && <div css={sInvertMode} />}
        </div>
      </>
    )

  return (
    <>
      <Switch>
        {routes
          .concat(otherLinks.map((e) => ({ link: e })))
          .filter((route) => route.link !== '/login')
          .map((route, id) => (
            <Redirect exact from={route.link} to='/login' key={id} />
          ))}
        <Route exact path='/login' component={Login} />
        <Route exact path='/password-reset' component={PasswordReset} />
        <Route exact path='/password-new' component={PasswordNew} />
      </Switch>
    </>
  )
}

const Page404 = () => <div>404</div>

const sLayout = {
  display: 'flex',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: '#f2f2f2',
  [s.sm_down]: {
    paddingTop: '4rem',
  },
}

const sAbsolute = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  zIndex: 2000,
}

const sInvertMode = {
  ...sAbsolute,
  mixBlendMode: 'exclusion',
  backgroundColor: 'white',
  backdropFilter: 'grayscale(1)',
}

const sMain = {
  display: 'flex',
  overflow: 'hidden',
  width: '100vw',
  height: '100vh',
  // height: 'calc(100vh - 34px)',
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sSection = {
  overflowY: 'auto',
  overflowX: 'hidden',
  flexGrow: 1,
  boxShadow: '0px 0px 16px rgba(0,0,0,0.04)',
  // [s.xs]: {
  //   minHeight: '100vh',
  // },
}

export default Routes
