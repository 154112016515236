import { useMutation } from '@apollo/client'
import _ from 'lodash'
import React from 'react'
import { toast } from 'react-toastify'
import {
  GET_PROJECTS_SETTINGS,
  PROJECT_CREATE,
} from '../../graphql/project.graphql'
import { Form } from '../Settings/SettingsProjects'

const FormProject = ({ open, setOpen, button, names }) => {
  const [projectCreate] = useMutation(PROJECT_CREATE)

  const onCreateSubmit = ({ input, callback }) => {
    const newInput = { ..._.pickBy(input), company: input.company?._id }
    projectCreate({
      variables: { input: newInput },
      update: (cache, { data }) => {
        const projectsCache = cache.readQuery({
          query: GET_PROJECTS_SETTINGS,
        })
        cache.writeQuery({
          query: GET_PROJECTS_SETTINGS,
          data: {
            getProjects: [...projectsCache?.getProjects, data?.projectCreate],
          },
        })
      },
      refetchQueries: [{ query: GET_PROJECTS_SETTINGS }],
    })
      .then((res) => {
        if (res?.data?.projectCreate)
          toast.success(
            <div>
              <strong>
                🔼&nbsp;&nbsp;<span>CREATED</span>
              </strong>
              <p>
                <strong>{input.name}</strong>
                {input.company?.name && (
                  <span>&nbsp;({input.company.name})</span>
                )}
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  return (
    <Form
      open={open}
      setOpen={setOpen}
      onSubmit={onCreateSubmit}
      button={button}
      companies={names.companies}
    />
  )
}

export default FormProject
