/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react'
import PageTitle from '../../components/PageTitle'
import Seo from '../../components/Seo'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import PageWrapper from '../../components/PageWrapper'
import { USER_CLICKUP_AUTH } from '../../graphql/clickup.graphql'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClickupAuthButton from './ClickupAuthButton'

const ClickupAuthPage = () => {
  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [userClickupAuth] = useMutation(USER_CLICKUP_AUTH)
  const history = useHistory()
  const location = useLocation()
  let params = queryString.parse(location.search)
  const code = params.code

  useEffect(() => {
    if (code) {
      setIsLoading(true)
      userClickupAuth({
        variables: { code, clientId: process.env.REACT_APP_CLICKUP_CLIENT },
      })
        .then((res) => {
          const data = res?.data?.userClickupAuth
          const newToken = data.token
          if (newToken) {
            toast.success(data.message)
            localStorage.setItem('clickup', JSON.stringify(newToken))
            setToken(newToken)
          }
          setIsLoading(false)
        })
        .catch((error) => {
          toast.error(error.toString())
          setIsLoading(false)
        })
    }
  }, [code])

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem('clickup'))
    if (getToken) setToken(getToken)
  }, [])

  return (
    <>
      <Seo title='🔺ClickUp' />
      <PageTitle>
        <h1>ClickUp</h1>
      </PageTitle>
      <PageWrapper>
        <PageWrapper>
          {isLoading ? (
            <>
              <CircularProgress />
              <p css={sText}>Autoryzacja...</p>
            </>
          ) : (
            <>
              {token && (
                <p css={sText}>
                  ✅ Twoje konto Clickup zostało połączone z CITO
                </p>
              )}
              <ClickupAuthButton reload />
            </>
          )}
        </PageWrapper>
      </PageWrapper>
    </>
  )
}

const sText = {
  margin: '2rem 0',
}

export default ClickupAuthPage
