/** @jsxImportSource @emotion/react */

import { s } from '../style'

const PageTitle = ({ children, extraCss }) => {
  return <div css={[sPageTitle, extraCss]}>{children}</div>
}

const sPageTitle = {
  padding: '4rem',
  [s.sm_down]: {
    padding: '2rem',
  },
  zIndex: 50,
  h1: {
    fontWeight: 300,
    fontSize: '2rem',
    lineHeight: '2em',
    [s.sm_down]: {
      fontSize: '2rem',
    },
  },
  h2: {
    fontWeight: 300,
    fontSize: '1.5rem',
    lineHeight: '1em',
    [s.sm_down]: {
      fontSize: '1.5rem',
    },
  },
  'p span': {
    opacity: 0.3,
    fontSize: '0.75rem',
  },
}

export default PageTitle
