/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import {
  ACTIVITY_TRACK,
  ACTIVITY_CREATE,
  ACTIVITY_UPDATE,
  GET_ACTIVITY_TRACK,
  GET_ACTIVITIES,
} from '../../graphql/activity.graphql'
import { CircularProgress } from '@material-ui/core'
import { colors, s } from '../../style'
import TrackDetails from './TrackDetails'
import ActivitiesEditWrapper from '../Activities/ActivitiesEditWrapper'
import { useUIContext } from '../../context/ui.context'
import QRView from '../QRCodes/QRView'
import QRScanner from '../QRCodes/QRScanner'
import { useAuthContext } from '../../context/auth.context'
import { getToday } from '../../utils/getToday'

const Track = () => {
  const { userId } = useAuthContext()
  const { setEditActivityOpen } = useUIContext()
  const location = useLocation()
  const history = useHistory()
  const newDate = new Date()
  const today = getToday(newDate)
  const refetchVariables = {
    dateFrom: today + 'T00:00:00.000Z',
    dateTo: today + 'T23:59:59.000Z',
    authors: [userId],
  }

  let params = queryString.parse(location.search)

  const isValid = params?.company && params?.category
  const [data, setData] = useState({})

  const [paused, setPaused] = useState(false)
  const [activityTrack, { loading }] = useMutation(ACTIVITY_TRACK)
  const [activityCreate] = useMutation(ACTIVITY_CREATE)
  const [activityUpdate] = useMutation(ACTIVITY_UPDATE)
  const { data: getActivityTrackData } = useQuery(GET_ACTIVITY_TRACK, {
    variables: { id: params.id },
    skip: !params.id,
  })

  const [viewCode, setViewCode] = useState({})
  const [scannerView, setScannerView] = useState(false)

  const onStopActivity = (id, time) => {
    setPaused(true)
    activityUpdate({
      variables: {
        id,
        input: {
          timeEnd: time,
        },
      },
    })
      .then()
      .catch((error) => {
        toast.error(error.toString())
        setPaused(false)
      })
  }

  const onStartActivity = () => {
    activityTrack({
      variables: {
        input: {
          title: data.title,
          company: data.company._id,
          project: data.project?._id,
          category: data.category?._id,
          clickup: data.clickup,
        },
      },
      refetchQueries: [
        { query: GET_ACTIVITIES, variables: { ...refetchVariables } },
      ],
    })
      .then((res) => {
        setData({
          ...data,
          _id: res.data?.activityTrack?._id,
          timeStart: res.data?.activityTrack?.timeStart,
          auto: true,
        })
        setPaused(false)
      })
      .catch((error) => {
        toast.error(error.toString())
        setPaused(false)
      })
  }

  const onEditUpdate = (newData) => {
    setData({ ...newData, auto: true })
  }

  useEffect(() => {
    if (isValid)
      activityTrack({
        variables: {
          input: { ...params, title: decodeURIComponent(params.title) },
        },
        refetchQueries: [
          { query: GET_ACTIVITIES, variables: { ...refetchVariables } },
        ],
      })
        .then((res) => {
          if (res?.data?.activityTrack)
            setData({ ...res.data.activityTrack, auto: true })
        })
        .catch((error) => {
          let newurl = '/tracking?'
          newurl += `company=${params.company}`
          newurl += `&category=${params.category}`
          if (params.project) newurl += `&project=${params.project}`
          newurl += `&title=${params.title}`
          if (params.clickup) newurl += `&clickup=${params.clickup}`

          toast.error(error.toString())
          history.push(newurl)
        })
  }, [])

  useEffect(() => {
    const newData = getActivityTrackData?.getActivity
    if (newData) {
      setData({ ...newData, auto: true })
      if (newData.timeEnd) setPaused(true)
    }
  }, [getActivityTrackData])

  useEffect(() => {
    if (data && data._id && data._id !== params.id)
      history.push(`/track?id=${data._id}`)
  }, [data])

  // useEffect(() => {

  // }, [data.timeEnd])

  if (!isValid && !params.id) return <Redirect to='/tracking' />

  return (
    <>
      <div
        css={[
          sView,
          loading && {
            height: 'calc(100vh - 4rem)',
            justifyContent: 'center',
            [s.md]: {
              height: '100vh',
            },
          },
        ]}>
        {loading && (
          <CircularProgress
            thickness={5}
            size={160}
            css={{ color: colors.gray400 }}
          />
        )}
        {!loading && data?._id && (
          <TrackDetails
            data={data}
            paused={paused}
            setEditActivityOpen={setEditActivityOpen}
            onStopActivity={onStopActivity}
            onStartActivity={onStartActivity}
            setViewCode={setViewCode}
            setScannerView={setScannerView}
          />
        )}
      </div>
      <ActivitiesEditWrapper callback={onEditUpdate} />
      <QRView viewCode={viewCode} setViewCode={setViewCode} />
      <QRScanner scannerView={scannerView} setScannerView={setScannerView} />
    </>
  )
}

const sView = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: 'max-content',
  minHeight: 'calc(100vh - 4rem)',
  backgroundColor: 'white',
  [s.md]: {
    minHeight: '100vh',
  },
}

export default Track
