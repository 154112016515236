/** @jsxImportSource @emotion/react */

import { useAuthContext } from '../../context/auth.context'
import { CHIEF } from '../../constants'
import { roleNameToValue } from '../../utils'
import Version from '../Version/Version'
import DashboardChief from '../DashboardChief/DashboardChief'

const Home = () => {
  const { userRole } = useAuthContext()
  const notChief = !(userRole >= roleNameToValue(CHIEF))

  if (notChief) return <Version />

  return <DashboardChief />
}

export default Home
