import React from 'react'
import { useUIContext } from '../../context/ui.context'
import EstimateEditModal from './EstimateEditModal'

const EstimateEditWrapper = () => {
  const { editEstimateOpen, setEditEstimateOpen } = useUIContext()

  if (!editEstimateOpen) return <div />

  return (
    <EstimateEditModal
      editEstimate={editEstimateOpen}
      setEditEstimate={setEditEstimateOpen}
    />
  )
}

export default EstimateEditWrapper
