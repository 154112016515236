/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client'
import { Chip } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { GET_CLICKUP_TASK } from '../../graphql/clickup.graphql'
import { toast } from 'react-toastify'

const ClickupTaskChip = ({ taskId }) => {
  const [hover, setHover] = useState(false)
  const [taskName, setTaskName] = useState(null)
  const { data, error, refetch } = useQuery(GET_CLICKUP_TASK, {
    variables: { id: taskId.replace('#', '') },
    skip: !taskId || !hover,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (hover) refetch()
  }, [hover])

  useEffect(() => {
    const getTaskName = data?.getClickupTask?.name
    if (getTaskName) setTaskName(getTaskName)
  }, [data])

  if (error) {
    toast.error(error)
  }

  return (
    <Chip
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      css={{
        display: 'flex',
        fontSize: '0.675rem',
        cursor: 'inherit',
        '&:hover': {
          filter: 'brightness(1.05)',
        },
      }}
      size='small'
      label={taskName || taskId}
    />
  )
}

export default ClickupTaskChip
